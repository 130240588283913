import React, {useState, useRef, useEffect, useCallback} from "react";
import {DualCalender} from "../../../components/Elements";
import moment from "moment";

const ChooseDateComponent = (props) => {
    let {
        label,
        style,
        value,
        onChange,
        showDay = true,
        tabStyle = {padding: "15px 5px"},
        callback,
        searchResults,
        type
    } = props;
    const [visibleTypehead, setVisibleTypehead] = useState(false);
    const containerRef = useRef(null);

    // Click outside handler
    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setVisibleTypehead(false);
        }
    }, []);

    useEffect(() => {
        if (visibleTypehead) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [visibleTypehead, handleClickOutside]);

    const tabComp = (
        type !== "modify" && (
            <small style={{color: "gray", ...tabStyle}}>
                Tap to add return
            </small>
        )
    );

    const valueBox = (
        <div style={{minHeight: type !== "modify" ? 45 : "auto"}}>
            <h5>{value ? moment(value).format(`D MMM' YY`) :
                <span className={'opacity-25'}>Choose {label}</span>
            }</h5>
            {showDay && type !== "modify" ? <small>{value ? moment(value).format(`dddd`) : ""}</small> : null}
        </div>
    );

    return (
        <div ref={containerRef} className="content" style={style} onClick={() => {
            setVisibleTypehead(true);
        }}>
            <span>{label}</span>
            {label === "Departure" ? valueBox : value ? valueBox : tabComp}

            {visibleTypehead && (
                <div className={'flight-date-picker'}>
                    <DualCalender
                        {...props}
                        callback={callback}
                        searchResults={searchResults}
                        onSelect={(value) => {
                            onChange(value);
                            setTimeout(() => setVisibleTypehead(false), 100);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ChooseDateComponent;
