import OneWayFlightInfoBox from "../../flightSearchResult/oneWayFlightInfoBox";
import React from "react";
import moment from "moment";
import {DomesticRoundTripFlightDetails} from "../roundTripFlightDetails";
import {getTotalDurationSegments} from "../../../components/utils/appUtils";
import MultiWayFlightInfoBox from "../../flightSearchResult/multiWayFlightInfoBox";
const flightDate = (date) => {
    return moment(date).format("dddd, MMMM, Do, YYYY")
}

const FlightDestination = (props) => {
    let { flightObj, allSegmentData } = props;

    const firstSegment = flightObj?.Segments?.[0];
    // console.log(allSegmentData, "first----")
    const lastSegmentGroup = flightObj?.Segments;
    const lastSegment = lastSegmentGroup?.[lastSegmentGroup.length - 1];

    const originAirport = firstSegment?.origin || flightObj.origin;
    const destinationAirport = lastSegment?.destination || flightObj.destination;

    return (
        <div className="flight-destimation-time">
            <div className="row row-gap-3 align-items-center">
                <div className="col-lg-4">
                    <div className="d-flex align-items-center" style={{ gap: '30px' }}>
                        <h6 className="m-0 white">{originAirport}</h6>
                        <span><img src="../images2/plane.png" width="22" alt="plane" /></span>
                        <h6 className="m-0 white">{destinationAirport}</h6>
                    </div>
                </div>
                <div className="col-lg-4">
                    <p className="m-0">on {flightDate(flightObj.preferredDepartureTime)}</p>
                </div>
                <div className="col-lg-4">
                    {getTotalDurationSegments(allSegmentData)}
                </div>
            </div>
        </div>
    );
};

const FlightInfoComponent = (props) => {
    let {flightObj, selectedFlight, bookingType = '', sessionId} = props;

    return (
        <>
            <div className={'mb-4 find-flight-main'}>
                <div className={'accordion-flight-track'}>
                    {flightObj.journeyType === 2 || flightObj.journeyType === "2" ? (
                        <div className={'row'}>
                            {selectedFlight.mappedInBoundFlights || selectedFlight.mappedOutBoundFlights ? (
                                <>
                                    <div className={'col-md-12'}>
                                        {selectedFlight.mappedOutBoundFlights && (
                                            <>
                                                <FlightDestination
                                                    flightObj={flightObj}
                                                    segments={selectedFlight.mappedOutBoundFlights['Segments']}
                                                    allSegmentData={selectedFlight.mappedOutBoundFlights['Segments'][0]}
                                                />

                                                <DomesticRoundTripFlightDetails
                                                    title={'Outbound'}
                                                    journeyType={flightObj.journeyType}
                                                    data={selectedFlight.mappedOutBoundFlights}
                                                    bookingType={bookingType}
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div className={'col-md-12 mt-3'}>
                                        {selectedFlight.mappedInBoundFlights && (
                                            <>
                                                <FlightDestination
                                                    flightObj={flightObj}
                                                    segments={selectedFlight.mappedInBoundFlights['Segments']}
                                                    allSegmentData={selectedFlight.mappedInBoundFlights['Segments'][0]}
                                                />

                                                <DomesticRoundTripFlightDetails
                                                    title={'Inbound'}
                                                    sessionId={sessionId}
                                                    journeyType={flightObj.journeyType}
                                                    data={selectedFlight.mappedInBoundFlights}
                                                    bookingType={bookingType}
                                                />
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    ) : flightObj.journeyType === "3" || flightObj.journeyType === 3 ? (
                        <>
                            <FlightDestination
                                flightObj={flightObj}
                                segments={selectedFlight['Segments']}
                                allSegmentData={selectedFlight['Segments'][0]}
                            />
                            <MultiWayFlightInfoBox
                               data={selectedFlight}
                               journeyType={flightObj.journeyType}
                            />
                        </>

                    ) : (
                        <>
                            <FlightDestination
                                flightObj={flightObj}
                                segments={selectedFlight['Segments']}
                                allSegmentData={selectedFlight['Segments'][0]}
                            />
                            <OneWayFlightInfoBox data={selectedFlight} journeyType={flightObj.journeyType} />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default FlightInfoComponent;



