import React from "react";
import {managerInfo} from "../components/utils/appUtils";
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="footer mt-auto bg-white">
                <div className="container">

                    <div className="social-box py-lg-4 py-3">
                        <ul>
                            <li><a href="#"><span>
                            <i className={'bx bxl-facebook bx-sm'}/>
                            </span> Facebook</a>
                            </li>
                            <li><a href="#"><span>
                            <i className={'bx bxl-instagram bx-sm'}/>
                            </span> Instagram</a></li>
                            <li><a href="#"><span>
                                <i className={'bx bxl-linkedin bx-sm'}/>
                            </span> Linkedin</a>
                            </li>
                        </ul>
                    </div>

                    <div className="py-lg-4 py-3">
                        <div className="row row-gap-3">
                            <div className="col-lg-3 col-md-6 col-12">
                                <h5>Quick Links</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><NavLink to="/flight-search" className="nav-link p-0">Book
                                        Flights</NavLink>
                                    </li>
                                    <li className="nav-item mb-2">
                                        <NavLink to="/ticket-list" className="nav-link p-0">My
                                            Bookings</NavLink></li>
                                    <li className="nav-item mb-2"><NavLink to="/login"
                                                                           className="nav-link p-0">Login</NavLink></li>
                                    <li className="nav-item mb-2"><NavLink to="/register"
                                                                           className="nav-link p-0">Register</NavLink>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12">
                                <h5>Useful Links</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><NavLink to="/terms-conditions"
                                                                           className="nav-link p-0">Terms
                                        & Conditions</NavLink></li>
                                    <li className="nav-item mb-2"><NavLink to="/privacy-policy"
                                                                           className="nav-link p-0">Privacy
                                        Policy</NavLink></li>
                                    <li className="nav-item mb-2"><NavLink to="/refund-policy" className="nav-link p-0">Refund
                                        Policy</NavLink></li>
                                    <li className="nav-item mb-2"><NavLink to="/terms-of-service"
                                                                           className="nav-link p-0">Terms
                                        Of
                                        Service</NavLink></li>
                                    <li className="nav-item mb-2"><NavLink to="/support24x7" className="nav-link p-0">Support
                                        24 x 7</NavLink></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12">
                                <h5>Contact Us</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2">Email us at</li>
                                    <li className="nav-item mb-2">{managerInfo.email}</li>
                                    <li className="nav-item mb-2">Helpline Number</li>
                                    <li className="nav-item mb-2">{managerInfo.mobile}</li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 col-6 mx-auto">
                                <img src="../images/logo-black.png" alt="XTravelWorld Logo" className="footer-logo"/>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-4 my-4 border-top align-items-center">
                        <p className="m-0">© 2024 XTravelWorld, Inc. All rights reserved.</p>
                        <img src="../images/payment-mode.jpg" alt="payment-mode" style={{maxHeight: '35px'}}/>
                    </div>

                </div>
            </footer>
        </>
    );
};

export default Footer;
