import Home from "../web/home";
import Agents from "../web/agents";
import AboutUs from "../web/aboutUs";
import Events from "../web/events";
import ContactUs from "../web/contactUs";
import Dashboard from "../containers/dashboard";
import Login from "../containers/authications/login";
import AuthLogin from "../containers/authications/authLogin";
import Register from "../containers/authications/register";
import ForgotPassword from "../containers/authications/forgotPassword";
import FlightBooking from "../containers/flightSearch";
import FindFlight from "../containers/flightSearchResult";
import Checkout from "../containers/checkout";
import TicketResponse from "../containers/ticketResponse";
import ModifySearch from "../containers/flightSearchResult/components/modifySearch";
import TicketListComp from "../containers/ticketList";
import BookingDetails from "../containers/ticketList/bookingDetails";
import TicketDetails from "../containers/ticketList/eTicket";
import DepositList from "../containers/deposit/depositList";
import HoldTicketList from "../containers/ticketList/HoldTicketList";
import TicketListBackoffice from "../containers/ticketList/ticketListBackoffice";
import ChangePassword from "../containers/authications/changePassword"
import ResetPassword from "../containers/authications/resetPassword";
import ErrorPage from "../containers/ticketResponse/errorPage";
import ComingSoon from "../containers/comingSoon";
import AgentProfile from "../containers/agentProfile/index";
import {userTypesObj} from "../components/utils/appUtils";
import CcAvenueTesting from "../containers/payment/ccAvenueTesting";
import PaymentResponse from "../containers/payment/paymentResponse";
import PaymentResponseOld from "../containers/payment/paymentResponseOld";
import PaymentCancel from "../containers/payment/paymentCancel";
import AddDeposit from "../containers/deposit/addDeposit";
import TermsAndConditions from "../web/termsCondition";
import PrivacyPolicy from "../web/privacyPolicy";
import RefundPolicy from "../web/refundPolicy";
import Support from "../web/support";
import DirectDepositAmount from "../containers/deposit/directDepositAmount";
import RegAgentList from "../containers/regAgents/regAgentList";
import AssignedAgentList from "../containers/regAgents/assignedAgentList";
import UploadCertificate from "../containers/regAgents/uploadCertificate";
import TermsOfService from "../web/termsOfService";

let authMenu = [
    {
        name: "Login",
        title: "Login",
        component: Login,
        path: "/login"
    },
    {
        name: "Auth Login",
        title: "Auth Login",
        component: AuthLogin,
        path: "/auth-login"
    },
    {
        name: "Register",
        title: "Register",
        component: Register,
        path: "/register"
    },
    {
        name: "Forgot Password",
        title: "Forgot Password",
        component: ForgotPassword,
        path: "/forgot-password"
    },
    {
        name: "Reset Password",
        title: "Reset Password",
        component: ResetPassword,
        path: "/reset-password/:token"
    },
    {
        name: 'Upload Certificate',
        path: '/upload-certificate/:token',
        component: UploadCertificate,
        key: 'uploadCertificate'
    },
]
let frontMenu = [
    {
        name: "Home",
        title: "Home",
        component: Home,
        path: "/"
    },
    {
        name: "Home",
        title: "Home",
        component: Home,
        path: "/home"
    },
    {
        name: "Agents",
        title: "Agents",
        component: Agents,
        path: "/agents"
    },
    {
        name: "About Us",
        title: "About Us",
        component: AboutUs,
        path: "/about-us"
    },
    {
        name: "Terms & Conditions",
        title: "Terms & Conditions",
        component: TermsAndConditions,
        path: "/terms-conditions"
    },
    {
        name: "Privacy Policy",
        title: "Privacy Policy",
        component: PrivacyPolicy,
        path: "/privacy-policy"
    },
    {
        name: "Refund Policy",
        title: "Refund Policy",
        component: RefundPolicy,
        path: "/refund-policy"
    },
    {
        name: "Terms Of Service",
        title: "Terms Of Service",
        component: TermsOfService,
        path: "/terms-of-service"
    },
    {
        name: "Support 24/7",
        title: "Support 24/7",
        component: Support,
        path: "/support24x7"
    },
    {
        name: "Events",
        title: "Events",
        component: Events,
        path: "/events"
    },
    {
        name: "Contact Us",
        title: "Contact Us",
        component: ContactUs,
        path: "/contact-us"
    },
    {
        name: "Cc Avenue Testing",
        title: "Cc Avenue Testing",
        component: CcAvenueTesting,
        path: "/cc-avenue-testing"
    },
    {
        name: "Direct Deposit",
        title: "Direct Deposit",
        component: DirectDepositAmount,
        path: "/direct-deposit"
    },
    {
        name: "Payment Response",
        title: "Payment Response",
        component: PaymentResponse,
        path: "/payment-response/:paymentId"
    },
    {
        name: "Payment Response",
        title: "Payment Response",
        component: PaymentResponseOld,
        path: "/payment-response-old"
    },
    {
        name: "Payment Cancel",
        title: "Payment Cancel",
        component: PaymentCancel,
        path: "/payment-cancel"
    },

]
let menu = [
    {
        name: "Checkout",
        title: "Checkout",
        component: Checkout,
        path: "/checkout"
    },
    {
        name: "Find Flight",
        title: "Find Flight",
        component: FindFlight,
        path: "/flight-search-result"
    },
    {
        name: "Find Booking",
        title: "Find Booking",
        component: FlightBooking,
        path: "/flight-search"
    },
    {
        name: "Ticket Response",
        title: "Ticket Response",
        component: TicketResponse,
        path: "/response/:id"
    },
    {
        name: "Modify Flight Search",
        title: "Modify Flight Search",
        component: ModifySearch,
        path: "/modify-search"
    },
    {
        name: "Error",
        title: "Error",
        component: ErrorPage,
        path: "/error-page/:id"
    },
]
let innerMenu = [
    {
        name: "Dashboard",
        title: "Dashboard",
        component: Dashboard,
        path: "/dashboard",
        icon: "../images2/search-white.png",
        dontShowOnMenu: true
    },
    {
        name: "Manager Booking",
        title: "Manager Booking",
        component: TicketListComp,
        path: "/ticket-list",
        icon: "../icons-img/bookingwhite.png",
        authority: [...userTypesObj.team, ...userTypesObj.admin, ...userTypesObj.agent, 'branchUser'],
    },
    {
        name: "Booking Queues",
        title: "Booking Queues",
        component: HoldTicketList,
        path: "/hold-ticket-list",
        icon: "../icons-img/queing.png",
        authority: [...userTypesObj.team, ...userTypesObj.admin, ...userTypesObj.agent, 'branchUser'],
    },
    {
        name: "All Tickets",
        title: "All Tickets",
        component: TicketListBackoffice,
        path: "/ticket-List-backoffice",
        icon: "../icons-img/ticket-white.png",
        authority: [...userTypesObj.team, ...userTypesObj.admin, 'branchUser'],
    },
    {
        name: "Registered Agents",
        title: "Registered Agents",
        component: RegAgentList,
        path: "/registered-agents",
        icon: "../icons-img/queing.png",
        authority: [...userTypesObj.admin, 'branchUser'],
    },
    {
        name: "Assigned Agents",
        title: "Assigned Agents",
        component: AssignedAgentList,
        path: "/assigned-agents",
        icon: "../icons-img/queing.png",
        authority: [...userTypesObj.admin, 'branchUser'],
    },
    {
        name: "Add Deposit",
        title: "Add Deposit",
        component: AddDeposit,
        path: "/add-deposit",
        icon: "../icons-img/deposit.png",
        authority: [...userTypesObj.agent, ...userTypesObj.team, ...userTypesObj.admin],
    },
    {
        name: "All Transactions",
        title: "All Transactions",
        component: DepositList,
        path: "/all-Transaction",
        icon: "../icons-img/transaction .png",
        authority: [...userTypesObj.agent, ...userTypesObj.team, ...userTypesObj.admin],
    },
    /*{
        name: "Deposit",
        key: "deposit",
        authority: [...userTypesObj.agent, ...userTypesObj.team, ...userTypesObj.admin],
        icon: "../icons-img/deposit.png",
        subMenu: [
            {
                name: "Add Deposit",
                title: "Add Deposit",
                component: AddDeposit,
                path: "/add-deposit"
            },
            {
                name: "Deposit List",
                title: "Deposit List",
                component: DepositList,
                path: "/deposit-List"
            },
        ],
    },*/
    {
        name: "Settings",
        key: "settings",
        authority: [...userTypesObj.agent],
        icon: "../icons-img/settings.png",
        subMenu: [
            {
                name: "My Profile",
                title: "My Profile",
                component: AgentProfile,
                path: "/my-profile"
            },
            {
                name: "Change Password",
                title: "Change Password",
                component: ChangePassword,
                path: "/change-password"
            },
        ],
    },


    {
        name: "Withdrawals",
        title: "Withdrawals",
        component: ComingSoon,
        path: "/withdrawals",
        icon: "../icons-img/money-withdrawal.png",
        dontShowOnMenu: true
    },
    /* {
         name: "Latest Update",
         title: "Latest Update",
         component: ComingSoon,
         path: "/latest-update",
         icon: "../icons-img/updated.png"
     },
     {
         name: "Callback Request",
         title: "Callback Request",
         component: ComingSoon,
         path: "/callback-request",
         icon: "../icons-img/call-back.png"
     },
     {
         name: "Supporting Team",
         title: "Supporting Team",
         component: ComingSoon,
         path: "/supporting-team",
         icon: "../icons-img/support.png"
     },
     {
         name: "Raise a Complaint",
         title: "Raise a Complaint",
         component: ComingSoon,
         path: "/raise-a-complaint",
         icon: "../icons-img/bad-review.png"
     },*/
    {
        name: "Coming Soon",
        title: "Coming Soon",
        component: ComingSoon,
        path: "/coming-soon",
        dontShowOnMenu: true
    },
]

let fullPageMenu = [
    {
        name: "Booking Details",
        title: "Booking Details",
        component: BookingDetails,
        path: "/booking-details"
    },
    {
        name: "Ticket Details",
        title: "Ticket Details",
        component: TicketDetails,
        path: "/ticket-details"
    }
]

export {frontMenu, menu, authMenu, innerMenu, fullPageMenu}
