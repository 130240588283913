import React from "react";
import Slider from "react-slick";
import RegisterBanner from "../components/pageComponents/registerBanner";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";

const PrivacyPolicy = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <div className={'policy-section'}>
                <section className="about-banner">

                    <img src="../images/about-banner.jpg" className="w-100"/>
                </section>

                <section className="note-banner pt-lg-4 pt-3">
                    <div className="container">
                        <h3 className="m-0">At X Travel World, we are committed to protecting your privacy and ensuring
                            that
                            your personal and business information is handled in a safe and responsible manner. This
                            Privacy
                            Policy outlines how we collect, use, and protect your information when you use our platform
                            and
                            services.</h3>
                    </div>
                </section>


                <section className="our-values">
                    <div className="container">


                        <div className="row row-gap-3 mt-5">
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">

                                    <ul className="ps-3 text-secondary listing some-space">

                                        <li> We respect your privacy and care about how your Personal Information is
                                            used. This Privacy Policy (the “Policy”) outlines how XTravelWorld, Inc.
                                            (“<strong className="fw-bold text-dark">Company</strong>” or "<strong
                                                className="fw-bold text-dark">us</strong>" or "<strong
                                                className="fw-bold text-dark">we</strong>" or "<strong
                                                className="fw-bold text-dark">our</strong>") and its affiliates collect,
                                            use, store, process, transfer, and disclose your information through our
                                            website https://www.xtravelworld.com/ (“<strong
                                                className="fw-bold text-dark">Website</strong>”), our mobile application
                                            (“<strong className="fw-bold text-dark">App</strong>”), our Services, and
                                            any of our other websites, software, mobile or digital applications, or any
                                            other services we offer from time to time by or in connection therewith
                                            (together referred to as the “<strong
                                                className="fw-bold text-dark">Platform</strong>” or “<strong
                                                className="fw-bold text-dark">X Travel World</strong>”). This Policy
                                            applies to your interactions and usage of our Platform, which is a B2B
                                            travel solutions provider built to help travel agencies, tour operators, and
                                            businesses (“<strong className="fw-bold text-dark">Partners</strong>”)
                                            access a wide range of travel services at competitive rates. Our Platform
                                            provides an efficient and user-friendly system for booking flights, enabling
                                            businesses to offer the best deals to their customers (“<strong
                                                className="fw-bold text-dark">Customers</strong>”). With a seamless
                                            interface, real-time availability, and integration of multiple suppliers, X
                                            Travel World simplifies the travel booking experience. Further, our secure
                                            payment processing, automated booking management, and dedicated customer
                                            support ensure that travel businesses can operate smoothly. We also
                                            implement advanced security measures to protect user data and maintain
                                            compliance with industry standards (collectively the “<strong
                                                className="fw-bold text-dark">Services</strong>”). By reviewing this
                                            Policy, you will gain a comprehensive understanding of your privacy rights
                                            and choices.
                                        </li>
                                        <li className='mt-3'>Your access to or utilization of our Platform and/or
                                            Services operated by the Company linked to this Policy implies your
                                            agreement to be governed by this Policy. By providing us with your Personal
                                            Information, you expressly consent to the use and disclosure of your
                                            Personal Information as outlined in this Policy. This Policy, along with the
                                            Terms of Service and Cancellation and Refund Policy, is applicable to your
                                            use of the Services and you explicitly agree and acknowledge to read the
                                            Privacy Policy in conjunction with the Terms of Service and Cancellation and
                                            Refund Policy.

                                        </li>
                                        <li className='mt-3'>The term “<strong className="fw-bold text-dark">Personal
                                            Information</strong>” shall mean any information that relates to an
                                            identified or identifiable individual, and can include information that you
                                            provide to us and that we collect about you, such as when you engage with
                                            our Platform and/or Services (e.g. device information, IP address, location,
                                            etc).

                                        </li>
                                        <li className='mt-3'>By utilizing the Platform and/or Services, or furnishing
                                            your Personal Information, you explicitly agree and acknowledge that you
                                            accept the terms delineated in this Policy. The terms '<strong
                                                className="fw-bold text-dark">you</strong>' '<strong
                                                className="fw-bold text-dark">your</strong>' or ‘<strong
                                                className="fw-bold text-dark">User</strong>’ in the context of this
                                            Policy collectively pertain to Partners, Customers or any individual or
                                            entity utilizing our Platform and Services, whether for personal use or on
                                            behalf of others.
                                        </li>
                                        <li className='mt-3'>By visiting the Platform or providing your information, you
                                            expressly agree to be bound by this Privacy Policy and agree to be governed
                                            by the privacy laws, including but not limited to the Information Technology
                                            Act, 2000, Information Technology (Reasonable Security Practices and
                                            Procedures and Sensitive Personal Data or Information) Rules, 2011 (IT
                                            Rules), and the Digital Personal Data Protection Act (DPDPA), 2023, General
                                            Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA),
                                            Personal Information Protection and Electronic Documents Act (PIPEDA), and
                                            other relevant regulations governing data protection and privacy.
                                        </li>
                                        <li className='mt-3'>
                                            IF YOU DO NOT CONSENT TO THE COLLECTION, USE, AND DISCLOSURE OF YOUR
                                            PERSONAL INFORMATION AS SET FORTH IN THIS PRIVACY POLICY, PLEASE REFRAIN
                                            FROM ACCESSING AND/OR USING OUR PLATFORM.

                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">1. TO WHOM DOES THIS POLICY APPLY?
                                    </h5>

                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li>
                                            <strong>1. </strong> This Policy is inclusive and applies to all Users of
                                            our Platform, irrespective of their browsing intent or their extent of
                                            utilising the Services offered on our Platform.
                                        </li>
                                        <li>
                                            <strong>2. </strong> The applicability of this Policy extends to Users
                                            regardless of the device type used for accessing our Platform, whether it be
                                            a laptop/desktop or a mobile/tablet device.
                                        </li>
                                        <li>
                                            <strong>3. </strong> We do not knowingly collect, solicit or process
                                            Personal Information from:
                                            <ul className="ps-4">
                                                <li><strong>1. </strong> Individuals under the age of eighteen (18)
                                                    years or the age of majority as per the applicable laws in their
                                                    country of residence.
                                                </li>
                                                <li><strong>2. </strong> Individuals under the age of sixteen (16) years
                                                    residing in European Union (EU) countries.
                                                </li>
                                                <li><strong>3. </strong> Individuals under the age of thirteen (13)
                                                    years, residing in the United States or United Kingdom.
                                                </li>
                                            </ul>
                                        </li>
                                        <li><strong>4. </strong> If you are below or between the ages of 13-18, or 16-18
                                            (as applicable), and below the contractual age determined by the applicable
                                            laws of your country, you confirm that you are using the Platform with the
                                            consent of a parent or legal guardian. If you do not meet these criteria,
                                            please refrain from using our Platform and Services or providing Personal
                                            Information to us.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">2. WHAT IS THE INFORMATION THAT WE COLLECT FROM
                                        YOU?
                                    </h5>

                                    <ul className="ps-3 text-secondary listing some-space">

                                        <li><strong className="fw-bold text-dark">1. For the Partners:</strong> To
                                            utilize the Platform and our Services, you must complete the one-time
                                            sign-in process with us by setting up an account (the “Account”). To create
                                            an Account, you must provide accurate, current, and complete information.
                                            This may include but is not limited to the following:
                                        </li>
                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. Basic
                                                Information: </strong> You are required to provide basic information
                                                such as your organisation's name, full business address, website URL, if
                                                any, and name of the authorised user while setting up the Account.
                                            </li>
                                            <li><strong className="fw-bold text-dark">2. Contact
                                                information: </strong> such as business email address and contact
                                                number.
                                            </li>
                                            <li><strong className="fw-bold text-dark">3. Verification Data: </strong> We
                                                may collect information relevant to your use of our Platform and
                                                Services, such as a One-Time Password (OTP) sent at the time of Account
                                                creation, and/or log-in.
                                            </li>
                                            <li><strong className="fw-bold text-dark">4. Transaction Data: </strong>In
                                                cases of withdrawal of your Commission from the E-wallet on our
                                                Platform, you are required to provide your financial details, including
                                                the details of the payment method such as credit or debit card number,
                                                UPI information (if applicable), and bank account information. Please
                                                note that we do not collect the transaction data directly but our
                                                payment partner CCAvenue does. You agree that in providing such
                                                transaction data, you consent to the privacy policy of CCAvenue.
                                            </li>
                                            <li><strong className="fw-bold text-dark">5. Company Information: </strong>This
                                                includes valid and up-to-date business credentials, including the
                                                necessary licenses, permits, and certifications required by relevant
                                                governmental and regulatory authorities in their jurisdiction to onboard
                                                on the Platform. These may include but are not limited to IATA
                                                accreditation, Seller of Travel licenses, ATOL certification, TIDS
                                                Registration, IRCTC authorization, or any other regulatory approvals
                                                required under local, national, or international law. Additionally, you
                                                may be required to provide details of your business entity, such as
                                                company registration information, tax identification numbers, and other
                                                relevant organizational details as necessary for compliance with the
                                                Platform’s onboarding requirements.
                                            </li>
                                        </ul>
                                        <li><strong className="fw-bold text-dark">2. For the Customers:</strong> The
                                            Platform collects certain information about Customers, which is either
                                            provided by Partners on their behalf or obtained through Partners during the
                                            booking process. This information is essential for processing bookings,
                                            facilitating transactions, and ensuring service fulfillment. The types of
                                            information collected may include, but are not limited to, the following:
                                        </li>
                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. Basic
                                                Information: </strong> This includes basic information including without
                                                limitation title, full name, residential and/or business address, date
                                                of birth, nationality, and gender.
                                            </li>
                                            <li><strong className="fw-bold text-dark">2. Contact
                                                information: </strong> Such as email address, phone number, and
                                                alternative contact details (if provided).
                                            </li>
                                            <li><strong className="fw-bold text-dark">3. Travel Related
                                                Information: </strong> This may include without limitation seating
                                                preference, meal preference, frequent flyer membership details, baggage
                                                requirements, special assistance requests (such as wheelchair assistance
                                                or medical needs), and any other relevant preferences for travel
                                                arrangements.
                                            </li>
                                            <li><strong className="fw-bold text-dark">4. Sensitive Personal
                                                Information: </strong>In the case of international travel, this may
                                                include passport number, passport issuance and expiration date, visa
                                                details, travel history, and any mandatory government-issued
                                                identification documents as required by applicable regulations.
                                            </li>
                                            <li><strong className="fw-bold text-dark">4. Financial
                                                Information: </strong>During the ticket booking process, Partners may
                                                use financial details such as credit/debit card numbers, bank account
                                                details, UPI information (if applicable), digital wallet details, and
                                                other payment-related information necessary for processing transactions
                                                securely
                                            </li>
                                            <li><strong className="fw-bold text-dark">4. Verification Data: </strong>or
                                                transaction processing, we or our payment partner CCAvenue may collect
                                                and verify OTPs sent to your registered email address or contact number,
                                                along with other authentication details as required for security and
                                                fraud prevention.
                                            </li>
                                        </ul>
                                        <li><strong className="fw-bold text-dark">3. Additional
                                            Information: </strong> It includes without limitation, when you fill in
                                            information through our payment partner CCAvenue or make payments through
                                            CCAvenue, participate in promotions, communicate with our support team and
                                            other users, import or manually enter address book contacts, provide your
                                            address and/or geolocation, or share your experience with us
                                        </li>
                                        <li><strong className="fw-bold text-dark">4. Communication with
                                            us: </strong> This can include any communication that you send to us,
                                            including communications for any inquiries, payments, technical support,
                                            etc.
                                        </li>
                                        <li><strong className="fw-bold text-dark">5. Device Identification
                                            data: </strong> This includes information that may assist us in identifying
                                            your device, including browser type, and version, your operating system,
                                            etc.
                                        </li>
                                        <li><strong className="fw-bold text-dark">6. Other Data: </strong> This can
                                            include the following, based on your interaction with the Platform and/or
                                            Services-
                                        </li>
                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li> 1. Number of times you access our Platform and/or Services.
                                            </li>
                                            <li> 2. The length of time you spent on the Platform.
                                            </li>
                                            <li> 3. The period of time from when you became active and have continued to
                                                be active on the Platform.
                                            </li>
                                            <li> 4. Other similar statistics we may collect with the intention to
                                                improve the user experience of the Platform.

                                            </li>
                                        </ul>
                                        <li><strong className="fw-bold text-dark">7.</strong> You agree to provide us
                                            with your Personal Information whenever you use our Services by performing
                                            any of the following functions:
                                        </li>
                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li> 1. Accessing our Platform and/or Services by means of any web browser
                                                or any device.
                                            </li>
                                            <li> 2. Signing in for our user plans and registering for our Services.
                                            </li>
                                            <li> 3. Inquiring about our Services through our Platform.
                                            </li>
                                            <li> 4. Initiating and maintaining correspondence with us.
                                            </li>
                                        </ul>
                                        <li><strong className="fw-bold text-dark">8.</strong> You understand and agree
                                            that all Personal Information, sensitive or otherwise, collected through our
                                            Platform is introduced solely by the User. We do not collect any personal or
                                            sensitive information without the User’s explicit input and consent. Users
                                            are responsible for the accuracy and completeness of the information they
                                            provide. By using our Services, you acknowledge that you are bound by the
                                            terms and policies of our third-party service providers such as our payment
                                            partner CCAvenue and storage partner AWS. We prioritize user privacy and
                                            security by relying on trusted third-party service providers, and we
                                            encourage Users to review and understand the policies of these platforms to
                                            protect their interests.
                                        </li>
                                        <li><strong className="fw-bold text-dark">9.</strong> Users also have the option
                                            to subscribe to our newsletter at the time of Account registration or
                                            through our Platform at any time later, allowing them to stay informed about
                                            our latest updates and offerings.
                                        </li>
                                        <li><strong className="fw-bold text-dark">10.</strong> We strive to take extra
                                            precautions to ensure that such Personal Information is kept secure and
                                            confidential, and we will only retain this data for as long as necessary for
                                            the purposes for which we collect it as per the permissible laws of the
                                            land.
                                        </li>
                                        <li><strong className="fw-bold text-dark">11.</strong> This Policy will not
                                            apply to any unsolicited information provided by you through the Platform or
                                            through any other means. This includes but is not limited to, information
                                            posted on any public areas of the Platform. All such unsolicited information
                                            shall be deemed to be non-confidential and we will be free to use and
                                            disclose such unsolicited information without limitation.
                                        </li>
                                        <li><strong className="fw-bold text-dark">12.</strong> You understand that our
                                            payment partner, CCAvenue provides you with the convenience of storing your
                                            payment methods on the Platform. By opting for this feature, you consent to
                                            the storage of your Personal Information, including your payment method. We
                                            shall not be liable for any loss or damage sustained by you as a result of
                                            any disclosure (inadvertent or otherwise) of any Personal Information
                                            concerning your payment-related information in the course of any online
                                            transactions or payments made for any Services offered through the Platform.
                                            For this purpose, we recommend that you go through the terms of service of
                                            our payment partner CCAvenue which can be found here and the terms of
                                            service of our storage partner AWS which can be found here.
                                        </li>
                                        <li><strong className="fw-bold text-dark">13.</strong> Access to your Personal
                                            Information is limited to employees, agents, partners, and third parties,
                                            who we reasonably believe will need that information to enable us to provide
                                            Services to you. However, we are not responsible for the confidentiality,
                                            security, or distribution of your own Personal Information by our partners
                                            and third parties (who have their own privacy policies) outside the scope of
                                            our agreement with such partners and third parties.
                                        </li>
                                        <li><strong className="fw-bold text-dark">14.</strong> When you use our
                                            Platform, we collect and store your information which is provided by you
                                            from time to time. In general, you can browse the Platform without telling
                                            us who you are or revealing any Personal Information about yourself. Once
                                            you give us your Personal Information, you are not anonymous to us. Where
                                            possible, we indicate which fields are required and which fields are
                                            optional. You always have the option to not provide information by choosing
                                            not to use a particular service, product, or feature on the Platform.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">3. HOW DO WE COLLECT THE INFORMATION?
                                    </h5>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1.</strong> We employ various methods
                                            to gather information, ensuring a comprehensive understanding of User
                                            interactions and preferences. The collection of Personal Information is
                                            facilitated through the following processes:
                                        </li>
                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. Information you give
                                                us: </strong> When you provide us with the information referred to in
                                                Clauses 2.1, 2.2, 2.3, 2.4, 2.5 and 2.6 through the methods outlined in
                                                Clause 2.7.
                                            </li>
                                            <li><strong className="fw-bold text-dark">2. Session
                                                Management: </strong> We study session metrics to understand how Users
                                                interact with the Platform. This helps us learn the average time Users
                                                spend on the Platform and when they prefer to engage. We use tools like
                                                Google Analytics (or alternatives) to collect anonymous data, including
                                                the number of views, how long Users stay, screen views, button clicks,
                                                and where they're visiting from. This data allows us to optimize the
                                                User experience, making informed enhancements to cater to User
                                                preferences and behaviors.
                                            </li>
                                            <li><strong className="fw-bold text-dark">3. User analytics: </strong> We
                                                analyze User behavior and preferences, by collecting and analyzing
                                                Personal Information within the Platform to track and ensure accuracy,
                                                promptly identify any unauthorized transactions, and detect fraudulent
                                                activities, allowing us to take immediate corrective action. By tracking
                                                interactions, we ensure that corrective actions are taken immediately to
                                                protect both our own systems and those of our Users, while optimising
                                                the overall User experience.
                                            </li>

                                        </ul>
                                        <li><strong className="fw-bold text-dark">2. Cookies:</strong> We utilize
                                            cookies, which are small text files stored on your device’s hard drive by
                                            web browsers. These cookies help us and third parties identify Users, track
                                            preferences, analyze usage patterns, and optimize the Website’s
                                            functionality to provide a customized experience. Cookies enable us to store
                                            preferential information and understand browsing activities. You may manage
                                            cookie preferences through your browser settings. Below is the limited list
                                            of the categories of cookies we use, along with their purposes:
                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1. Strictly Necessary
                                                    Cookies: </strong> These cookies are needed to run our Website, to
                                                    keep it secure when you are accessing the Website, and to obey
                                                    regulations that apply to us. They also help us keep your details
                                                    safe and private.
                                                </li>
                                                <li><strong className="fw-bold text-dark">2. Functional
                                                    Cookies: </strong> These cookies are used for remembering things
                                                    such as your region or country, your preferred language,
                                                    accessibility options like large font or high-contrast pages.
                                                </li>
                                                <li><strong className="fw-bold text-dark">3. Performance
                                                    Cookies: </strong> These cookies tell us how you and our other users
                                                    use our Website. We combine all this data together and study it.
                                                    This helps us to improve the performance of our Services and/or the
                                                    Website.
                                                </li>
                                                <li><strong className="fw-bold text-dark">4. Session
                                                    Cookies: </strong> We use session cookies, which are stored
                                                    temporarily during your browsing session and deleted when you close
                                                    your browser or application. These cookies support Website
                                                    functionality and help us analyze usage, including pages visited,
                                                    links clicked, content viewed, and time spent on each page.
                                                </li>
                                                <li><strong className="fw-bold text-dark">5. Analytics
                                                    Cookies: </strong> Analytics cookies collect data about your use of
                                                    the Website, allowing us to improve its performance. These cookies
                                                    provide aggregated information to monitor site functionality, track
                                                    page visits, identify technical issues, analyze user traffic, and
                                                    measure the effectiveness of our advertising, including emails sent
                                                    to you.
                                                </li>
                                                <li><strong className="fw-bold text-dark">6. Purpose of Cookies We
                                                    Use: </strong> We utilize Personal Information obtained through
                                                    cookies to enhance the speed, security of your interaction with us,
                                                    and overall user experience. These cookies serve various purposes,
                                                    including but not limited to:

                                                </li>
                                                <ul className="ps-3 text-secondary listing some-space">
                                                    <li><strong className="fw-bold text-dark">1.
                                                        Preferences: </strong> Cookies enable the Website to remember
                                                        information that alters the site’s behavior or appearance, such
                                                        as your preferred language or geographic region. By retaining
                                                        your preferences, we can customize and present advertisements
                                                        and other content tailored to you.
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">2.
                                                        Security/Optimisation: </strong> Cookies play a crucial role in
                                                        maintaining security by verifying Users, preventing fraudulent
                                                        use of Services, and safeguarding User data from unauthorized
                                                        access. Specific types of cookies assist in blocking various
                                                        types of attacks, such as attempts to pilfer content from
                                                        Website forms.
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">3.
                                                        Processing: </strong> Cookies contribute to the efficient
                                                        functioning of the Website, allowing us to deliver the Services
                                                        expected by visitors and/or Users. These cookies facilitate
                                                        tasks like navigating web pages and accessing secure sections of
                                                        the Website.
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">4. Analytics and
                                                        Research: </strong> Cookies aid in comprehending how individuals
                                                        utilize our Services, enabling us to enhance them for a better
                                                        User experience. This data-driven insight helps us refine and
                                                        improve our offerings.
                                                    </li>
                                                </ul>
                                            </ul>
                                            <li><strong className="fw-bold text-dark">3. Web Beacons, Pixel Tags, and
                                                Trackers: </strong> We may employ Web Beacons, Pixel tags, and trackers
                                                which are tiny graphic images and/or small blocks of code placed on
                                                mobile apps, ads, or in the emails that allow us to determine whether
                                                you performed a specific action. When you access these pages, or when
                                                you open an email, you let us know that you have accessed the Platform
                                                or opened the email. These tools help us measure responses to our
                                                communications and improve our promotions.
                                            </li>
                                            <li><strong className="fw-bold text-dark">4. Log Files: </strong> Our
                                                servers automatically collect information sent by Users' devices, known
                                                as log files. This data may include IP addresses, device information,
                                                browser type, and timestamps. Log files are instrumental in analysing
                                                trends, administering the Website, and diagnosing technical issues.
                                            </li>
                                            <li><strong className="fw-bold text-dark">5. Third-Party
                                                Analytics: </strong> We may integrate third-party analytics services to
                                                further understand User behaviour. These services utilise their own
                                                tracking technologies to compile reports on Platform activity, aiding us
                                                in improving our Services.
                                            </li>
                                            <li><strong className="fw-bold text-dark">6. Location Data: </strong> As
                                                part of our Services, we may also collect precise geolocation data,
                                                including GPS signals, device sensors, Wi-Fi access points, and cell
                                                tower IDs. We collect this type of data if you grant us access to your
                                                location. You can withdraw your consent at any time by disabling the GPS
                                                or other location-tracking functions on your device.
                                            </li>
                                            <li><strong className="fw-bold text-dark">7. Location Data: </strong> We may
                                                collect Personal Information from other sources, including but not
                                                limited to:
                                            </li>
                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1. </strong> If a User or any
                                                    third party submits a complaint about you, we may receive
                                                    information relating to the specific complaint made in order to
                                                    understand and, where relevant, address the complaint; and
                                                </li>
                                                <li><strong className="fw-bold text-dark">2. </strong> To the extent
                                                    permitted by applicable law, we may receive additional information
                                                    about you, such as references, demographic data, and information to
                                                    help detect fraud and safety issues from (i) third-party service
                                                    providers, other third parties, and/or partners, or (ii) Users and
                                                    any other individuals, entities, and authorities, and combine it
                                                    with information we have about you. For example, we may receive
                                                    background check results or fraud warnings from identity
                                                    verification service providers for use in our fraud prevention,
                                                    security investigation, and risk assessment efforts. We may receive
                                                    information about you and your activities on and off the Platform,
                                                    including from Users of our Platform, members of the public, or
                                                    governmental, public, or tax authorities, or about your experiences
                                                    and interactions with our partners. We may receive health
                                                    information including, but not limited to, health information
                                                    related to contagious diseases.
                                                </li>

                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">4. WHY DO WE COLLECT YOUR INFORMATION?
                                    </h5>

                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. </strong> You agree and acknowledge
                                            that we shall collect your information only for lawful and legally
                                            permissible purposes which are as follows:
                                        </li>

                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. Contractual
                                                Necessity: </strong> We process your Personal Information to fulfil our
                                                contractual obligations to you. This includes actions such as providing
                                                access to our travel booking platform, managing bookings, processing
                                                transactions, and delivering our Services efficiently.
                                            </li>

                                            <li><strong className="fw-bold text-dark">2. User
                                                Authentication: </strong> We collect your information to help us
                                                identify you as and when you access the Platform, when you register an
                                                Account with us or log in, or when you utilize our Services.
                                            </li>

                                            <li><strong className="fw-bold text-dark">3. Transactions and
                                                Payments: </strong> To facilitate secure and efficient payment
                                                processing, as well as handle transactions related to Services on the
                                                Platform. Also, Personal information is utilized to enable or authorize
                                                payment services, including detecting and preventing money laundering,
                                                fraud, abuse, and security incidents, complying with legal obligations,
                                                enforcing payment policies, and improving payment services.
                                            </li>

                                            <li><strong className="fw-bold text-dark">4. Research and
                                                development: </strong> We will utilize Personal information to deliver
                                                the Services and to develop, test, and enhance the quality and usability
                                                of both the Platform and Services.
                                            </li>

                                            <li><strong className="fw-bold text-dark">5. Communicate with
                                                you: </strong> We use your Personal Information to communicate with you
                                                concerning our Services via different channels (e.g., by phone, e-mail,
                                                chat) including to inform you regarding delivery location.
                                            </li>

                                            <li><strong className="fw-bold text-dark">6. Fraud Prevention and Credit
                                                Risks: </strong> We use your Personal Information to prevent and detect
                                                fraud and abuse to protect the security of our Users.
                                            </li>
                                            <li><strong className="fw-bold text-dark">7. Troubleshoot
                                                Problems: </strong> We use your Personal Information to provide
                                                functionality, analyze performance, fix errors, and improve the
                                                usability and effectiveness of the Platform and/or Services.
                                            </li>
                                            <li><strong className="fw-bold text-dark">8. Compliance with
                                                law: </strong> To be able to perform any contractual and legal
                                                obligation.
                                            </li>
                                            <li><strong className="fw-bold text-dark">9. Enhancing User
                                                Experience: </strong> To analyze User behavior and preferences for
                                                improving our Services and User experience and to be able to provide
                                                location-specific services, if any;
                                            </li>
                                            <li><strong className="fw-bold text-dark">10. Enhanced Advertising and
                                                Marketing Efforts: </strong> In our efforts to provide, personalize,
                                                measure, and enhance our advertising and marketing endeavors, we engage
                                                in several key activities. Firstly, we utilize User information to send
                                                promotional and marketing messages, tailoring them to suit individual
                                                preferences and interests. Additionally, we strive to customize and
                                                optimize advertising on various platforms to ensure relevance and
                                                effectiveness. Furthermore, we may administer referral programs,
                                                rewards, surveys, sweepstakes, contests, and other promotional
                                                activities to engage Users and foster community participation. Through
                                                the analysis of User characteristics and preferences, we aim to send
                                                targeted promotional messages that resonate with each User segment.
                                                Finally, we extend invitations to Users for events and relevant
                                                opportunities, enriching their overall experience with our Platform; and
                                            </li>
                                            <li><strong className="fw-bold text-dark">11. Providing
                                                alerts/notifications: </strong> To effectively communicate with you
                                                through emails/SMS/notifications through the Platform to inform you
                                                about any other new apps or services that we may from time to time
                                                develop.
                                            </li>
                                        </ul>

                                        <li><strong className="fw-bold text-dark">2. </strong> In the course of
                                            operating the Platform and/or Services, we collect and utilize Personal
                                            Information in accordance with our Privacy Policy.
                                        </li>
                                        <li><strong className="fw-bold text-dark">3. </strong> You consent and recognize
                                            that your Personal Information may be disclosed on our Platform authorized
                                            by you for the purpose of utilizing our Services. Additionally, you agree
                                            and acknowledge that we are permitted to communicate with you through
                                            messaging, calls, emails, or other means, to facilitate the performance of
                                            our services wherever necessary.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">5. WHO DO WE SHARE YOUR PERSONAL INFORMATION WITH
                                        AND WHY?
                                    </h5>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. </strong> To facilitate our
                                            Platform’s services and enhance User experience, we may share Personal
                                            Information with the following entities:
                                        </li>

                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. Payment
                                                Processors: </strong> We may need to share your banking information
                                                (such as credit/debit card details, bank account numbers, and
                                                transaction details) with our trusted financial institutions or payment
                                                gateway partners to facilitate secure payments and refunds related to
                                                travel bookings.
                                            </li>
                                            <li><strong className="fw-bold text-dark">2. Third-party Service
                                                Providers: </strong> We engage third parties to perform various
                                                functions on our behalf, including data analysis, payment processing,
                                                customer support, hosting services, booking management, email
                                                communications, and marketing assistance. These service providers have
                                                access to the necessary Personal Information required to perform their
                                                functions. However, they are strictly prohibited from using it for any
                                                other purposes. Moreover, they are obligated to process the Personal
                                                Information in compliance with applicable laws. We utilize Amazon Web
                                                Services (AWS) for a range of services. While we do not own or control
                                                these third parties, when you interact with them and choose to use their
                                                services, you are providing your information to them. Your use of these
                                                services is subject to the privacy policies of those providers,
                                                including <a
                                                    href="https://www.ccavenue.com/privacy_policy_customers.jsp"
                                                    target="_blank">CCAvenue’s Privacy Policy</a>, and <a
                                                    href="https://aws.amazon.com/privacy/" target="_blank">AWS Privacy
                                                    Policy</a>.
                                            </li>
                                            <li><strong className="fw-bold text-dark">3. Affiliates: </strong> We may
                                                share your information with our affiliates, in which case we will
                                                require those affiliates to honor this Privacy Policy. Affiliates may
                                                include our parent company and any subsidiaries, joint venture partners,
                                                or other companies that we control or that are under common control with
                                                us.
                                            </li>
                                            <li><strong className="fw-bold text-dark">4. Business
                                                Transfers: </strong> If we reorganize or sell all or a portion of our
                                                assets, undergo a merger, or are acquired by another entity, we may
                                                transfer your information to the successor entity. If we go out of
                                                business or enter bankruptcy, your information would be an asset
                                                transferred or acquired by a third party. You acknowledge that such
                                                transfers may occur and that the transferee may decline to honor
                                                commitments we made in this Privacy Policy.
                                            </li>
                                            <li><strong className="fw-bold text-dark">5. Legal Compliance: </strong>
                                            </li>
                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1. </strong> We may disclose
                                                    your information to courts, law enforcement, governmental or public
                                                    authorities, tax authorities, authorized third parties, or other
                                                    Users, if and to the extent we are required or permitted to do so by
                                                    law or where disclosure is reasonably necessary to: (i) comply with
                                                    our legal obligations, (ii) comply with a valid legal request, such
                                                    as a subpoena or court order, or to respond to claims asserted
                                                    against us, (iii) respond to a valid legal request relating to a
                                                    criminal investigation to address alleged or suspected illegal
                                                    activity, or to respond to or address any other activity that may
                                                    expose us, you, or any other of our Users to legal or regulatory
                                                    liability, (iv) enforce and administer our agreements with Users,
                                                    including our Terms, additional legal terms, and policies, (v)
                                                    respond to requests for or in connection with current or prospective
                                                    legal claims or legal proceedings concerning us and/or third
                                                    parties, in accordance with applicable law, or (vi) protect the
                                                    rights, property or personal safety of the Company, its employees,
                                                    its User, or Users of the public. Notwithstanding the above, you
                                                    understand that before responding to any request for Personal
                                                    Information, we conduct a thorough legal review to ensure the
                                                    request complies with applicable laws, is specific, and is valid
                                                    under the relevant legal framework. Requests that do not meet these
                                                    criteria will not be processed. We reserve the right to seek
                                                    clarification or additional information from the requesting
                                                    authority if needed to validate the request.
                                                </li>
                                                <li><strong className="fw-bold text-dark">2. </strong> Where legally
                                                    required or permissible according to applicable law, we may disclose
                                                    User information to relevant tax authorities or other governmental
                                                    agencies, depending on where you are based, for the purpose of the
                                                    tax authorities’ determination of proper compliance with relevant
                                                    tax obligations.

                                                </li>
                                                <li><strong className="fw-bold text-dark">3. </strong> Furthermore, we
                                                    adhere to a strict data minimization policy, disclosing only the
                                                    minimum amount of Personal Information necessary to comply with
                                                    lawful requests. Before responding to any request, we thoroughly
                                                    assess its scope to ensure only information specifically relevant
                                                    and necessary to fulfill the legal requirement is disclosed.
                                                    Additionally, any data irrelevant to the specific request is
                                                    redacted or withheld. If data must be disclosed, it is shared in
                                                    aggregate or anonymized form wherever possible to safeguard
                                                    individual privacy.
                                                </li>
                                                <li><strong className="fw-bold text-dark">4. </strong> We maintain
                                                    detailed documentation of all requests from public authorities for
                                                    user information to ensure accountability and transparency. This
                                                    includes, without limitation,
                                                </li>
                                                <ul className="ps-3 text-secondary listing some-space">
                                                    <li><strong className="fw-bold text-dark">1. </strong> the nature
                                                        and content of the request,
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">2. </strong> verification
                                                        of the requesting authority's credentials,

                                                    </li>
                                                    <li><strong className="fw-bold text-dark">3. </strong> legal basis
                                                        cited in the request (e.g., court orders, subpoenas, statutory
                                                        authority),

                                                    </li>
                                                    <li><strong className="fw-bold text-dark">4. </strong> internal
                                                        legal review processes, including opinions sought from legal
                                                        counsel,
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">5. </strong> specific
                                                        information disclosed (if any) and the date of disclosure,
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">6. </strong> any
                                                        communication with the requesting authority, including
                                                        objections or clarifications sought, and

                                                    </li>
                                                    <li><strong className="fw-bold text-dark">7. </strong> steps taken
                                                        to comply with jurisdiction-specific laws and regulations.

                                                    </li>
                                                </ul>
                                                <li><strong className="fw-bold text-dark">5. </strong> Where appropriate
                                                    and/or legally required, we may notify the User about legal
                                                    requests, unless: (i) providing notice is prohibited by the legal
                                                    process itself, by court order we receive, or by applicable law, or
                                                    (ii) we believe that providing notice would be futile, ineffective,
                                                    create a risk of injury or bodily harm to an individual or group, or
                                                    create or increase a risk of fraud upon or harm to us, our Users, or
                                                    expose us to a claim of obstruction of justice. If a request for
                                                    Personal Information is deemed unlawful, overly broad, or lacking in
                                                    sufficient legal basis, we will take appropriate measures to
                                                    challenge or refuse the request. This may include engaging with the
                                                    requesting authority to clarify the scope of the request or initiate
                                                    or participate in legal proceedings to challenge the request where
                                                    warranted.
                                                </li>
                                                <li><strong className="fw-bold text-dark">6. Service
                                                    Improvement: </strong> We may share certain aggregated, anonymized
                                                    information with third parties (for example, for Google Analytics)
                                                    in order to assess the Platform usage and information pertaining to
                                                    the ease of navigation.
                                                </li>
                                                <li><strong className="fw-bold text-dark">7.
                                                    Advertisements: </strong> We use third-party advertising companies
                                                    to serve ads when you visit our Platform. These companies may use
                                                    information (not including your name, address, email address, or
                                                    telephone number) about your visits to the Platform and other
                                                    websites in order to provide personalized advertisements about
                                                    services of interest to you.
                                                </li>
                                                <li><strong className="fw-bold text-dark">8.
                                                    Collaborations: </strong> We may share your Personal Information
                                                    with reputable partners to facilitate joint initiatives, promotions,
                                                    or integrated services; and
                                                </li>
                                                <li><strong className="fw-bold text-dark">9. Growth and
                                                    Expansion: </strong> As our Platform evolves and expands, there may
                                                    be instances where sharing Personal Information with new entities or
                                                    parties becomes necessary for the enhancement of our Platform. Any
                                                    such sharing will be carried out with the utmost consideration for
                                                    User privacy and in accordance with relevant legal frameworks.
                                                </li>

                                            </ul>
                                        </ul>

                                        <li><strong className="fw-bold text-dark">2. </strong> We do not ever sell or
                                            rent your Personal Information without your express approval.
                                        </li>
                                        <li><strong className="fw-bold text-dark">3. </strong> We are not responsible
                                            for the actions of third parties with whom you share personal or sensitive
                                            data, and we have no authority to manage or control third-party
                                            solicitations. If you no longer wish to receive correspondence, emails, or
                                            other communications from third parties, you are responsible for contacting
                                            the third party directly.
                                        </li>
                                        <li><strong className="fw-bold text-dark">4. </strong> We may also de-identify
                                            or aggregate information and convert it into non-personal information so
                                            that it can no longer reasonably be used to identify you (“De-identified
                                            Information”). We use and retain De-Identified Information for any of the
                                            purposes described in Section 4 and Section 5 of this Policy. We will
                                            maintain and use De-Identified Information in de-identified form and will
                                            not attempt to re-identify the information, except to confirm our
                                            de-identification processes or unless required by law.
                                        </li>
                                        <li><strong className="fw-bold text-dark">5. </strong> De-identified Information
                                            that will never be able to personally identify particular individuals is
                                            referred to as <strong className="fw-bold text-dark">anonymized
                                                information</strong>. Additionally, De-identified Information that can
                                            identify individuals only if it is combined with another, separate piece of
                                            information is referred to as pseudonymized information.
                                        </li>
                                        <li><strong className="fw-bold text-dark">6. </strong> Where possible, we will
                                            aim to collect, store, and use anonymized information as a first preference,
                                            and if not, then <strong className="fw-bold text-dark">pseudonymized
                                                information</strong>. Please note that we may retain anonymized
                                            information for analytic and service development purposes.
                                        </li>
                                        <li><strong className="fw-bold text-dark">7. </strong> We may share aggregated
                                            anonymized information, to third parties for analytical and/or marketing
                                            purposes.
                                        </li>
                                        <li><strong className="fw-bold text-dark">8. </strong> We are not responsible
                                            for the actions of third parties with whom you share personal or sensitive
                                            data, and we have no authority to manage or control third-party
                                            solicitations. If you no longer wish to receive correspondence, emails, or
                                            other communications from third parties, you are responsible for contacting
                                            the third party directly.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">6. HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?

                                    </h5>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. </strong> In compliance with
                                            applicable laws, we retain your Personal Information for a duration no
                                            longer than necessary for the purpose for which it was collected or as
                                            mandated by relevant laws.
                                        </li>
                                        <li><strong className="fw-bold text-dark">2. For Partners: </strong>

                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1. Booking and Transaction
                                                    Data Retention: </strong> We store booking records, payment details,
                                                    and commission information to comply with legal, tax, and audit
                                                    requirements.
                                                </li>
                                                <li><strong className="fw-bold text-dark">2. Deactivated
                                                    Accounts: </strong> If a travel partner or agent account becomes
                                                    inactive, we may retain relevant records as required by applicable
                                                    laws and business needs. Once retention obligations are met, we
                                                    anonymize data to ensure it is no longer linked to a specific
                                                    business.
                                                </li>
                                                <li><strong className="fw-bold text-dark">3. Deletion
                                                    Requests: </strong> Businesses can request the deletion of
                                                    non-essential data, but records necessary for compliance, fraud
                                                    prevention, and financial reporting may be retained as required by
                                                    law.
                                                </li>


                                            </ul>
                                            <li><strong className="fw-bold text-dark">3. For Customers: </strong>
                                                <ul className="ps-3 text-secondary listing some-space">
                                                    <li><strong className="fw-bold text-dark">1. Booking and Travel
                                                        History: </strong> We retain your trip details, payments,
                                                        preferences, and other relevant information for as long as
                                                        required to provide you with seamless travel experiences and for
                                                        legitimate business and legal purposes.
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">2. Deletion
                                                        Requests: </strong> Customers can request deletion of personal
                                                        data (such as name, contact details, and preferences) through
                                                        the website or customer support. However, we may retain certain
                                                        data for dispute resolution, fraud prevention, and regulatory
                                                        compliance.
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">3. Browser Data
                                                        Deletion: </strong> For website-based data, deletion cannot be
                                                        processed directly through the browser. Users must contact
                                                        Xtravel World customer support to initiate a request.
                                                    </li>


                                                </ul>
                                            </li>
                                            <li><strong className="fw-bold text-dark">4. For Customers: </strong>
                                                All deletion requests will be verified and processed securely. While we
                                                will honor most deletion requests, You understand and agree that certain
                                                data related to you may be retained beyond this period if we reasonably
                                                believe it is necessary to prevent fraud, mitigate potential abuse,
                                                allow us to exercise our legal rights, defend against legal claims, or
                                                fulfill other legitimate purposes required by law or for analytical and
                                                research purposes. Additionally, we may continue to retain your Personal
                                                Information for the following purposes, including but not limited to:
                                                <ul className="ps-3 text-secondary listing some-space">
                                                    <li><strong className="fw-bold text-dark">1. Legitimate Business
                                                        Interest: </strong> We may retain your Personal Information as
                                                        necessary for our legitimate business interests, such as the
                                                        prevention of money laundering, fraud detection and prevention,
                                                        and enhancing safety;
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">2. Legal, Tax, Reporting,
                                                        and Auditing Obligations: </strong> We may retain and use your
                                                        Personal Information to the extent necessary to comply with our
                                                        legal, tax, reporting, and auditing obligations;
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">3. Shared
                                                        Information: </strong> Information you have shared with others,
                                                        such as reviews and forum postings, may continue to be publicly
                                                        visible on the Platform, even after your access/use is
                                                        restricted; and
                                                    </li>

                                                    <li><strong className="fw-bold text-dark">4. Residual
                                                        Copies: </strong> Because we take measures to protect data from
                                                        accidental or malicious loss and destruction, residual copies of
                                                        your Personal Information may not be removed from our backup
                                                        systems for a limited period of time.
                                                    </li>


                                                </ul>
                                            </li>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">7. HOW DO WE PROVIDE FOR THE SECURITY OF YOUR
                                        PERSONAL INFORMATION WITH US?</h5>

                                    <ul className="ps-3 text-secondary listing some-space">

                                        <li><strong className="fw-bold text-dark">1. </strong> We prioritize the
                                            security of your data, utilizing secure cloud servers such as Amazon Web
                                            Services (AWS), where your Personal Information is encrypted at rest, adding
                                            an extra layer of protection against unauthorized access. We implement
                                            reasonable physical, electronic, and procedural safeguards to ensure the
                                            confidentiality and integrity of your information. Accessing your
                                            information is facilitated through a secure server, and once in our
                                            possession, your data is subject to strict security guidelines to prevent
                                            unauthorized access. Further, access to your information is restricted to
                                            our internal team members only to maintain confidentiality and security.
                                        </li>
                                        <li><strong className="fw-bold text-dark">2. </strong> You understand and agree
                                            that despite the security measures in place, we cannot be held liable for
                                            any issues related to data security. Nevertheless, we implement reasonable
                                            physical, electronic, and procedural safeguards to maintain the
                                            confidentiality and integrity of your information.
                                        </li>
                                        <li><strong className="fw-bold text-dark">3. </strong> While we take
                                            comprehensive measures to safeguard your information, by using our Platform,
                                            you accept and understand the security implications inherent in transmitting
                                            data over the Internet and the World Wide Web. Despite our diligent efforts,
                                            complete security cannot be guaranteed, and inherent risks persist. Rest
                                            assured, we are dedicated to continuously enhancing our security protocols
                                            to effectively mitigate emerging threats and uphold the trust of our Users.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark ">8. HOW DO WE HANDLE DATA BREACHES AND SECURITY
                                        INCIDENTS?
                                    </h5>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. </strong> In the event of a data
                                            breach or security incident, X Travel World maintains a proactive approach
                                            to ensure swift resolution and mitigate potential risks. We have established
                                            a comprehensive incident response plan designed to address such occurrences
                                            promptly and effectively:
                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1.
                                                    Identification: </strong> We promptly identify and acknowledge any
                                                    signs of a data breach or security incident within our systems or
                                                    infrastructure;
                                                </li>
                                                <li><strong className="fw-bold text-dark">2.
                                                    Containment: </strong> Immediate action is taken to contain the
                                                    impact of the breach, preventing further unauthorized access or
                                                    damage to data;
                                                </li>
                                                <li><strong className="fw-bold text-dark">3. Notification: </strong> We
                                                    prioritize transparency by promptly notifying affected parties,
                                                    including users and relevant stakeholders, about the breach and its
                                                    potential impact on their data;
                                                </li>
                                                <li><strong className="fw-bold text-dark">4. Collaboration: </strong> We
                                                    collaborate with relevant authorities, such as regulatory bodies and
                                                    law enforcement agencies, to report the incident and comply with any
                                                    legal obligations or regulatory requirements; and
                                                </li>
                                                <li><strong className="fw-bold text-dark">5. Post-Incident
                                                    Assessment: </strong> Following the resolution of the incident, we
                                                    conduct thorough assessments to evaluate the effectiveness of our
                                                    response measures and identify areas for improvement.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark ">9. WHO IS THE DATA CONTROLLER AND DATA PROCESSOR
                                        OF YOUR PERSONAL INFORMATION?
                                    </h5>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. </strong> We will act as the data
                                            controller where we make decisions on how your Personal Information is used
                                            in connection with the Platform or our Services. We will act as the data
                                            processor where we only use your Personal Information as authorised and
                                            instructed by a third party in connection with the Platform, or our
                                            applications or Services.

                                        </li>
                                        <li><strong className="fw-bold text-dark">2. </strong> Where we are acting as
                                            the data controller, we are responsible for the obligations of a data
                                            controller under data protection laws in connection with the processing of
                                            your Personal Information and we use this Privacy Policy to provide you with
                                            information about our use of your Personal Information.
                                        </li>
                                        <li><strong className="fw-bold text-dark">3. </strong> Where we are acting as a
                                            data processor, the relevant third party will be acting as a data controller
                                            and will be responsible for the obligations of a data controller under data
                                            protection laws in connection with the processing of your Personal
                                            Information. If you are accessing the Platform, or our Services through a
                                            third party, you should contact them with queries regarding the processing
                                            of your Personal Information or compliance with data protection law.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">10. DO WE TRANSFER YOUR PERSONAL INFORMATION
                                        ACROSS THE BORDER?
                                    </h5>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. </strong> While our primary
                                            practice is to store all data on servers located within India, it's
                                            important to note that certain circumstances may necessitate the transfer of
                                            your Personal Information to countries outside your residential country.
                                            These transfers may occur for various purposes outlined in this Policy.

                                        </li>
                                        <li><strong className="fw-bold text-dark">2. </strong> You understand and accept
                                            that other countries may have differing (and potentially less stringent)
                                            laws relating to the degree of confidentiality afforded to the information
                                            it holds and that such information can become subject to the laws and
                                            disclosure requirements of such countries, including disclosure to
                                            governmental bodies, regulatory agencies, and private persons, as a result
                                            of applicable governmental or regulatory inquiry, court order or other
                                            similar processes. In addition, a number of countries have agreements with
                                            other countries providing for the exchange of information for law
                                            enforcement, tax, and other purposes.
                                        </li>
                                        <li><strong className="fw-bold text-dark">3. </strong> If we transfer your
                                            Personal Information to third parties for purposes stated in this Policy, we
                                            will use our best endeavors to put in place appropriate controls and
                                            safeguards to ensure that your Personal Information is kept accurate,
                                            adequately protected, and processed only for specified and reasonable
                                            purposes in a manner that is fair, transparent and has a lawful basis, and
                                            is stored for no longer than is absolutely necessary.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">11. WHAT ARE YOUR RIGHTS AS A DATA SUBJECT?</h5>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. </strong> You, as a data subject,
                                            may have certain rights to your Personal Information with us, as under:

                                        </li>

                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. Request access to your Personal
                                                Information: </strong> This allows you to receive a copy of the Personal
                                                Information we hold about you, and to check that we are lawfully
                                                processing it;
                                            </li>
                                            <li><strong className="fw-bold text-dark">2. Request the correction of your
                                                Personal Information: </strong> This allows you to ask for any
                                                incomplete or inaccurate information we hold about you to be corrected;
                                            </li>
                                            <li><strong className="fw-bold text-dark">3. Request the erasure of your
                                                Personal Information: </strong> This allows you to ask us to delete or
                                                remove your Personal Information from our systems where there is no good
                                                reason for us to continue processing it;
                                            </li>
                                            <li><strong className="fw-bold text-dark">4. Object to the processing of
                                                your Personal Information: </strong> This allows you to object to our
                                                processing of your Personal Information for a specific purpose (for
                                                example, for marketing purposes);

                                            </li>
                                            <li><strong className="fw-bold text-dark">5. Request the transfer (data
                                                portability) of your Personal Information: </strong> This allows you to
                                                request the transfer of your Personal Information in a structured,
                                                commonly used, machine-readable format, either to you or to a third
                                                party designated by you and, if technically feasible, have it
                                                transmitted to another controller without any hindrance. This provision
                                                is applicable provided that your information is processed by automated
                                                means and that the processing is based on your consent, on a contract of
                                                which you are part of, or on pre-contractual obligations thereof;
                                            </li>
                                            <li><strong className="fw-bold text-dark">6. Request the
                                                restriction: </strong> You have the right to request the restriction of
                                                processing of your Personal Information. This means we will store your
                                                Personal Information but not further process it, except in limited
                                                circumstances (e.g., with your consent or for legal claims);
                                            </li>
                                            <li><strong className="fw-bold text-dark">7. Request further information on
                                                the processing of your Personal Information: </strong> You have the
                                                right to obtain further information on how we process your Personal
                                                Information. This includes details about the purposes of the processing,
                                                the categories of Personal Information involved, the recipients or
                                                categories of recipients with whom the Personal Information has been or
                                                will be shared, and the envisaged retention period of the Personal
                                                Information.
                                            </li>
                                            <li><strong className="fw-bold text-dark">8. Withdraw your
                                                Consent: </strong> This right only exists where we are relying on your
                                                consent to process your Personal Information. If you withdraw your
                                                consent, we may not be able to provide you with access to certain
                                                features of our Platform. We will advise you if this is the case at the
                                                time you withdraw your Consent.
                                            </li>
                                            <li><strong className="fw-bold text-dark">8. User’s State-Specific Rights:
                                            </strong></li>
                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1. </strong> If you are a
                                                    citizen of India, you may have additional rights listed in Schedule
                                                    I. Please refer to Schedule I below for more detailed information on
                                                    your rights.
                                                </li>
                                                <li><strong className="fw-bold text-dark">2. </strong> If you are a
                                                    citizen of the European Union, you may have additional rights listed
                                                    in Schedule II. Please refer to Schedule II below for more detailed
                                                    information on your rights.
                                                </li>
                                                <li><strong className="fw-bold text-dark">3. </strong> If you are
                                                    residing in the United Kingdom, you may have rights listed in
                                                    Schedule III. Please refer to Schedule III below for more detailed
                                                    information on your rights.
                                                </li>
                                                <li><strong className="fw-bold text-dark">4. </strong> If you are
                                                    residing in Canada, you may have rights listed in Schedule IV.
                                                    Please refer to Schedule III below for more detailed information on
                                                    your rights.
                                                </li>
                                                <li><strong className="fw-bold text-dark">5. </strong> If you are
                                                    residing in the United States including states such as California,
                                                    Colorado, Connecticut, Delaware, Florida, Iowa, Montana, Nevada,
                                                    Oregon, Texas, Utah, Vermont, Virginia, or Washington, or are
                                                    otherwise protected by privacy or consumer health data laws in those
                                                    jurisdictions, please refer to Schedule V that outlines
                                                    state-specific rights and protections afforded to you.
                                                </li>
                                                <li><strong className="fw-bold text-dark">6. </strong> Please note that
                                                    these Schedules are supplemental to our Privacy Policy.
                                                </li>
                                                <li><strong className="fw-bold text-dark">7. </strong> In relation to
                                                    the above, you can exercise such right by sending us an email with
                                                    your request to ____________________ along with the necessary proof
                                                    of identity requirements that we may require prior to processing
                                                    such a request from you.
                                                </li>
                                                <li><strong className="fw-bold text-dark">8. </strong> We are committed
                                                    to respecting and protecting your privacy and will make every effort
                                                    to accommodate your requests and address your concerns in accordance
                                                    with applicable laws. It's important to note that we may not
                                                    accommodate a request to change information if we believe the change
                                                    would violate any law or legal requirement or cause the information
                                                    to be incorrect. This ensures that your rights are upheld and that
                                                    we respond to your inquiries promptly and effectively.
                                                </li>
                                                <li><strong className="fw-bold text-dark">9. </strong> While we strive
                                                    to cover the privacy rights of Users in various jurisdictions
                                                    through this Privacy Policy and the accompanying Schedules, we
                                                    understand that some Users may be in regions with specific data
                                                    protection laws that are not explicitly addressed here. If you are a
                                                    User whose data subject rights are not clearly covered in this
                                                    Privacy Policy or the Schedules, please reach out to us for
                                                    assistance at ____________________. You can contact us to: (a)
                                                    inquire about the processing of your Personal Information; (b)
                                                    request access, rectification, or erasure of your Personal
                                                    Information; (c) restrict or object to the processing of your
                                                    Personal Information; and (d) exercise any other rights granted
                                                    under applicable data protection laws in your jurisdiction.
                                                </li>
                                                <li><strong className="fw-bold text-dark">10. </strong> Please be aware
                                                    that these rights are subject to legal restrictions. We will respond
                                                    to your request as soon as possible, typically within one month at
                                                    the latest. If you believe that our processing of your Personal
                                                    Information is unlawful, you have a right to complain to the
                                                    _____________. We hope that you will first contact us so that we can
                                                    assess your objections and clarify any misunderstandings.
                                                </li>
                                            </ul>

                                        </ul>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">12. ARE CHILDREN ALLOWED TO USE OUR PLATFORM
                                        AND/OR SERVICES?
                                    </h5>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. </strong> This Platform and/or
                                            Services are strictly prohibited for use by individuals under the age of
                                            eighteen (18) years or the age of majority as defined by the applicable laws
                                            of their country of residence. Specifically, individuals under the age of
                                            sixteen (16) residing in European Union (EU) countries and those under
                                            thirteen (13) years old in the United States or United Kingdom are also
                                            restricted from accessing our Platform and Services (collectively referred
                                            to as “<strong className="fw-bold text-dark">Minors</strong>”) Accessing or
                                            using the Platform and/or Services by Minors constitutes a violation of our
                                            Terms of Service and Privacy Policy.

                                        </li>
                                        <li><strong className="fw-bold text-dark">2. </strong> We do not knowingly
                                            collect, solicit, or process any Personal Information from Minors. We
                                            implement commercially reasonable age verification measures and data
                                            protection practices to prevent such unauthorised collection and usage.
                                        </li>
                                        <li><strong className="fw-bold text-dark">3. </strong> If you are a parent or
                                            legal guardian ("<strong className="fw-bold text-dark">Guardian</strong>")
                                            and believe your child has provided us with Personal Information, we urge
                                            you to promptly contact us at _____________________. Upon verification of
                                            your Guardian status, we will promptly take all necessary steps to remove
                                            and delete such information from our records.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">13. HOW CAN YOU EXPRESS YOUR COMPLAINTS AND
                                        CONCERNS?
                                    </h5>
                                    <p>User satisfaction is one of the key focus areas and an integral part of our
                                        Platform’s founding principles and business policies. We strongly believe that
                                        User satisfaction is the most important factor in the growth and development of
                                        our business and hence, we have adopted User centricity as a priority in
                                        developing our business processes. The terms below shall constitute our “<strong
                                            className="fw-bold text-dark">User Grievance Redressal Policy</strong>”
                                        which outlines the framework for addressing User grievances:
                                    </p>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. Objective: </strong> The objective
                                            of this Grievance Policy is to provide a framework:

                                        </li>
                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. </strong> to ensure the
                                                provision of timely and effective resolution of issues raised by the
                                                User; and

                                            </li>
                                            <li><strong className="fw-bold text-dark">2. </strong> to keep the User
                                                informed about the manner in which they can reach out to us to resolve
                                                their queries and grievances.

                                            </li>
                                        </ul>
                                        <li><strong className="fw-bold text-dark">2. Governing Principles: </strong> The
                                            policy on grievance redressal is governed by the following principles:

                                        </li>
                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. </strong> User shall be treated
                                                fairly at all times;

                                            </li>
                                            <li><strong className="fw-bold text-dark">2. </strong> issues raised by
                                                Users are always attended to with courtesy and on time;

                                            </li>
                                            <li><strong className="fw-bold text-dark">3. </strong> Users are provided
                                                with effective and satisfactory resolution within a reasonable time
                                                period; and

                                            </li>
                                            <li><strong className="fw-bold text-dark">2. </strong> Users are fully
                                                informed of avenues to escalate their issues/ grievances if they are not
                                                fully satisfied with the response to their complaints.

                                            </li>
                                        </ul>
                                        <li><strong className="fw-bold text-dark">3. Grievance Redressal: </strong> Any
                                            User can reach out to our Grievance Redressal Officer ______________ through
                                            electronic mode by way of email communication at _______________________;
                                            and

                                        </li>
                                        <li><strong className="fw-bold text-dark">4. Must Know: </strong> You must know
                                            and understand that-

                                        </li>
                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. </strong> We DO NOT solicit
                                                confidential details like your OTP/CVV/PIN/Card Number/ Bank account
                                                details through any means.
                                            </li>
                                            <li><strong className="fw-bold text-dark">2. </strong> Scamsters/fraudsters
                                                attempt various techniques such as ‘phishing’, to contact, influence,
                                                and defraud consumers. We regularly caution our Users against sharing
                                                any personal or payment-sensitive information with unknown persons as
                                                such sharing leads to unauthorized use and/or fraud and consequent
                                                financial loss.

                                            </li>
                                            <li><strong className="fw-bold text-dark">3. </strong> We shall not be
                                                liable for any loss, damage, or expense incurred by a User where the
                                                User has shared personal and/or payment-sensitive information with
                                                scamsters/fraudsters.

                                            </li>
                                            <li><strong className="fw-bold text-dark">2. </strong> We also request and
                                                encourage our Users to report such attempts or incidents to us at
                                                ____________________ to enable us to investigate and explore legal
                                                recourse.

                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">14. HOW ARE CHANGES MADE TO THIS POLICY?
                                    </h5>
                                    <p>This Policy may be updated at our sole discretion or due to changes in the law.
                                        Such changes, unless otherwise stated, will be effective from the day and date
                                        of posting on the Platform. We reserve the right to update the Policy without
                                        obligation to notify Users. It is recommended to regularly review this Policy
                                        for any changes, as your continued access and use of the Platform will be
                                        considered your approval and acceptance of all modifications to this Policy. In
                                        cases where applicable law mandates, we may notify you of updates through email.
                                        If you do not agree with this Policy governing our Platform, please refrain from
                                        using the Platform or the Services provided by us.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">15. HOW CAN YOU CONTACT US?

                                    </h5>
                                    <p className='mb-0'>Should you need additional information or have any questions or
                                        complaints regarding the handling of your Personal Information, please reach out
                                        to us in writing at:

                                    </p>
                                    <p>Emal : __________________________</p>
                                </div>
                            </div>
                        </div>
                        <div className="row row-gap-3 mt-3">
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className='text-center fw-bold mb-4'>Schedule I- India Residents
                                    </h5>
                                    <p>We are committed to safeguarding the privacy rights of our Users in India. This
                                        schedule outlines our compliance with Indian privacy laws, including the <strong
                                            className="fw-bold text-dark"> Information Technology (Reasonable Security
                                            Practices and Procedures and Sensitive Personal Data or Information) Rules,
                                            2011 (IT Rules), and the Digital Personal Data Protection Act (DPDPA), 2023.
                                            These regulations ensure that Personal Data is processed fairly, lawfully,
                                            and transparently</strong>.
                                    </p>
                                    <h5>YOUR DATA SUBJECT RIGHTS:</h5>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. Right to Consent: </strong> Your
                                            explicit consent is required for the collection and processing of your
                                            Sensitive Personal Data or Information (SPDI).

                                        </li>
                                        <li><strong className="fw-bold text-dark">2. Right to Access: </strong> You have
                                            the right to access your Personal Data and obtain a copy of it.
                                        </li>
                                        <li><strong className="fw-bold text-dark">3. Right to Correction: </strong> You
                                            can request corrections to any inaccurate or incomplete Personal Data.

                                        </li>
                                        <li><strong className="fw-bold text-dark">4.Right to Withdrawal of
                                            Consent: </strong> You can withdraw your consent to the processing of your
                                            Personal Data at any time.

                                        </li>
                                        <li><strong className="fw-bold text-dark">5. Right to Review
                                            Information: </strong> You can review the information you have provided and
                                            ensure it is accurate and up-to-date.

                                        </li>
                                        <li><strong className="fw-bold text-dark">6. Right to Grievance
                                            Redressal: </strong> You have the right to lodge a complaint regarding the
                                            processing of your Personal Data with our Grievance Officer.

                                        </li>
                                    </ul>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">DATA RETENTION AND DE-IDENTIFICATION:
                                        </strong></li>
                                        We are committed to retaining Personal Information only for as long as necessary
                                        to fulfil the purposes for which it was collected, as outlined in our main
                                        Privacy Policy (see Clause 7). We may also take measures to de-identify Personal
                                        Information in accordance with applicable laws and regulations.
                                    </ul>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">APPEAL PROCESS
                                        </strong></li>
                                        If you disagree with the Company's response to your privacy rights request or
                                        believe your rights have not been adequately addressed, you have the right to
                                        appeal. You can submit your appeal in writing to our Data Protection Officer at
                                        _____________ with the subject line "Appeal of Privacy Rights Request."

                                    </ul>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">LODGING A COMPLAINT
                                        </strong></li>
                                        If you believe your rights have been violated or are dissatisfied with our
                                        response, you have the right to lodge a complaint with the <strong
                                        className="fw-bold text-dark">Office of the Data Protection Commissioner of
                                        India (DPIC)</strong>. The DPIC is responsible for handling complaints related
                                        to data protection and ensuring compliance with the DPDPA.
                                    </ul>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">ADDITIONAL INFORMATION:

                                        </strong></li>
                                        For more detailed information on how we handle your Personal Information,
                                        including our data collection practices, security measures, and third-party
                                        disclosures, please refer to our main Privacy Policy available.
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className='text-center fw-bold mb-4'>Schedule II- European Union Residents</h5>
                                    <p>We are committed to ensuring compliance with the <strong
                                        className="fw-bold text-dark">European Union General Data Protection Regulation
                                        (‘GDPR’)</strong>.
                                    </p>
                                    <p>Although our Privacy Policy explains how we meet all of our obligations for
                                        United Kingdom Users, we also have some Users who are habitually located in the
                                        European Union (‘EU Residents’) that have additional rights with respect to
                                        their Personal Data.
                                    </p>
                                    <p>Personal Data is defined as: “Any information relating to an identifiable person
                                        who can be directly or indirectly identified in particular by reference to an
                                        identifier”. The term “Personal Data” should be considered fundamentally
                                        interchangeable with the expression “Personal Information” for the purposes of
                                        this Privacy Policy.
                                    </p>
                                    <p>Under the GDPR, we are the “Data Controller” and “Data Processor” of Personal
                                        Data. As part of our GDPR compliance, we provide and operate the App in a way
                                        that ensures: Personal Data (i.e. Personal Information) is processed fairly,
                                        lawfully, and in a transparent manner; and collected and processed only for
                                        specified and lawful purposes.
                                    </p>
                                    <p>We ensure that the Personal Data we collect about you is accurate, complete, and
                                        used for its intended purpose. You may access, review, correct, and update your
                                        Information by contacting us by email at the contact details below.
                                    </p>
                                    <h6 className='fw-bold'>EUROPEAN UNION RESIDENTS
                                    </h6>
                                    <p>In certain circumstances, you have certain rights regarding your Personal
                                        Information. A summary of each right and how you can exercise it is detailed
                                        below. To exercise any of these rights, please contact us at _____________. Such
                                        requests should include information to allow us to verify your identity (e.g.
                                        your name, address, email address, or other information reasonably required).
                                    </p>
                                    <p>Where we receive your request to exercise one of these rights, we will respond
                                        without undue delay and within the time required by applicable law. This may be
                                        extended in certain circumstances, e.g. where requests are complex or numerous.
                                    </p>
                                    <p>We will provide the information free of charge, except where requests are
                                        manifestly unfounded or excessive, e.g. because of their repetitive character.
                                        In these circumstances, we may charge a reasonable fee or may refuse to act on
                                        the request. We will advise you of any fees prior to proceeding with a request.
                                        We may ask for additional information to verify your identity before carrying
                                        out a request.
                                    </p>
                                    <h6>HOW CAN YOU EXERCISE YOUR RIGHT?
                                    </h6>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. Right to access and/or correct your
                                            Personal Data
                                        </strong>
                                            <li>You have the right to access the Personal Data we hold about you, and to
                                                be provided with a copy of the information (in most circumstances). You
                                                also have the right to correct any information we may hold about you
                                                that is inaccurate.
                                            </li>
                                        </li>

                                        <li><strong className="fw-bold text-dark">2. Right to restrict the use of your
                                            Personal Data
                                        </strong>
                                            <li>You have the right to ask us to restrict the processing of your Personal
                                                Data where one of the following applies:
                                                <ul className="ps-3 text-secondary listing some-space">
                                                    <li><strong className="fw-bold text-dark">1. </strong> The
                                                        processing is unlawful, but you want us to restrict the use of
                                                        the data instead of deleting it;
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">2. </strong> Where you
                                                        contest the accuracy of your Personal Data, the restriction will
                                                        apply until we have verified the accuracy or corrected your
                                                        Personal Data;
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">3. </strong> We no longer
                                                        require the Personal Data for the purposes of the processing,
                                                        but are required to keep it in connection with a legal claim;
                                                    </li>
                                                    <li><strong className="fw-bold text-dark">4. </strong> You have
                                                        exercised your right to object to the processing. The
                                                        restriction will apply until we have taken steps to verify
                                                        whether we have compelling legitimate grounds to continue
                                                        processing.
                                                    </li>
                                                </ul>
                                            </li>

                                        </li>
                                        <li className="fw-bold text-dark">3. Right to withdraw consent and request
                                            deletion of your Personal Data

                                        </li>
                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. </strong> You have the right to
                                                ask us to delete your Personal Data in most circumstances. There are
                                                also certain exceptions where we may refuse a request for erasure, for
                                                example, where the Personal Data is required to comply with a legal
                                                obligation or for the establishment, exercise, or defence of legal
                                                claims.
                                            </li>
                                            <li><strong className="fw-bold text-dark">2. </strong> You may object to our
                                                use of your Personal Data for marketing purposes and you can let us know
                                                via the provided email address.
                                            </li>
                                            <li><strong className="fw-bold text-dark">3. </strong> Further, you may also
                                                object to the processing of your Personal Data in cases where we have
                                                used legitimate interests as the basis for processing. In such cases, we
                                                will stop processing your Personal Data until we verify that we have
                                                compelling legitimate grounds for processing that outweigh your
                                                interests, rights, and freedoms in asking us to stop processing the
                                                data, or in limited cases where we need to continue processing the data
                                                for the establishment, exercise, or defence of legal claims.
                                            </li>

                                        </ul>
                                        <li><strong className="fw-bold text-dark">4. Data retention and
                                            anonymisation</strong>
                                            <p>We are committed to retaining Personal Information only for as long as
                                                necessary to fulfil the purposes for which it was collected, as outlined
                                                in our main Privacy Policy (see Clause 7). We may also take measures to
                                                de-identify Personal Information in accordance with applicable laws and
                                                regulations.
                                            </p>

                                        </li>
                                        <li><strong className="fw-bold text-dark">5. Right to data portability
                                        </strong>
                                            <p>In most cases, you have the right to receive all Personal Data you have
                                                provided to us in a structured, commonly used, and machine-readable
                                                format and to transmit this data to another data controller, where
                                                technically feasible. </p>

                                        </li>
                                        <li><strong className="fw-bold text-dark">6. Right to lodge a complaint with a
                                            supervisory authority
                                        </strong>
                                            <p>If you are a resident of the EU, you have the right to make a complaint
                                                at any time to the regulator in your jurisdiction. To find out how to
                                                contact your local regulator, please visit <a
                                                    href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
                                                    target="_blank">https://edpb.europa.eu/about-edpb/about-edpb/members_en</a>.
                                            </p>
                                            <p><strong className="fw-bold text-dark">We will allow and assist Users who
                                                are EU Residents to exercise these rights unless we have compelling and
                                                legitimate legal grounds not to (e.g. a legal obligation under United
                                                Kingdom legislation, or if the Personal Data has been fully anonymized).
                                            </strong></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className='text-center fw-bold mb-4'>Schedule III: United Kingdom (UK) Residents
                                    </h5>
                                    <p>We are dedicated to upholding the principles of the <strong
                                        className="fw-bold text-dark"> Kingdom General Data Protection Regulation (UK
                                        GDPR) and the Data Protection Act 2018.
                                    </strong></p>
                                    <p>While our Privacy Policy outlines our commitments to European Union Users, we
                                        recognize that Users located in the United Kingdom (‘UK Residents’) have
                                        additional rights concerning their Personal Data.
                                    </p>

                                    <h6 className='fw-bold mb-2'>YOUR DATA SUBJECT RIGHTS:
                                    </h6>
                                    <p className='mb-0'>You have a number of rights under applicable Data Protection
                                        Laws in relation to your Personal Information. Under certain circumstances, you
                                        have the right to:
                                    </p>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1.</strong> Have access to your
                                            Personal Information by submitting a request to us;
                                        </li>
                                        <li><strong className="fw-bold text-dark">2.</strong> Have your Personal
                                            Information deleted;
                                        </li>
                                        <li><strong className="fw-bold text-dark">3.</strong> Have your Personal
                                            Information corrected if it is wrong;
                                        </li>
                                        <li><strong className="fw-bold text-dark">4.</strong> Have the processing of
                                            your Personal Information restricted;
                                        </li>
                                        <li><strong className="fw-bold text-dark">5.</strong> Object to further
                                            processing of your Personal Information, including to object to marketing
                                            from us;
                                        </li>
                                        <li><strong className="fw-bold text-dark">6.</strong> Make a data portability
                                            request;
                                        </li>
                                        <li><strong className="fw-bold text-dark">7.</strong> Withdraw any consent you
                                            have provided to us;
                                        </li>
                                        <li><strong className="fw-bold text-dark">8.</strong> Restrict any automatic
                                            processing of your Personal Information;
                                        </li>
                                        <li><strong className="fw-bold text-dark">9.</strong> Complaint to the
                                            appropriate supervisory authority;
                                        </li>
                                        <li><strong className="fw-bold text-dark">10.</strong> To exercise any of these
                                            rights, please contact us _____________.
                                        </li>
                                        <li className='mt-2'>
                                            <strong className="fw-bold text-dark ">A. BASIS FOR PROCESSING:</strong>
                                            <p>We will only process your Personal Information when we have a lawful
                                                basis to do so, such as consent, contractual necessity, or legitimate
                                                interests. Also, please refer to Clause 5 to understand how we process
                                                your Personal Information.</p>
                                        </li>
                                        <li>
                                            <strong className="fw-bold text-dark">B. PROCESS OF APPEAL:</strong>
                                            <p className='mb-0'>If you disagree with the Company's response to your
                                                privacy rights request or believe your rights have not been adequately
                                                addressed, you have the right to appeal. You can submit your appeal in
                                                writing to our Data Protection Officer at _____________ with the subject
                                                line "Appeal of Privacy Rights Request" or you can send us a notice at
                                                the following address.</p>
                                            <p className='mb-0'><strong
                                                className="fw-bold text-dark">Address: </strong> _____________
                                            </p>
                                            <p className='mb-0'><strong
                                                className="fw-bold text-dark">Email: </strong> _____________
                                            </p>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className='text-center fw-bold'>Schedule IV: Canada Residents</h5>
                                    <p>
                                        We are committed to upholding the privacy rights of our Users in Canada. This
                                        schedule outlines our compliance with Canadian privacy laws, including
                                        the <strong className="fw-bold text-dark">Personal Information Protection and
                                        Electronic Documents Act (PIPEDA)</strong> and any applicable provincial privacy
                                        legislation.
                                    </p>
                                    <p>
                                        While our Privacy Policy details our overall data practices, this section
                                        specifically addresses the rights and protections available to residents of
                                        Canada regarding their Personal Data.
                                    </p>

                                    <h6 className='fw-bold mb-0'>Definition of Personal Data:</h6>
                                    <p className='mb-0'>
                                        “Personal Data” refers to information about an identifiable individual, as
                                        defined under PIPEDA. This includes but is not limited to, information such as
                                        your name, address, email address, phone number, and other identifying details.
                                        The term “Personal Data” should be considered fundamentally interchangeable with
                                        the expression “Personal Information” for the purposes of this Privacy Policy.
                                    </p>

                                    <h6 className='fw-bold mt-4 mb-0'>YOUR RIGHTS:</h6>
                                    <p className='mb-0 mt-2'>Canadian residents have several rights concerning their
                                        Personal Data. These rights include:</p>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li className="lh-base"><strong className="fw-bold text-dark">1. Right to Access
                                            Personal Data –</strong> You have the right to request access to your
                                            Personal Data held by us. Upon request, we will provide you with a copy of
                                            the information in our possession, subject to certain exceptions provided by
                                            law.
                                        </li>
                                        <li className="lh-base"><strong className="fw-bold text-dark">2. Right to
                                            Correct Personal Data – </strong> If you believe that any Personal Data we
                                            hold about you is incorrect or incomplete, you have the right to request
                                            corrections.
                                        </li>
                                        <li className="lh-base"><strong className="fw-bold text-dark">3. Right to
                                            Withdraw Consent – </strong> You may withdraw your consent to our processing
                                            of your Personal Data at any time, subject to legal or contractual
                                            restrictions.
                                        </li>
                                        <li className="lh-base"><strong className="fw-bold text-dark">4. Right to Data
                                            Portability – </strong> In certain circumstances, you may request a copy of
                                            your Personal Data in a structured, commonly used, and machine-readable
                                            format.
                                        </li>
                                        <li className="lh-base"><strong className="fw-bold text-dark">5. Right to Object
                                            to Processing –</strong> You may object to the processing of your Personal
                                            Data for specific purposes, such as direct marketing.
                                        </li>
                                        <li className="lh-base"><strong className="fw-bold text-dark">6. Right to
                                            Erasure –</strong> You may request deletion of your Personal Data, subject
                                            to legal exceptions.
                                        </li>
                                        <li className="lh-base"><strong className="fw-bold text-dark">7. Right to
                                            Restrict Processing –</strong> You may request restriction of processing in
                                            certain circumstances, such as contesting accuracy or processing legality.
                                        </li>

                                        <li className="mt-3 lh-base"><strong className="fw-bold text-dark">HOW TO
                                            EXERCISE YOUR RIGHTS?</strong></li>

                                        <p>To exercise any of these rights, please contact us at ___________. Requests
                                            should include sufficient information for us to verify your identity and
                                            understand the nature of your request (e.g., name, address, email
                                            address).</p>
                                        <li className='mt-2'>
                                            <strong className="fw-bold text-dark">FEES FOR REQUESTS:</strong>
                                            <p>We will generally process your requests free of charge. However, if a
                                                request is manifestly unfounded or excessive, we may charge a reasonable
                                                fee or refuse to act on the request. We will notify you of any fees in
                                                advance.

                                            </p></li>

                                        <li>
                                            <strong className="fw-bold text-dark">BASIS FOR PROCESSING:</strong>
                                            <p className='mb-1'>We will process your Personal Data based on one or more
                                                of the following lawful bases:</p>
                                            <ul className='ps-3 text-secondary listing some-space'>
                                                <li><strong className="fw-bold text-dark">Consent: </strong> Where you
                                                    have provided explicit consent for processing.
                                                </li>
                                                <li><strong className="fw-bold text-dark">Contractual
                                                    Necessity:</strong> Where processing is necessary to fulfill a
                                                    contract with you.
                                                </li>
                                                <li><strong className="fw-bold text-dark">Legal
                                                    Obligation:</strong> Where processing is required to comply with
                                                    legal obligations.
                                                </li>
                                                <li><strong className="fw-bold text-dark">Legitimate
                                                    Interests:</strong> Where processing is necessary for our or
                                                    third-party legitimate interests, unless overridden by your rights
                                                    and freedoms.
                                                </li>
                                            </ul>
                                        </li>

                                        <li className='mt-2'>
                                            <strong className="fw-bold text-dark"> PROCESS OF APPEAL:</strong>
                                            <p className='mb-0'>If you disagree with the Company's response or believe
                                                your rights have not been adequately addressed, you may appeal.</p>
                                            <p className='mb-0'>Submit your appeal in writing to our Data Protection
                                                Officer at _____________ with the subject line "Appeal of Privacy Rights
                                                Request" or send a notice to:</p>
                                            <p className='mb-0'><strong
                                                className="fw-bold text-dark">Email: </strong> _____________</p>
                                        </li>

                                        <li className='mt-2'>
                                            <strong className="fw-bold text-dark "> RIGHT TO LODGE A COMPLAINT:</strong>
                                            <p className='mb-0'>If you are not satisfied with our response to your
                                                appeal, you have the right to lodge a complaint with the Office of the
                                                Privacy Commissioner of Canada or the relevant provincial privacy
                                                authority.
                                            </p>
                                            <p className="mt-2">
                                                For more information on how to contact the Office of the Privacy
                                                Commissioner of Canada, please visit{' '}
                                                <a href="https://www.priv.gc.ca/en/contact-the-opc/" target="_blank"
                                                   rel="noopener noreferrer">
                                                    https://www.priv.gc.ca/en/contact-the-opc/.

                                                </a>.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="text-center fw-bold">Schedule V: United States Residents</h5>
                                    <p>
                                        We are dedicated to adhering to the<strong className="fw-bold text-dark">
                                        <strong className="fw-bold text-dark">California Consumer Privacy Act
                                            (CCPA)</strong>, California Privacy Rights Act (CPRA),
                                        Virginia Consumer Data Protection Act (VCDPA), Colorado Privacy Act
                                        (CPA)</strong>, and other relevant state privacy
                                        regulations. If you are a resident of California, Colorado, Connecticut,
                                        Delaware, Florida, Iowa, Montana, Nevada,
                                        Oregon, Texas, Utah, Vermont, Virginia, or Washington, or fall under the
                                        protections of similar state laws, this
                                        section serves as an addition to our primary Privacy Policy, detailing your
                                        specific rights and protections.
                                    </p>
                                    <p>
                                        According to the CCPA, "Personal Information" encompasses any data that can
                                        identify, relate to, describe, or be
                                        reasonably associated with a specific consumer or household. This includes
                                        names, addresses, online identifiers,
                                        and more. We make every effort to ensure that the Personal Information we gather
                                        is precise, relevant, and
                                        utilised correctly.
                                    </p>
                                    <p>
                                        You have the right to access, delete, and request disclosures regarding your
                                        Personal Information. For any
                                        questions or to exercise these rights, please reach out to us using the contact
                                        details provided below.
                                    </p>

                                    <h6 className="fw-bold mb-2 some-space">A. YOUR PRIVACY RIGHTS:</h6>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. Right to Access –</strong> You can
                                            access the information that has been provided by you by reaching out to us
                                            at _____________.
                                        </li>
                                        <li><strong className="fw-bold text-dark">2. Right to Withdraw Consent
                                            –</strong> You may withdraw your consent in writing at any time. Upon
                                            withdrawal, we will stop processing your data, unless legally required
                                            otherwise.
                                        </li>
                                        <li><strong className="fw-bold text-dark">3. Right to Opt-Out –</strong> You
                                            have the right to opt out of the sale of your Personal Information or its
                                            use in targeted advertising. We will respect and implement your preferences.
                                        </li>
                                        <li><strong className="fw-bold text-dark">4. Right to Correction –</strong> You
                                            can correct or update your Personal Information through the App or by
                                            contacting us at _____________.
                                        </li>
                                        <li><strong className="fw-bold text-dark">5. Right of Access and Portability
                                            –</strong> You may request a structured, machine-readable copy of your
                                            Personal Information and request its transfer to another provider.
                                        </li>
                                        <li><strong className="fw-bold text-dark">6. Right of Erasure –</strong> In some
                                            jurisdictions, you can request deletion of your Personal Information,
                                            subject to legal exceptions.
                                        </li>
                                    </ul>

                                    <h6 className="fw-bold mt-3 ">B. DATA CONTROLLER AND PROCESSOR:</h6>
                                    <p>
                                        We act as both the “Data Controller” and “Data Processor” of your Personal
                                        Information under various U.S. privacy
                                        laws. We process data fairly, lawfully, and transparently, and only for
                                        legitimate and specified purposes.
                                    </p>

                                    <h6 className="fw-bold ">C. APPEAL PROCESS:</h6>
                                    <p className="mb-2">
                                        If you disagree with the Company's response to your privacy rights request or
                                        believe your rights have not been
                                        adequately addressed, you may submit an appeal to our Data Protection Officer at
                                        _____________ with the subject line
                                        "Appeal of Privacy Rights Request," or send a written notice to:
                                    </p>
                                    <p className="mb-0"><strong
                                        className="fw-bold text-dark">Address:</strong> _____________</p>
                                    <p className="mb-0"><strong
                                        className="fw-bold text-dark">Email:</strong> _____________</p>

                                    <h6 className="fw-bold mt-3">D. DATA RETENTION AND DE-IDENTIFICATION:</h6>
                                    <p>
                                        We retain Personal Information only as long as necessary to fulfill the purposes
                                        described in our Privacy Policy (see Clause 7).
                                        We may also de-identify data in accordance with applicable privacy laws and
                                        regulations.
                                    </p>

                                    <h6 className="fw-bold ">E. LODGING A COMPLAINT:</h6>
                                    <p>
                                        If you believe your rights have been violated or are dissatisfied with our
                                        response, you can lodge a complaint with
                                        the Federal Trade Commission (FTC), Consumer Financial Protection Bureau (CFPB),
                                        or the Attorney General’s Office
                                        of your state.
                                    </p>

                                    <h6 className="fw-bold ">F. ADDITIONAL INFORMATION:</h6>
                                    <p>
                                        For more detailed information on how we handle your Personal Information,
                                        including our data collection practices,
                                        security measures, and third-party disclosures, please refer to our main Privacy
                                        Policy.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>


            <DownloadApplications/>
            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default PrivacyPolicy;
