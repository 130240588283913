import React, {useEffect, useState} from "react"
import {CabinObject, getArrivalDays} from "../../../components/utils/appUtils";
import {Modal} from "../../../components/Elements"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import AirportImg from '../../../assets/img/airport.png'
import FlightFareIconsComponent from "../components/flightFareIconsComponent";
import WithStopComponent from "./withStopComponent";
import NonStopComponent from "./nonStopComponent";
import {reissueTicketFxn} from "../../ticketList/action";

const MultiCityFlightDetails = (props) => {
    const { data, flightInfo } = props;
    const [showModal, setShowModal] = useState({
        visible: false,
        searchDestination: '',
        flightDestination: ''
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleModalAction = (action) => {
        if (action === "yes") {
            setShowModal({ ...showModal, visible: false });
            dispatch({
                type: "UPDATE_RESULT_INDEX",
                selectedFlight: { ...data, FareBasisCode: {} },
                traceId: flightInfo.traceId,
            });
            navigate("/checkout");
        } else {
            setShowModal({ ...showModal, visible: false });
        }
    };

    const confirmBooking = () => {
        const searchOrigin = flightInfo.origin;
        const searchDestination = flightInfo.destination;
        const flightOrigin = data?.Segments?.[0]?.[0]?.Origin?.Airport?.AirportCode;
        const firstSegment = data?.Segments?.[0];
        const flightDestination = firstSegment?.[firstSegment.length - 1]?.Destination?.Airport?.AirportCode;

        if (searchDestination !== flightDestination) {
            setShowModal({
                visible: true,
                searchDestination: `${searchOrigin}-${searchDestination}`,
                flightDestination: `${flightOrigin}-${flightDestination}`,
            });
        } else {
            dispatch({
                type: "UPDATE_RESULT_INDEX",
                selectedFlight: { ...data, FareBasisCode: {} },
                traceId: flightInfo.traceId,
                flightBookingType: "onWayFlight"
            });
            navigate("/checkout");
        }
    };

    const calculateArrivalDays = (segments) => {
        if (!segments || !segments.length) return "N/A";

        const firstSegment = segments[0][0];
        const lastSegment = segments[0][segments[0].length - 1];

        const departureDate = new Date(firstSegment.Origin.DepTime);
        const arrivalDate = new Date(lastSegment.Destination.ArrTime);

        const depDateOnly = new Date(departureDate.getFullYear(), departureDate.getMonth(), departureDate.getDate());
        const arrDateOnly = new Date(arrivalDate.getFullYear(), arrivalDate.getMonth(), arrivalDate.getDate());

        const timeDifference = arrDateOnly - depDateOnly;
        const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

        return dayDifference >= 1 ? `${dayDifference} day${dayDifference > 1 ? "s" : ""}` : "same day";
    };

    const arrivalDayValue = calculateArrivalDays(data?.Segments);

    return (
        data?.Segments?.length ? (
            <>
                <div className="book-flight mb-3">
                    <div className="row row-gap-3">
                        <div className="col-lg-10">
                            <div className="track-flights overseas-return-track">
                                {data.Segments.map((segmentGroup, index) => (
                                    <div key={index}>
                                        {segmentGroup.length > 1 ? (
                                            <WithStopComponent data={{ ...data, Segments: [segmentGroup] }} />
                                        ) : (
                                            <NonStopComponent data={{ ...data, Segments: [segmentGroup] }} />
                                        )}
                                        <div className="mt-2">
                                            <FlightFareIconsComponent
                                                flight={data}
                                                bookingType={"Inbound"}
                                                traceId={flightInfo.traceId}
                                            />
                                        </div>
                                        <hr />
                                    </div>
                                ))}
                            </div>

                            {data.IsTransitVisaRequired && (
                                <small className="d-block mt-2">
                                    <span className="color-red">Transit Visa is Required</span>
                                </small>
                            )}
                        </div>

                        <div className="col-lg-2">
                            <div className="d-flex flex-wrap gap-3 h-100">
                                <div className="total-price cursor-pointer">
                                    <span>Total Price</span>
                                    <h5>Rs. {data?.Fare?.newFare || ""}</h5>
                                    <button
                                        onClick={confirmBooking}
                                        className="btn btn-primary"
                                    >
                                        Book Now
                                    </button>
                                </div>

                                {arrivalDayValue !== "same day" && (
                                    <div className="total-price cursor-pointer">
                                        <span>Flight Arrives in {arrivalDayValue}</span>
                                    </div>
                                )}

                                {data?.Segments?.[0]?.[0]?.NoOfSeatAvailable > 0 && (
                                    <div className="total-price cursor-pointer">
                                        <span className="ms-2" style={{ color: "red" }}>
                                            Seats left: {data.Segments[0][0].NoOfSeatAvailable}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {showModal.visible && (
                    <Modal
                        visible={showModal.visible}
                        className="popup-confirm"
                        width="38%"
                        onClose={() => setShowModal({ ...showModal, visible: false })}
                    >
                        <div className="mt-1 alert-popup">
                            <div className="mb-4">
                                <img src={AirportImg} alt="airportimg" width="30%" />
                            </div>
                            <h3 className="mb-4">Different Airport Alert</h3>
                            <p className="mb-4">
                                You have searched for Outbound <strong>({showModal.searchDestination})</strong>,
                                however, this flight operates from Outbound <strong>({showModal.flightDestination})</strong>.
                            </p>
                            <h3 className="mb-4">Do you wish to continue?</h3>
                            <div className="d-flex justify-content-center gap-3 mb-2">
                                <button className="customYesBtn" onClick={() => handleModalAction("yes")}>Yes</button>
                                <button className="customNoBtn" onClick={() => handleModalAction("no")}>No</button>
                            </div>
                        </div>
                    </Modal>
                )}
            </>
        ) : null
    );
};

export default MultiCityFlightDetails;



