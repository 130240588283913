import React, { useState } from 'react';
import { Modal } from "../../../components/Elements";

const ApplyCouponModal = (props) => {
    const { onClose, visible, applyCouponCallback } = props;
    const [couponCode, setCouponCode] = useState('');
    const [error, setError] = useState('');

    const handleApply = () => {
        if (!couponCode.trim()) {
            setError('Enter a valid coupon code');
            return;
        }
        applyCouponCallback(couponCode);
        onClose();
    };

    return (
        <Modal visible={visible} className="popup-confirm" width="30%" height="auto" onClose={onClose} footer={null}>
            <div className="modal-header">
                <h5>Apply Coupon</h5>
            </div>
            <div style={{ borderBottom: '1px solid #ccc', marginTop: '8px' }} />
            <div className="modal-body">
                <div className="form-group mt-3">
                    <label htmlFor="couponCode">Coupon Code</label>
                    <input
                        id="couponCode"
                        type="text"
                        className="form-control mt10"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        placeholder="Enter coupon code"
                    />
                    {error && (
                        <div className="mt-2" style={{ color: 'red', fontSize: '0.9em' }}>
                            {error}
                        </div>
                    )}
                    <div className="d-flex justify-content-end mt-3">
                        <button className="btn btn-primary" onClick={handleApply}>
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};


export default ApplyCouponModal;
