import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {airLinesFxn} from "../actions";
import {FlightDatePicker, Form, notification, Radio, Select, Tooltip} from "../../../components/Elements";
import {CabinComponent, FareTypesComponent, PassengerComponent} from "./flightSearchComponent";
import moment from "moment";
import {displayDateOnly, fareTypeList} from "../../../components/utils/appUtils";
import {swapIcon} from "../../../components/imgComp"
import {FareTypesNewComponent} from "./flightSearchComponent";
import SelectCityComponent from "./SelectCityComponent";
import SelectJourneyTypeComponent from "./SelectJourneyTypeComponent";
// import TravellersComponent from "../flightSearchComponent/travellersComponent";
import TravellersComponent from "./travellersComponent";
import {calendarFareFxn} from "../../ticketList/action";
import ChooseDateComponent from "../flightSearchComponent/ChooseDateComponent";
import ChangeLocationImage from "../changeLocationImage";

const initState = {
    cabinClass: "2",
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    resultFareType: "2",
    preferredDepartureTime: moment()
}
const ModifySearchComponent = (props) => {
    let {flightObj = {}, flightSearch} = props;
    let [journeyType, setJourneyType] = useState("0");
    const [calendarFareData, setCalendarFareData] = useState({
        departure: [],
        return: []
    });
    useEffect(() => {
        if (flightObj.journeyType) {
            setJourneyType(flightObj.journeyType)
        } else {
            setJourneyType("1")
        }
    }, [])

    let dispatch = useDispatch()
    let [state, setState] = useState(initState)
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        _choosePreferredDepartureTime: (value) => {
            let obj = {preferredDepartureTime: value}
            if (obj.preferredReturnTime) {
                if (moment(obj.preferredReturnTime).isBefore(value)) {
                    obj.preferredReturnTime = value
                }
            } else {
                if (moment(state.preferredReturnTime).isBefore(value)) {
                    obj.preferredReturnTime = value
                }
            }
            events._updateState(obj)
        },
        handleSearchFlight: async () => {
            if (!state.origin) {
                return notification.warning({message: "Please choose Departing From."})
            }
            if (!state.destination) {
                return notification.warning({message: "Please choose Going To."})
            }
            if (!state.preferredDepartureTime) {
                return notification.warning({message: "Please choose Departure Date."})
            }
            if (!state.preferredReturnTime && journeyType == "2") {
                return notification.warning({message: "Please choose Return Date."})
            }
            if (state.adultCount == 0 && state.childCount == 0 && state.infantCount == 0) {
                return notification.warning({message: "Please choose Passenger."})
            }
            let obj = {
                ...state, journeyType
            }
            dispatch({type: "SEARCH_FLIGHT", flightObj: obj})
            flightSearch(obj)
        },
        changeJourneyType: (value) => {
            setJourneyType(value)
        },
        _updateReturnDate: (journeyType) => {
            if (journeyType == "1") {
                events._updateState({preferredReturnTime: null})
            } else {
                if (!state.preferredReturnTime) {
                    events._updateState({preferredReturnTime: state.preferredDepartureTime})
                }
            }
        },
        _choosePreferredReturnTime: () => {
            if (!state.preferredReturnTime) {
                events._updateState({preferredReturnTime: state.preferredDepartureTime})
            }
        },

        getCalendarFare: async (date, label = 'Departure') => {
            // console.log('from callback----', date, "label", label)
            let firstDayOfMonth = moment(date).startOf('month');

            if (firstDayOfMonth < moment()) {
                firstDayOfMonth = moment(date);
            }

            let obj = {
                origin: state?.origin,
                destination: state?.destination,
                cabinClass: state?.cabinClass,
                preferredDepartureTime: firstDayOfMonth,
                journeyType: journeyType
            };

            let objReturn = {
                origin: state?.destination,
                destination: state?.origin,
                cabinClass: state?.cabinClass,
                preferredDepartureTime: firstDayOfMonth,
                journeyType: journeyType
            };

            let valData = label === 'Return' ? objReturn : obj;
            let resp = await dispatch(calendarFareFxn(valData));

            if (resp?.data?.SearchResults) {
                setCalendarFareData((prevState) => {
                    return {
                        ...prevState,
                        [label.toLowerCase()]: [
                            ...prevState[label.toLowerCase()] || [],
                            ...resp.data.SearchResults,
                        ]
                    };
                });
            }
        },
        callbackCalendarFxn: async (date, label) => {
            let nextMonth = moment(date).add(1, 'month');

            await Promise.all([
                events.getCalendarFare(date, label),
                events.getCalendarFare(nextMonth, label)
            ]);
        },
    }
    useEffect(() => {
        if (flightObj) {
            if (flightObj.preferredDepartureTime) {
                flightObj.preferredDepartureTime = moment(flightObj.preferredDepartureTime)
            }
            if (flightObj.preferredReturnTime) {
                flightObj.preferredReturnTime = moment(flightObj.preferredReturnTime)
            }
            setState((prevState) => {
                return {
                    ...prevState,
                    ...flightObj
                }
            })
        }
    }, [flightObj])

    const disabledDate = (current, fromDate) => {
        return moment(current).isBefore(fromDate, 'day');
    };

    useEffect(() => {
        /*  if (journeyType == "1") {
              events._updateState({preferredReturnTime: null})
          } else {
              if (!state.preferredReturnTime) {
                  events._updateState({preferredReturnTime: state.preferredDepartureTime})
              }
          }*/
    }, [journeyType])


    useEffect(() => {
        setCalendarFareData({departure: [], return: []});
        const loadDefaultCalendarFares = async () => {
            let currentDate = moment();
            let nextMonth = moment().add(1, 'month');

            await Promise.all([
                events.getCalendarFare(currentDate, 'Departure'),
                events.getCalendarFare(nextMonth, 'Departure'),
                events.getCalendarFare(currentDate, 'Return'),
                events.getCalendarFare(nextMonth, 'Return')
            ]);
        };

        loadDefaultCalendarFares();
    }, [state.origin, state.destination]);


    return (
        <div>
            <Form>
                <div className="flight-booking modify-search-box">
                    <div className="container position-relative">
                        <div className="find-flight search-flight-main-box">
                            <div className="flight-top-filters">
                                <div className="radio-box mb-3">
                                    <div className="search-filter w-100">
                                        <div className="row row-gap-3 align-items-center">
                                            <div className="col-lg-2 col-12">
                                                <div className="double-box">
                                                    <SelectJourneyTypeComponent
                                                        journeyType={journeyType}
                                                        onSelect={(value) => {
                                                            setJourneyType(value);
                                                            events._updateReturnDate(value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-12">
                                                <div className="double-box city-double">
                                                    <SelectCityComponent
                                                        label={'From'}
                                                        placeholder={"From ?"}
                                                        cityId={state.origin}
                                                        cityName={state.originCity}
                                                        airportName={state.originAirportName}
                                                        onSelect={(data) => {
                                                            events._updateState({
                                                                origin: data.value,
                                                                originCity: data.cityName,
                                                                originAirportName: data.airportName,
                                                            })
                                                        }}
                                                    />
                                                    <ChangeLocationImage
                                                        state={state}
                                                        onClick={(data) => {
                                                            events._updateState(data)
                                                        }}
                                                    />

                                                    <SelectCityComponent
                                                        label={'To'}
                                                        style={{paddingLeft: 25}}
                                                        placeholder={"To ?"}
                                                        cityId={state.destination}
                                                        cityName={state.destinationCity}
                                                        airportName={state.destinationAirportName}
                                                        onSelect={(data) => {
                                                            events._updateState({
                                                                destination: data.value,
                                                                destinationCity: data.cityName,
                                                                destinationAirportName: data.airportName,
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-12">
                                                <div className="double-box" key={journeyType}>
                                                    <ChooseDateComponent
                                                        placeholder={'Departure Date'}
                                                        journeyType={journeyType}
                                                        value={state.preferredDepartureTime}
                                                        label={'Departure'}
                                                        type={'modify'}
                                                        searchResults={calendarFareData.departure}
                                                        callback={events.callbackCalendarFxn}
                                                        state={state}
                                                        disabledDate={(current) => disabledDate(current, moment())}
                                                        onChange={(value) => {
                                                            events._choosePreferredDepartureTime(value)
                                                        }}/>

                                                    <div className="divide-box"></div>
                                                    <ChooseDateComponent
                                                        placeholder={'Return'}
                                                        journeyType={journeyType}
                                                        value={state.preferredReturnTime}
                                                        startFrom={state.preferredDepartureTime}
                                                        label={'Return'}
                                                        type={'modify'}
                                                        state={state}
                                                        // defaultValue={state.preferredDepartureTime}
                                                        disabledDate={(current) => disabledDate(current, moment(state.preferredDepartureTime))}
                                                        changeJourneyType={(value) => {
                                                            setJourneyType(value)
                                                            events._choosePreferredReturnTime()
                                                        }}
                                                        callback={events.callbackCalendarFxn}
                                                        searchResults={calendarFareData.return}
                                                        onChange={(value) => {
                                                            events._updateState({preferredReturnTime: value})
                                                        }}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-12">
                                                <div className="double-box">
                                                    <TravellersComponent
                                                        state={state}
                                                        onChange={(data) => {
                                                            events._updateState(data)
                                                        }}
                                                        resultFareType={state.resultFareType}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-12">
                                                <a className={'btn btn-primary search-btn'}
                                                   onClick={() => {
                                                       events.handleSearchFlight()
                                                   }}>
                                                    Search
                                                </a>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <FareTypesNewComponent value={state.resultFareType}
                                                                       onClick={(data) => {
                                                                           events._updateState({resultFareType: data})
                                                                       }}/>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default ModifySearchComponent
