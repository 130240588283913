import React from "react";
import Slider from "react-slick";
import RegisterBanner from "../components/pageComponents/registerBanner";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";

const RefundPolicy = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <div className={'policy-section'}>
                <section className="about-banner">

                    <img src="../images/about-banner.jpg" className="w-100"/>
                </section>

                <section className="note-banner pt-lg-4 pt-3">
                    <div className="container">
                        <h3 className="m-0">At X Travel World, we strive to provide excellent service and ensure that
                            our
                            B2B clients have a seamless experience when booking flights and hotels. However, we
                            understand
                            that sometimes cancellations and refunds may be necessary. Below are the details of our
                            refund
                            policy:

                        </h3>
                    </div>
                </section>


                <section className="our-values">
                    <div className="container">


                        <div className="row row-gap-3 mt-5">
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li>
                                            Our goal is to ensure that you (<strong
                                            className="fw-bold text-dark">“you"</strong> or <strong
                                            className="fw-bold text-dark">“your”</strong>) have a clear understanding of
                                            the
                                            cancellation and refund terms established by Uniz Portal Private Limited
                                            (<strong className="fw-bold text-dark">"Company," "we,"
                                            "our,"</strong> or <strong className="fw-bold text-dark">"us"</strong>) when
                                            accessing and using our Website,
                                            <a href="https://www.xtravelworld.com" target="_blank"
                                               rel="noopener noreferrer"> https://www.xtravelworld.com</a> (<strong
                                            className="fw-bold text-dark">“Platform”</strong> or <strong
                                            className="fw-bold text-dark">“X Travel World”</strong>) and availing of our
                                            Services. This Cancellation and Refund Policy (<strong
                                            className="fw-bold text-dark">“Policy”</strong>) is designed to provide
                                            clarity
                                            on the cancellation and refund process for bookings made through the
                                            Platform.
                                            As X Travel World operates as a facilitator between Partners and third-party
                                            service providers, all cancellations and refunds are subject to the
                                            respective
                                            provider’s policies. This Policy outlines the general terms governing
                                            cancellations and refunds to ensure smooth transactions while minimizing
                                            disruptions.
                                        </li>
                                        <li className="mt-2">
                                            Please note that this Policy applies exclusively to the Services offered on
                                            our
                                            Platform and should be read in conjunction with the terms and conditions
                                            outlined in the Terms of Service governing our Platform.
                                        </li>
                                        <li className="mt-2">
                                            This Policy answers your common questions about cancellations and refunds to
                                            help you make informed decisions.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">CANCELLATIONS:
                                    </h5>
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark">1. Can flight bookings be canceled
                                            through
                                            the Platform? </strong><br/>
                                            Yes, flight bookings can be canceled through the Platform; however, all
                                            cancellations are subject to the respective airline’s policies. Partners
                                            must
                                            initiate cancellation requests through the Platform, and any applicable
                                            cancellation fees or conditions imposed by the airline will apply. The
                                            Platform
                                            does not have authority over airline cancellation approvals or refunds.
                                        </li>

                                        <li><strong className="fw-bold text-dark">2. How can a Partner initiate a
                                            cancellation request?</strong><br/>
                                            Partners can initiate a cancellation request directly through their
                                            Dashboards
                                            by following the steps provided in the cancellation section. Alternatively,
                                            cancellations may need to be processed directly with the airline, depending
                                            on
                                            the airline's policy. It is the Partner’s responsibility to verify and
                                            comply
                                            with the applicable cancellation procedures provided by the Platform as well
                                            as
                                            the airlines.
                                        </li>

                                        <li><strong className="fw-bold text-dark">3. What are the cancellation
                                            charges?</strong><br/>
                                            Cancellation charges vary based on the airline’s fare rules, ticket type,
                                            and
                                            timing of the cancellation request. Airlines may impose penalties, and
                                            certain
                                            fares may be non-refundable. Partners should review the fare conditions
                                            before
                                            booking. The Platform does not impose additional cancellation charges beyond
                                            those set by the airline.
                                        </li>

                                        <li><strong className="fw-bold text-dark">4. Can a booking be modified instead
                                            of
                                            canceled?</strong><br/>
                                            Yes, modifications such as date changes, name corrections, or route
                                            alterations
                                            may be possible, subject to airline policies. Some airlines allow minor name
                                            corrections, while others may require ticket reissuance with additional
                                            charges.
                                            Any modification may incur fare differences, airline change fees, or
                                            administrative charges. Partners should review the applicable airline policy
                                            before requesting modifications, as the Platform does not control
                                            modification
                                            approvals.
                                        </li>

                                        <li><strong className="fw-bold text-dark">5. What happens if a flight is
                                            canceled or
                                            rescheduled by the airline?</strong><br/>
                                            If an airline cancels or reschedules a flight, the refund, rescheduling, or
                                            compensation options will be subject to the airline’s policies. Some
                                            airlines
                                            may offer a full refund, travel credits, or an alternative flight, while
                                            others
                                            may impose restrictions.
                                            The Platform will assist Partners by providing timely notifications about
                                            flight
                                            changes, facilitating refund or rebooking requests through the Dashboard,
                                            and
                                            displaying available alternatives where applicable. However, the Platform
                                            does
                                            not influence airline decisions, and final approval for refunds or
                                            rescheduling
                                            rests solely with the airline. In certain cases, Partners may need to
                                            coordinate
                                            directly with the airline for resolution.

                                        </li>

                                        <li><strong className="fw-bold text-dark">6. What happens in case of force
                                            majeure
                                            events affecting bookings?</strong><br/>
                                            In cases of force majeure events such as natural disasters,
                                            government-imposed
                                            travel restrictions, airline strikes, or other unforeseen circumstances
                                            beyond
                                            control, cancellations and refunds are subject to the airline’s policies.
                                            Some
                                            airlines may offer refunds, rescheduling options, or travel credits, while
                                            others may impose restrictions. Partners should review the airline’s
                                            guidelines
                                            and contact the airline directly for applicable resolutions.
                                            While the Platform does not influence or determine airline policies in such
                                            cases, it provides assistance to Partners by:

                                            <br/>
                                            <ul className='ps-4 some-space'>
                                                <li><strong className="fw-bold text-dark">1. Alerts &
                                                    Notifications: </strong> Sending real-time alerts regarding
                                                    disruptions,
                                                    cancellations, or airline policy updates.
                                                </li>
                                                <li><strong className="fw-bold text-dark">2. Support Assistance (contact
                                                    customer support at ________________) </strong> Helping Partners
                                                    connect
                                                    with airlines or providing relevant contact details. For this
                                                    purpose,
                                                    please contact our customer support at ________________.
                                                </li>
                                                <li><strong className="fw-bold text-dark">3. Alternative
                                                    Options </strong> Displaying available rebooking options on the
                                                    Platform, subject to airline availability.
                                                </li>
                                                <li><strong className="fw-bold text-dark">4. Policy
                                                    Guidance </strong> Offering information on airline-specific policies
                                                    to
                                                    help Partners make informed decisions.
                                                </li>
                                            </ul>
                                            Partners are encouraged to act promptly in case of disruptions and use the
                                            Platform’s available tools and support channels for assistance.
                                        </li>
                                        <h6 className="fw-bold text-dark mt-2">REFUNDS:
                                        </h6>

                                        <li><strong className="fw-bold text-dark">7. Are all flight bookings eligible
                                            for
                                            refunds?</strong><br/>
                                            Not all flight bookings are eligible for refunds. Refund eligibility depends
                                            on
                                            the airline’s fare rules, ticket type, and applicable terms. Some tickets
                                            may be
                                            fully refundable, partially refundable, or completely non-refundable.
                                            Airlines
                                            may impose penalties or conditions for processing refunds.
                                            <br/>
                                            Certain bookings, such as promotional fares, student or senior citizen
                                            fares,
                                            last-minute deals, or restricted tickets,may have strict non-refundable
                                            conditions set by the airline. The Platform follows the airline’s refund
                                            policy
                                            and does not control refund eligibility for these fares.
                                            <br/>
                                            Additionally, any incidental discounts or promotional price reductions
                                            offered
                                            by the Platform are non-refundable in case of cancellation. Partners should
                                            carefully review fare conditions before confirming a booking.
                                        </li>

                                        <li><strong className="fw-bold text-dark">8. How are refunds processed for
                                            canceled
                                            bookings?</strong><br/>
                                            Refunds, if applicable, are processed in accordance with the airline’s
                                            refund
                                            policies. Once the airline approves a refund, it will be credited back to
                                            the
                                            original payment method used for booking. The processing time varies and may
                                            take several days or weeks, depending on the airline and payment provider.
                                            The
                                            Platform does not control the refund timeline.
                                        </li>

                                        <li><strong className="fw-bold text-dark">9. What is the role of the Platform in
                                            the
                                            refund process?</strong><br/>
                                            The Platform serves as a facilitator for refund requests but does not issue
                                            refunds directly. Partners can initiate refund requests through the
                                            Dashboard by
                                            selecting the relevant booking and following the cancellation process. Once
                                            submitted, the request is forwarded to the respective airline for approval.

                                            <ul className='ps-4 some-space'>
                                                <li><strong className="fw-bold text-dark">Tracking Refund
                                                    Status:</strong> Partners can monitor refund progress through the
                                                    Dashboard.
                                                </li>
                                                <li><strong className="fw-bold text-dark">Notifications &
                                                    Updates: </strong> The Platform provides real-time alerts when an
                                                    airline processes, approves, or rejects a refund.
                                                </li>
                                                <li><strong className="fw-bold text-dark">Support
                                                    Assistance: </strong> If
                                                    there are delays, the Platform can guide Partners on how to follow
                                                    up
                                                    with the airline or payment provider. For this purpose, please
                                                    contact
                                                    our customer support at ________________.
                                                </li>
                                                <li><strong className="fw-bold text-dark">Exploring
                                                    Alternatives: </strong> The Platform may display available
                                                    modification
                                                    options to help Partners make informed decisions.
                                                </li>
                                                <li><strong className="fw-bold text-dark">Cancellation Charges & Service
                                                    Fees: </strong> The Platform does not impose additional cancellation
                                                    charges beyond those set by the airline. However, Service Fees paid
                                                    at
                                                    the time of booking are generally non-refundable, as they cover
                                                    administrative and operational costs. Any exceptions will be
                                                    explicitly
                                                    mentioned at the time of booking.
                                                </li>
                                            </ul>
                                            If an airline denies a refund request, Partners must resolve the matter
                                            directly
                                            with the airline. While the Platform facilitates communication, it does not
                                            intervene in refund approvals.

                                        </li>

                                        <li><strong className="fw-bold text-dark">10. How long does it take to receive a
                                            refund, and what if it is delayed or not received?
                                        </strong><br/>
                                            Refund processing times vary depending on the airline and payment provider.
                                            Once
                                            an airline approves a refund, it may take anywhere from a few days to
                                            several
                                            weeks to reflect in the Partner’s bank account. The Platform does not
                                            process or
                                            hold refunds but facilitates tracking and provides updates and notifications
                                            where applicable. If a refund is delayed or not received, Partners should
                                            first
                                            check the refund status on the Platform. If further follow-up is required,
                                            Partners may need to contact the airline or payment provider directly. The
                                            Platform can assist by providing relevant details to help Partners escalate
                                            the
                                            issue where necessary.
                                        </li>

                                        <li><strong className="fw-bold text-dark">11. Can Partners dispute a
                                            cancellation or
                                            refund decision?
                                        </strong><br/>
                                            Yes, if a Partner believes a cancellation or refund decision is incorrect,
                                            they
                                            may raise a dispute through the Platform. The Platform provides a dispute
                                            resolution mechanism where Partners can submit relevant details and
                                            supporting
                                            documentation for review. While the final decision rests with the airline,
                                            the
                                            Platform assists by facilitating communication with the airline, tracking
                                            dispute status, and providing necessary updates. If the airline upholds its
                                            decision, the Partner may need to engage directly with the airline for
                                            further
                                            resolution.
                                        </li>

                                        <li>
                                            <strong className="fw-bold text-dark">
                                                12. What responsibilities do Partners have towards Customers regarding
                                                cancellations and refunds?
                                            </strong>
                                            <br/>
                                            Partners must:
                                            <ul className="ps-4 some-space">
                                                <li>
                                                    <strong className="fw-bold text-dark">1. Clearly communicate
                                                        policies: </strong>
                                                    Ensure Customers are informed about cancellation and refund
                                                    policies,
                                                    including charges, eligibility, and processing timelines before
                                                    booking.
                                                </li>
                                                <li>
                                                    <strong className="fw-bold text-dark">2. Handle cancellations
                                                        promptly: </strong>
                                                    Process cancellation requests efficiently and keep Customers updated
                                                    on
                                                    the status.
                                                </li>
                                                <li>
                                                    <strong className="fw-bold text-dark">3. Ensure refunds follow
                                                        airline
                                                        policies: </strong>
                                                    Refund claims must be processed in strict accordance with the
                                                    airline or
                                                    service provider’s policies.
                                                </li>
                                                <li>
                                                    <strong className="fw-bold text-dark">4. Provide accurate
                                                        information: </strong>
                                                    Provide accurate fare and cancellation details.
                                                </li>
                                                <li>
                                                    <strong className="fw-bold text-dark">5. Address
                                                        discrepancies: </strong>
                                                    Any incorrect information provided to Customers regarding
                                                    cancellations
                                                    or refunds is the Partner’s responsibility, and they must resolve
                                                    any
                                                    disputes that arise.
                                                </li>
                                            </ul>

                                            The Platform acts as a facilitator and is not responsible for or answerable
                                            to
                                            Customers regarding cancellations, refunds, or disputes. Partners must
                                            handle
                                            all Customer interactions directly.

                                        </li>

                                        <li><strong className="fw-bold text-dark">Contact Support:</strong><br/>
                                            For any questions or assistance regarding cancellations or refunds, please
                                            contact our <strong className="fw-bold text-dark">customer
                                                support</strong> at:
                                            <br/>
                                            Email: __________________
                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>

            </div>

            <DownloadApplications/>
            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default RefundPolicy;
