import {Icon, Modal} from "../../../components/Elements";
import _ from "lodash";
import {passengerTypeObject} from "../../../components/utils/appUtils";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UpdateMarginCode from "./updateMarginModal";
import ApplyCouponModal from "./ApplyCouponModal"
import {applyCouponFxn} from "../../ticketList/action";

const fixed2Digit = (value) => {
    return parseFloat(value).toFixed(2);
};

const CollapsibleSection = ({id, title, children, isOpen = false}) => {
    const [isExpanded, setIsExpanded] = React.useState(isOpen);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="accordion-item border-1 mb-1">
            <h2 className="accordion-header d-flex align-items-center" id={`heading-${id}`}>
                <button
                    className={`accordion-button d-flex justify-content-between align-items-center customCollapse ${isExpanded ? 'expanded' : ''}`}
                    onClick={toggleExpand}
                    aria-expanded={isExpanded}
                    aria-controls={`collapse-${id}`}
                >
                    {title}
                    <span
                        style={{
                            transform: isExpanded ? "rotate(90deg)" : "rotate(0)",
                            transition: "transform 0.3s",
                        }}
                    >

                    </span>
                </button>
            </h2>
            <div
                id={`collapse-${id}`}
                className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
                aria-labelledby={`heading-${id}`}
            >
                <div className="accordion-body p-1">{children}</div>
            </div>
        </div>
    );
};


const SummaryRow = (props) => {
    let {
        title,
        published,
        offered,
        showIcon = true,
        negative = false,
        showEditIcon = false,
        setShowMarginModal
    } = props;
    return (
        <>
            <div className="summary-grid" style={{color: negative ? 'red' : 'black'}}>
                <div>{title}{negative && ' (-)'}</div>
                <div>
                    {published ?
                        <>
                            {negative && '-'} {showIcon ? <Icon name={'rupee'}/> : ""}
                            {published}{showEditIcon &&
                        <span onClick={() => {
                            setShowMarginModal(true)
                        }}><Icon name={'edit'}/></span>}
                        </> : 0}

                </div>
                {/*   {offered !== undefined ? <div>
                    {showIcon ? <Icon name={'rupee'}/> : ""}
                    {offered}
                </div> : null}*/}
            </div>
        </>
    )
}
const ReturnFlightSummary = (props) => {
    let {inboundData, outboundData, passengerState} = props;

    let {FareBreakdown: fareInbound} = inboundData;
    let {FareBreakdown: fareOutbound} = outboundData;
    // let {adultPassengers, childPassengers} = passengerState;
    let taxEvents = {
        getSingleTax: (data, taxName) => {
            let findD = _.find(data, (item) => {
                return item.key == taxName;
            });
            return findD ? findD.value : 0;
        },
        getOtherTax: (data) => {
            let totalTax = 0;
            _.each(data, (item) => {
                if (!['YQTax', 'YR'].includes(item.key)) {
                    totalTax += item.value;
                }
            });
            return totalTax;
        },
    };

    const calculateTotals = (fareBreakdown) => {
        return fareBreakdown.reduce(
            (acc, item) => {
                const baseFare = item.BaseFare || 0;
                const tax = item.Tax || 0;
                const otherTax = taxEvents.getOtherTax(item.TaxBreakUp);
                const yqTax = taxEvents.getSingleTax(item.TaxBreakUp, 'YQTax');
                const yrTax = taxEvents.getSingleTax(item.TaxBreakUp, 'YR');

                acc.totalBaseFare += baseFare;
                acc.totalTax += tax;
                acc.totalOtherTax += otherTax;
                acc.totalYQTax += yqTax;
                acc.totalYRTax += yrTax;
                acc.totalAmount += baseFare + tax;

                return acc;
            },
            {
                totalBaseFare: 0,
                totalTax: 0,
                totalOtherTax: 0,
                totalYQTax: 0,
                totalYRTax: 0,
                totalAmount: 0,
            }
        );
    };

    const inboundTotals = calculateTotals(fareInbound || []);
    const outboundTotals = calculateTotals(fareOutbound || []);


    const combinedTotals = {
        totalBaseFare: inboundTotals.totalBaseFare + outboundTotals.totalBaseFare,
        totalTax: inboundTotals.totalTax + outboundTotals.totalTax,
        totalOtherTax: inboundTotals.totalOtherTax + outboundTotals.totalOtherTax,
        totalYQTax: inboundTotals.totalYQTax + outboundTotals.totalYQTax,
        totalYRTax: inboundTotals.totalYRTax + outboundTotals.totalYRTax,
        totalAmount: inboundTotals.totalAmount + outboundTotals.totalAmount,
    };

    return (
        <>
            <div className="detail-box">
                <h4>Fare Summary</h4>
                <SummaryRow published={'Published'} offered={'Offered'} showIcon={false}/>

                {fareInbound && fareInbound.length
                    ? fareInbound.map((item, index) => (
                        <SummaryRow
                            key={`inbound-${index}`}
                            title={<b>{passengerTypeObject[item.PassengerType]}</b>}
                            published={fixed2Digit(item.BaseFare)}
                            offered={fixed2Digit(item.BaseFare / 2)}
                            showIcon={false}
                        />
                    ))
                    : null}

                {fareOutbound && fareOutbound.length
                    ? fareOutbound.map((item, index) => (
                        <SummaryRow
                            key={`outbound-${index}`}
                            title={<b>{passengerTypeObject[item.PassengerType]}</b>}
                            published={fixed2Digit(item.BaseFare)}
                            offered={fixed2Digit(item.BaseFare / 2)}
                            showIcon={false}
                        />
                    ))
                    : null}


                <SummaryRow
                    title={'QT Tax'}
                    published={fixed2Digit(combinedTotals.totalOtherTax)}
                    offered={fixed2Digit(combinedTotals.totalOtherTax / 2)}
                />
                <SummaryRow
                    title={'YQ Tax'}
                    published={fixed2Digit(combinedTotals.totalYQTax)}
                    offered={fixed2Digit(combinedTotals.totalYQTax / 2)}
                />
                <SummaryRow
                    title={'YR Tax'}
                    published={fixed2Digit(combinedTotals.totalYRTax)}
                    offered={fixed2Digit(combinedTotals.totalYRTax / 2)}
                />
                <hr/>
                <SummaryRow
                    title={'Total Fare'}
                    published={<b>{fixed2Digit(combinedTotals.totalAmount)}</b>}
                    offered={<b>{fixed2Digit(combinedTotals.totalAmount / 2)}</b>}
                />
                <hr/>
                <ul>
                    <li>
                        <button className="btn btn-primary w-100" type="button">Pay <Icon
                            name={'rupee'}/> {fixed2Digit(combinedTotals.totalAmount)}</button>
                    </li>
                </ul>
            </div>
        </>
    );
};
const FlightSummary = (props) => {
    const {flightObj} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj
    }))

    const [showSelectedFlightModal, setShowSelectedFlightModal] = useState({
        visible: false,
    });
    const [showCouponModal, setShowCouponModal] = useState({visible: false});
    const [couponDiscountAmount, setCouponDiscountAmount] = useState(0);
    const [coupon, setCoupon] = useState({})
    let dispatch = useDispatch()
    let {
        flightData,
        passengerState = {},
        callBack,
        convienceFeesCallback,
        convinceAmount,
        xtravelMarginDataCallback
    } = props;
    const {fareQuoteObj} = useSelector((state) => ({
        fareQuoteObj: state.flightsReducer.fareQuoteObj,
    }))
    const {FareBreakdown: fareBreakdown, Fare} =
        fareQuoteObj && Object.keys(fareQuoteObj).length > 0
            ? fareQuoteObj
            : flightData || {};

    let {adultPassengers, childPassengers, infantPassengers} = passengerState;
    // console.log(Fare?.PublishedFare, 'Fare?.PublishedFare')
    const publishedFare = Fare?.PublishedFare || fareBreakdown.reduce((total, item) => total + item.BaseFare + item.Tax, 0);
    const supplierReissueCharges = Fare?.SupplierReissueCharges !== undefined ? Fare.SupplierReissueCharges : null;
    const totalTax = ((Fare?.OtherCharges || 0) / 1.18).toFixed(2);
    const totalGST = totalTax * 0.18;

    const ootTaxTotal = fareBreakdown.reduce((sum, item) => sum + (Number(item.ootTaxTotal) || 0), 0);
    const adjustedBaseFare = fareBreakdown.reduce((total, item) => total + item.BaseFare, 0);
    const calculateAncillaryPrices = (passengers = []) => {
        return passengers?.reduce(
            (totals, passenger) => {
                if (passenger?.Baggage) {
                    if (Array.isArray(passenger.Baggage)) {
                        passenger.Baggage.forEach(baggage => {
                            totals.baggagePrice += baggage?.Price || 0;
                        });
                    } else {
                        totals.baggagePrice += passenger?.Baggage?.Price || 0;
                    }
                }
                if (passenger?.MealDynamic) {
                    passenger.MealDynamic?.forEach(meal => {
                        totals.mealPrice += meal?.Price || 0;
                    });
                }
                if (passenger?.SeatDynamic) {
                    passenger.SeatDynamic?.forEach(seat => {
                        totals.seatPrice += seat?.Price || 0;
                    });
                }
                if (passenger?.SpecialServices) {
                    passenger.SpecialServices?.forEach(service => {
                        totals.specialServicesPrice += service?.Price || 0;
                    });
                }
                if (passenger?.BagDynamic) {
                    totals.specialServicesPrice += passenger?.BagDynamic?.Price || 0;
                }
                return totals;
            },
            {baggagePrice: 0, mealPrice: 0, seatPrice: 0, specialServicesPrice: 0}
        );
    };


    const adultAncillaries = calculateAncillaryPrices(adultPassengers);

    const childAncillaries = calculateAncillaryPrices(childPassengers);


    const baggagePrice = adultAncillaries?.baggagePrice + childAncillaries?.baggagePrice;
    const mealPrice = adultAncillaries?.mealPrice + childAncillaries?.mealPrice;
    const seatPrice = adultAncillaries?.seatPrice + childAncillaries?.seatPrice;
    const specialServicesPrice = adultAncillaries?.specialServicesPrice + childAncillaries?.specialServicesPrice;
    const validPassengers = fareBreakdown.filter(item => item.PassengerType !== 3);
    const totalValidPassengerCount = validPassengers.reduce((sum, item) => sum + item.PassengerCount, 0);

    const perPassengerFee = totalValidPassengerCount > 0 ? (Fare?.OtherCharges || 0) / totalValidPassengerCount : 0;
    const perPassengerFeeExGST = (perPassengerFee / 1.18).toFixed(2);
    const totalTaxes = fareBreakdown.reduce((total, item) => total + item.Tax, 0);
    let AgentXTravelMarginAmount = convinceAmount || 0


    //discount
    // let commissionEarnedNew = ((parseFloat(Fare?.CommissionEarned ?? 0)) +
    //     (parseFloat(Fare?.IncentiveEarned ?? 0)) +
    //     (parseFloat(Fare?.PLBEarned ?? 0))).toFixed(2)

    let commissionEarnedNew = 0 //just in case for coupon code flow needed

    //after discount
    let amountBeforeDiscount = publishedFare + mealPrice + seatPrice + baggagePrice + specialServicesPrice + (supplierReissueCharges || 0) - (totalGST || 0);

    //after discount
    let amountAfterDiscount = amountBeforeDiscount - (commissionEarnedNew || 0);

    //Gst convenience fees
    // let gstOnConvenienceFees = (AgentXTravelMarginAmount * 0.18) || 0;

    let xTravelExchangeMargin =
        fareQuoteObj?.agentXTravelMargin != undefined ?
            fareQuoteObj?.agentXTravelMargin :
            fareQuoteObj?.xTravelExchangeMargin

    //service fees
    let xTravelMargin = (amountAfterDiscount * (xTravelExchangeMargin / 100)) || 0;

    //gst on service fees
    let gstOnXTravelMargin = (xTravelMargin * 0.18) || 0;

    //admin and x travel margin combined
    let TotalMarginWithAgent = (xTravelMargin || 0) + (AgentXTravelMarginAmount || 0)
    let TotalMarginGst = (gstOnXTravelMargin || 0)


    const totalAmountWithAncillaries = Math.round(
        amountAfterDiscount +
        (xTravelMargin || 0) +
        (gstOnXTravelMargin || 0)
    );

    const netAmount = Math.round(
        amountAfterDiscount +
        (xTravelMargin || 0) +
        (AgentXTravelMarginAmount || 0) +
        (gstOnXTravelMargin || 0) -
        couponDiscountAmount
    );

    useEffect(() => {
        callBack(totalAmountWithAncillaries)
        xtravelMarginDataCallback({
            commissionEarnedNew,
            amountBeforeDiscount,
            amountAfterDiscount,
            // gstOnConvenienceFees,
            xTravelMargin,
            gstOnXTravelMargin,
            TotalMarginWithAgent,
            totalGST,
            netAmount,
            couponDiscountAmount,
            couponId: coupon._id
        })
    }, [
        totalAmountWithAncillaries,
        commissionEarnedNew,
        amountBeforeDiscount,
        amountAfterDiscount,
        // gstOnConvenienceFees,
        xTravelMargin,
        gstOnXTravelMargin,
        TotalMarginWithAgent,
        TotalMarginGst,
        totalGST,
        netAmount
    ])
    const events = {
        applyCoupon: async (couponCode) => {
            const obj = {
                couponCode,
                flightObj,
                baseFare: netAmount
            };

            const data = await dispatch(applyCouponFxn(obj));
            console.log(data, "dis count")
            if (data.success) {
                setCouponDiscountAmount(data?.data?.discountAmount);
                setCoupon(data?.data?.couponData)
            } else {
                // notification.error({message: data.message})
            }
        },
    };
    console.log(couponDiscountAmount, "coupn dis")

// const totalTax = fareBreakdown.map((item, index) => {
//         const tax = (Number(item.Tax) / Number(item.PassengerCount)) + Number(item.ootTaxPaxWise || 0);
//         const tFeePerPassenger = item.PassengerType !== 3 ? parseFloat(perPassengerFeeExGST) : 0;
//         return (
//             tax + tFeePerPassenger
//         );
//     })


    return (
        <div className="detail-box sticky-check-box">
            <h4>Fare Summary</h4>
            <CollapsibleSection title="Pax-Wise Fare">
                {fareBreakdown.map((item, index) => {
                    const baseFarePerPassenger = (Number(item.BaseFare) / Number(item.PassengerCount));
                    const tax = (Number(item.Tax) / Number(item.PassengerCount)) + Number(item.ootTaxPaxWise || 0);
                    const tFeePerPassenger = item.PassengerType !== 3 ? parseFloat(perPassengerFeeExGST) : 0;
                    const reissueChargePerPassenger = supplierReissueCharges
                        ? supplierReissueCharges / totalValidPassengerCount
                        : 0;

                    const totalFare = item.PassengerType !== 3
                        ? baseFarePerPassenger + tax + tFeePerPassenger + reissueChargePerPassenger
                        : baseFarePerPassenger + tax;

                    return (
                        <div key={index}>
                            <SummaryRow
                                title={<b>{passengerTypeObject[item.PassengerType]}</b>}
                                published={fixed2Digit(baseFarePerPassenger)}
                                offered={fixed2Digit(baseFarePerPassenger)}
                                showIcon={false}
                            />
                            <SummaryRow
                                title="Tax"
                                published={fixed2Digit(tax + tFeePerPassenger)}
                                offered={fixed2Digit(tax + tFeePerPassenger)}
                            />
                            {/*<SummaryRow
                                title="T.Fee & S.Charges"
                                published={fixed2Digit(tFeePerPassenger)}
                                offered={fixed2Digit(tFeePerPassenger)}
                            />*/}
                            {supplierReissueCharges !== null && supplierReissueCharges > 0 && (
                                <SummaryRow
                                    title="Supplier Reissue Charges"
                                    published={fixed2Digit(reissueChargePerPassenger)}
                                    offered={fixed2Digit(reissueChargePerPassenger)}
                                />
                            )}
                            <hr style={{margin: '7px 0'}}/>
                            <SummaryRow
                                title="Total"
                                published={fixed2Digit(totalFare)}
                                offered={fixed2Digit(totalFare)}
                                showIcon={false}
                            />
                            <hr style={{margin: '7px 0'}}/>
                        </div>
                    );
                })}
            </CollapsibleSection>


            <CollapsibleSection title="Base Fare" isOpen={true}>
                <SummaryRow
                    title={<b>Base Fare</b>}
                    published={<b>{fixed2Digit(adjustedBaseFare)}</b>}
                    offered={<b>{fixed2Digit(adjustedBaseFare)}</b>}
                    showIcon={false}
                />
                <hr style={{margin: '7px 0'}}/>
                <SummaryRow
                    title="Taxes and fees"
                    published={fixed2Digit(AgentXTravelMarginAmount + (supplierReissueCharges || 0) + (totalTaxes || 0))}
                    offered={fixed2Digit(AgentXTravelMarginAmount + (supplierReissueCharges || 0) + (totalTaxes || 0))}
                    showEditIcon={true}
                    setShowMarginModal={() => {
                        setShowSelectedFlightModal({visible: true})
                    }}
                />
                <hr style={{margin: '7px 0'}}/>
                {/*  <SummaryRow
                    title="Apply Coupon"
                    published={couponDiscountAmount > 0 ? `-${fixed2Digit(couponDiscountAmount)}` : '-'}
                    offered={couponDiscountAmount > 0 ? `-${fixed2Digit(couponDiscountAmount)}` : '-'}
                    showEditIcon={true}
                    setShowMarginModal={() => {
                        setShowCouponModal({ visible: true });
                    }}
                />*/}


            </CollapsibleSection>


            {/*<SummaryRow
                title="Base fare"
                published={fixed2Digit(AgentXTravelMarginAmount)}
                offered={fixed2Digit(AgentXTravelMarginAmount)}
                setShowMarginModal={() => {
                    setShowSelectedFlightModal({visible: true})
                }}
            />
            <hr/>*/}


            <CollapsibleSection isOpen={true} title="Additional Charges">
                <SummaryRow
                    title="Excess Baggage"
                    published={fixed2Digit(baggagePrice || 0)}
                    offered={fixed2Digit((baggagePrice || 0) / 2)}
                />

                <SummaryRow
                    title="Meal"
                    published={fixed2Digit(mealPrice || 0)}
                    offered={fixed2Digit((mealPrice || 0) / 2)}
                />

                <SummaryRow
                    title="Seat Charges"
                    published={fixed2Digit(seatPrice || 0)}
                    offered={fixed2Digit((seatPrice || 0) / 2)}
                />

                <SummaryRow
                    title="Special Services"
                    published={fixed2Digit(specialServicesPrice || 0)}
                    offered={fixed2Digit((specialServicesPrice || 0) / 2)}
                />
            </CollapsibleSection>


            <CollapsibleSection
                title={
                    <>
                        Total Payable Amount - <b><Icon name="rupee"/> {fixed2Digit(netAmount)}</b>
                    </>
                }
            >

                <SummaryRow
                    title="Total Amount"
                    published={fixed2Digit(amountBeforeDiscount)}
                    offered={fixed2Digit(amountBeforeDiscount)}
                />
                {/*<SummaryRow*/}
                {/*    title="Discount"*/}
                {/*    published={fixed2Digit(commissionEarnedNew)}*/}
                {/*    offered={fixed2Digit(commissionEarnedNew)}*/}
                {/*    negative={true}*/}
                {/*/>*/}
                <SummaryRow title="Coupon Discount"
                            published={`${fixed2Digit(couponDiscountAmount)}`}
                            showIcon={true}
                            negative={true}/>
                <SummaryRow
                    title="Service Fees & Taxes"
                    published={fixed2Digit(xTravelMargin)}
                    offered={fixed2Digit(xTravelMargin)}
                />
                <SummaryRow
                    title="Total Gst"
                    published={fixed2Digit(TotalMarginGst)}
                    offered={fixed2Digit(TotalMarginGst)}
                />
                <SummaryRow
                    title="Net Price"
                    published={fixed2Digit(totalAmountWithAncillaries)}
                    offered={fixed2Digit(totalAmountWithAncillaries)}
                />
                <hr/>
            </CollapsibleSection>

            <ul>
                <li>
                    <li className="total-amount">
                        <p><b>Total Payable Amount</b></p>
                        <span><b><Icon name="rupee"/> {fixed2Digit(totalAmountWithAncillaries)}</b></span>
                    </li>
                </li>
            </ul>

            {showSelectedFlightModal.visible && (
                <UpdateMarginCode
                    visible={showSelectedFlightModal.visible}
                    onClose={() => {
                        setShowSelectedFlightModal({visible: false})
                    }}
                    convienceFeesCallback={(value) => {
                        convienceFeesCallback(value)
                        setShowSelectedFlightModal({visible: false})
                        // fareQuoteObj.agentXTravelMargin = 0
                    }}
                    AgentXTravelMarginAmount={AgentXTravelMarginAmount}
                />
            )}
            {showCouponModal.visible && (
                <ApplyCouponModal
                    visible={showCouponModal.visible}
                    onClose={() => setShowCouponModal({visible: false})}
                    applyCouponCallback={events.applyCoupon}
                />
            )}

        </div>
    );
};


export {FlightSummary, ReturnFlightSummary}
