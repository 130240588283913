import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {airLinesFxn} from "../actions";
import {FlightDatePicker, Form, notification, Radio, Select, Tooltip} from "../../../components/Elements";
import {CabinComponent, FareTypesComponent, PassengerComponent} from "./flightSearchComponent";
import moment from "moment";
import {displayDateOnly, fareTypeList} from "../../../components/utils/appUtils";
import {swapIcon} from "../../../components/imgComp"
import {FareTypesNewComponent} from "./flightSearchComponent";
import SelectCityComponent from "./SelectCityComponent";
import SelectJourneyTypeComponent from "./SelectJourneyTypeComponent";
// import TravellersComponent from "../flightSearchComponent/travellersComponent";
import TravellersComponent from "./travellersComponent";
import {calendarFareFxn} from "../../ticketList/action";
import ChooseDateComponent from "../flightSearchComponent/ChooseDateComponent";
import DeleteIcon from "../../../assets/img/delete.png";
import AddIcon from '../../../assets/img/add.png'

const initState = {
    cabinClass: "2",
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    resultFareType: "2",
    preferredDepartureTime: moment(),
    Segments: []
}
const MultiCityModifySearch = (props) => {
    let {flightObj = {}, flightSearch} = props;
    let [journeyType, setJourneyType] = useState("0");
    const [calendarFareData, setCalendarFareData] = useState({
        departure: [],
        return: []
    });
    useEffect(() => {
        if (flightObj.journeyType) {
            setJourneyType(flightObj.journeyType)
        } else {
            setJourneyType("3")
        }
    }, [])
    console.log(flightObj, "flight --obj---")
    let dispatch = useDispatch()
    let [state, setState] = useState(initState)
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        _choosePreferredDepartureTime: (value) => {
            let obj = {preferredDepartureTime: value}
            if (obj.preferredReturnTime) {
                if (moment(obj.preferredReturnTime).isBefore(value)) {
                    obj.preferredReturnTime = value
                }
            } else {
                if (moment(state.preferredReturnTime).isBefore(value)) {
                    obj.preferredReturnTime = value
                }
            }
            events._updateState(obj)
        },
        handleSearchFlight: async () => {

            if (state.adultCount == 0 && state.childCount == 0 && state.infantCount == 0) {
                return notification.warning({message: "Please choose Passenger."})
            }
            let obj = {
                ...state, journeyType
            }
            dispatch({type: "SEARCH_FLIGHT", flightObj: obj})
            flightSearch(obj)
        },
        changeJourneyType: (value) => {
            setJourneyType(value)
        },
        _updateReturnDate: (journeyType) => {
            if (journeyType == "3") {
                events._updateState({preferredReturnTime: null})
            } else {
                if (!state.preferredReturnTime) {
                    events._updateState({preferredReturnTime: state.preferredDepartureTime})
                }
            }
        },
        _choosePreferredReturnTime: () => {
            if (!state.preferredReturnTime) {
                events._updateState({preferredReturnTime: state.preferredDepartureTime})
            }
        },

        getCalendarFare: async (date, label = 'Departure') => {
            // console.log('from callback----', date, "label", label)
            let firstDayOfMonth = moment(date).startOf('month');

            if (firstDayOfMonth < moment()) {
                firstDayOfMonth = moment(date);
            }

            let obj = {
                origin: state?.origin,
                destination: state?.destination,
                cabinClass: state?.cabinClass,
                preferredDepartureTime: firstDayOfMonth,
                journeyType: journeyType
            };

            let objReturn = {
                origin: state?.destination,
                destination: state?.origin,
                cabinClass: state?.cabinClass,
                preferredDepartureTime: firstDayOfMonth,
                journeyType: journeyType
            };

            let valData = label === 'Return' ? objReturn : obj;
            let resp = await dispatch(calendarFareFxn(valData));

            if (resp?.data?.SearchResults) {
                setCalendarFareData((prevState) => {
                    return {
                        ...prevState,
                        [label.toLowerCase()]: [
                            ...prevState[label.toLowerCase()] || [],
                            ...resp.data.SearchResults,
                        ]
                    };
                });
            }
        },
        callbackCalendarFxn: async (date, label) => {
            let nextMonth = moment(date).add(1, 'month');

            await Promise.all([
                events.getCalendarFare(date, label),
                events.getCalendarFare(nextMonth, label)
            ]);
        },
        addSegment: () => {
            if (state.Segments.length >= 5) {
                return notification.warning({message: "You can only add up to 5 segments."});
            }

            const newSegment = {
                origin: "",
                destination: "",
                originCity: "",
                destinationCity: "",
                originAirportName: "",
                destinationAirportName: "",
                preferredDepartureTime: moment()
            };

            events._updateState({
                Segments: [...state.Segments, newSegment]
            });
        },
        removeSegment: (index) => {
            const updatedSegments = [...state.Segments];
            updatedSegments.splice(index, 1);
            events._updateState({Segments: updatedSegments});
        }
    }
    useEffect(() => {
        if (flightObj.journeyType) {
            setJourneyType(flightObj.journeyType);
        } else {
            setJourneyType("3");
        }

        if (flightObj?.Segments) {
            const initializedSegments = flightObj.Segments.map(seg => ({
                ...seg,
                preferredDepartureTime: moment(seg.preferredDepartureTime),
            }));

            setState(prev => ({
                ...prev,
                ...flightObj,
                Segments: initializedSegments
            }));
        } else {
            setState(prev => ({
                ...prev,
                ...flightObj
            }));
        }
    }, []);


    const disabledDate = (current, fromDate) => {
        return moment(current).isBefore(fromDate, 'day');
    };

    useEffect(() => {
        /*  if (journeyType == "1") {
              events._updateState({preferredReturnTime: null})
          } else {
              if (!state.preferredReturnTime) {
                  events._updateState({preferredReturnTime: state.preferredDepartureTime})
              }
          }*/
    }, [journeyType])


    useEffect(() => {
        setCalendarFareData({departure: [], return: []});
        const loadDefaultCalendarFares = async () => {
            let currentDate = moment();
            let nextMonth = moment().add(1, 'month');

            await Promise.all([
                events.getCalendarFare(currentDate, 'Departure'),
                events.getCalendarFare(nextMonth, 'Departure'),
                events.getCalendarFare(currentDate, 'Return'),
                events.getCalendarFare(nextMonth, 'Return')
            ]);
        };

        loadDefaultCalendarFares();
    }, [state.origin, state.destination]);


    return (
        <div>
            <Form>
                <div className="flight-booking modify-search-box">
                    <div className="container position-relative">
                        <div className="find-flight search-flight-main-box">
                            <div className="flight-top-filters">
                                <div className="radio-box mb-3">
                                    <div className="search-filter w-100">
                                        <div className="row row-gap-3 align-items-center">
                                            <div className="col-lg-4 col-12">
                                                <div className="double-box">
                                                    <SelectJourneyTypeComponent
                                                        journeyType={journeyType}
                                                        onSelect={(value) => {
                                                            setJourneyType(value);
                                                            events._updateReturnDate(value)
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {state.Segments.map((segment, index) => (
                                                <div className="col-lg-4 col-12" key={index}>
                                                    <div style={{background: '#f9f9f9'}}>

                                                        <div className="double-box city-double">
                                                            <SelectCityComponent
                                                                label={'From'}
                                                                placeholder={"From ?"}
                                                                cityId={segment.origin}
                                                                cityName={segment.originCity}
                                                                airportName={segment.originAirportName}
                                                                onSelect={(data) => {
                                                                    const updatedSegments = [...state.Segments];
                                                                    updatedSegments[index] = {
                                                                        ...updatedSegments[index],
                                                                        origin: data.value,
                                                                        originCity: data.cityName,
                                                                        originAirportName: data.airportName
                                                                    };
                                                                    events._updateState({Segments: updatedSegments});
                                                                }}
                                                            />
                                                            <div className="divide-box position-relative">
                                                                <img src={swapIcon} alt="Shift Icon"/>
                                                            </div>
                                                            <SelectCityComponent
                                                                label={'To'}
                                                                style={{paddingLeft: 25}}
                                                                placeholder={"To ?"}
                                                                cityId={segment.destination}
                                                                cityName={segment.destinationCity}
                                                                airportName={segment.destinationAirportName}
                                                                onSelect={(data) => {
                                                                    const updatedSegments = [...state.Segments];
                                                                    updatedSegments[index] = {
                                                                        ...updatedSegments[index],
                                                                        destination: data.value,
                                                                        destinationCity: data.cityName,
                                                                        destinationAirportName: data.airportName
                                                                    };
                                                                    events._updateState({Segments: updatedSegments});
                                                                }}
                                                            />
                                                            <div className="divide-box position-relative">
                                                                {/*<img src={swapIcon} alt="Shift Icon"/>*/}
                                                            </div>
                                                            <ChooseDateComponent
                                                                placeholder={'Departure'}
                                                                journeyType={journeyType}
                                                                value={segment.preferredDepartureTime}
                                                                startFrom={moment()}
                                                                label={'Departure'}
                                                                type={'modify'}
                                                                state={state}
                                                                disabledDate={(current) => moment(current).isBefore(moment(), 'day')}
                                                                changeJourneyType={(value) => {
                                                                    setJourneyType(value);
                                                                    events._choosePreferredReturnTime();
                                                                }}
                                                                callback={(date) => events.callbackCalendarFxn(date, 'Departure')}
                                                                searchResults={calendarFareData.departure}
                                                                onChange={(value) => {
                                                                    const updatedSegments = [...state.Segments];
                                                                    updatedSegments[index] = {
                                                                        ...updatedSegments[index],
                                                                        preferredDepartureTime: value
                                                                    };
                                                                    events._updateState({Segments: updatedSegments});
                                                                }}
                                                            />
                                                            {index > 1 && (
                                                                <span
                                                                    className={'ps-2'}
                                                                    style={{marginTop: -10}}
                                                                    onClick={() => events.removeSegment(index)}
                                                                >
                                                                <img src={DeleteIcon} alt={'img'} width={23}/>
                                                            </span>
                                                            )}

                                                            {index === state.Segments.length - 1 && state.Segments.length < 5 && (
                                                                <span
                                                                    className={'ps-1'}
                                                                    style={{marginTop: -10}}
                                                                    onClick={events.addSegment}
                                                                >
                                                                    <img src={AddIcon} alt={'img'} width={25}/>
                                                                </span>
                                                            )}
                                                        </div>


                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                        <div className={'row row-gap-3 align-items-center mt-3'}>
                                            <div className="col-lg-2 col-12">
                                                <div className="double-box">
                                                    <TravellersComponent
                                                        state={state}
                                                        onChange={(data) => {
                                                            events._updateState(data)
                                                        }}
                                                        resultFareType={state.resultFareType}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <FareTypesNewComponent
                                                    optionStyle={{height: 60}}
                                                    value={state.resultFareType}
                                                    onClick={(data) => {
                                                        events._updateState({resultFareType: data})
                                                    }}/>
                                            </div>
                                            <div className="col-lg-2 col-12">
                                                <a className={'btn btn-primary search-btn'}
                                                   onClick={() => {
                                                       events.handleSearchFlight()
                                                   }}>
                                                    Search
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default MultiCityModifySearch
