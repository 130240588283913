import React from "react";
import Slider from "react-slick";
import RegisterBanner from "../components/pageComponents/registerBanner";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";

const TermsOfService = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <div className={'policy-section'}>
                <section className="about-banner">

                    <img src="../images/about-banner.jpg" className="w-100"/>
                </section>

                <section className="note-banner pt-lg-4 pt-3">
                    <div className="container">
                        <h3 className="m-0">PLEASE REVIEW THESE TERMS CAREFULLY BEFORE ACCESSING THIS PLATFORM. IT
                            OUTLINES
                            THE LEGALLY BINDING TERMS AND CONDITIONS GOVERNING YOUR USE OF THIS PLATFORM AND INCLUDES
                            CRUCIAL INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS.
                        </h3>
                    </div>
                </section>


                <section className="our-values">
                    <div className="container">


                        <div className="row row-gap-3 mt-5">
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li>
                                            These Terms (the “<strong className="fw-bold text-dark"> Terms</strong>”)
                                            constitute a legally binding agreement between Uniz Portal Private Limited
                                            ("<strong className="fw-bold text-dark">Company</strong>," "<strong
                                            className="fw-bold text-dark"> we</strong>," "<strong
                                            className="fw-bold text-dark"> our</strong>," or "<strong
                                            className="fw-bold text-dark"> us</strong>") and you (“<strong
                                            className="fw-bold text-dark">you</strong>”, or “<strong
                                            className="fw-bold text-dark">your</strong>”). These Terms will be effective
                                            upon your acceptance of the same (directly or indirectly in electronic form
                                            or by means of an electronic record) and will govern the relationship
                                            between us and you for the use of our Website <a
                                            href="https://www.xtravelworld.com"
                                            target="_blank">https://www.xtravelworld.com</a> (“<strong
                                            className="fw-bold text-dark">Website</strong>”), our mobile application
                                            (“<strong className="fw-bold text-dark">App</strong>”), our Services and any
                                            of our other websites, mobile or digital applications, or any other services
                                            we offer from time to time by or in connection therewith (together referred
                                            to as the (“<strong className="fw-bold text-dark">Platform</strong>” or
                                            “<strong className="fw-bold text-dark">X Travel World</strong>”). The
                                            Platform is owned and operated by Uniz Portal Private Limited having its
                                            registered office at ______________________.
                                        </li>
                                        <li className="mt-2">
                                            The Platform is a B2B travel marketplace designed to connect travel agents,
                                            tour operators, and other travel service providers (“<strong
                                            className="fw-bold text-dark">Partners</strong>”) with third-party suppliers
                                            offering flights, hotels, transportation, and other travel-related services.
                                            Partners can browse listings, compare options, and manage bookings for their
                                            end clients (“<strong className="fw-bold text-dark">Customers</strong>”)
                                            through a centralized Dashboard. The Platform provides tools for tracking
                                            reservations, handling communications with service providers, and managing
                                            team access and permissions. While facilitating transactions, the Platform
                                            does not directly offer travel services, endorse suppliers, or guarantee
                                            service availability. All bookings are subject to the respective providers’
                                            terms and conditions (collectively the "<strong
                                            className="fw-bold text-dark">Services</strong>").
                                        </li>
                                        <li className="mt-2">
                                            For the purpose of these Terms, wherever the context so requires "<strong
                                            className="fw-bold text-dark">you</strong>," or "<strong
                                            className="fw-bold text-dark">your</strong>" refers to any natural or legal
                                            person, who has registered on our Platform as a Partner and has availed
                                            themselves of our Services, including their authorised users on the
                                            Platform. For avoidance of doubt, the term “User” shall collectively refer
                                            to Partners, their authorised users, Customers and any other individual or
                                            entity who accesses or interacts with our Platform. The applicability of
                                            these Terms extends to Users regardless of the device type used for
                                            accessing our Platform, whether it be a laptop/desktop or a mobile/tablet
                                            device
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <ul className="ps-3 text-secondary listing some-space">
                                        <li><strong className="fw-bold text-dark"> Acceptance of Terms:
                                        </strong><br/>
                                            This document is an electronic record in accordance with the Information
                                            Technology Act, 2000 and rules thereunder, as applicable, including the
                                            amended provisions pertaining to electronic records in various statutes as
                                            amended by the Information Technology Act, 2000. This electronic record is
                                            generated by a computer system and does not require any physical or digital
                                            signatures. It is published in accordance with the provisions of Rule 3 (1)
                                            of the Information Technology (Intermediaries guidelines) Rules, 2011 that
                                            require publishing the rules and regulations, Privacy Policy, and Terms of
                                            Service for access or usage of our Platform.
                                            <br/>Any User accessing the Platform from jurisdictions not explicitly
                                            mentioned here shall be deemed to have electronically accepted these Terms
                                            in accordance with the electronic transaction laws or equivalent statutes
                                            applicable in their respective jurisdiction. In the absence of such laws,
                                            the acceptance shall be presumed valid by universally accepted principles of
                                            digital contracting.
                                            <br/>Our role under these Terms is limited to administration and managing
                                            the Platform, including any Services made available to you on the Platform.
                                            You must comply with applicable laws, regulations, and policies when using
                                            our Platform and/or Services. It is your responsibility to ensure that your
                                            use of the Platform does not violate any laws or regulations in your
                                            jurisdiction.
                                            <br/>By impliedly or expressly accepting these Terms, you also accept and
                                            agree to be bound by our Privacy Policy and Cancellation and Returns Policy
                                            as amended from time to time. We encourage you to read the Privacy Policy
                                            and Cancellation and Returns Policy in conjunction with these Terms to
                                            better understand how you can update, manage, export, and delete your
                                            information. If you do not agree with anything provided herein, please do
                                            not use or access our Platform and/or Services.
                                            <br/>We reserve the unilateral right to change the particulars contained in
                                            these Terms from time to time, without notice to you and in our sole
                                            discretion. If we make any such revision in these Terms, we will update the
                                            effective date above and the revised Terms shall be effective from such
                                            date. You are required to frequently check these Terms and its effective
                                            date to understand the terms and conditions that apply to your use of our
                                            Services. Your continued use of the Platform and/or Services following such
                                            modification constitutes your acceptance of the modified Terms of Service,
                                            whether or not you have read them.
                                            <br/>Further, we may change, suspend, and/or discontinue the Services at any
                                            time, including the availability of any feature, database, and content on
                                            the Platform. We may also restrict Users’ access to parts and/or all of the
                                            Platform without notice in the event of technical disruptions or other
                                            similar events and we shall not be liable to the Users in such cases.
                                        </li>

                                        <li><strong className="fw-bold text-dark">1. WHO IS ELIGIBLE TO USE OUR PLATFORM
                                            AND SERVICES?
                                        </strong><br/>
                                        </li>
                                        <ul className="ps-3 text-secondary listing some-space">
                                            <li><strong className="fw-bold text-dark">1. Eligibility:
                                            </strong><br/>
                                                To access and use the Services provided by the Platform, you must
                                                represent and warrant that:
                                            </li>
                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1.
                                                </strong>
                                                    You are either a duly registered business entity or an individual
                                                    acting in a professional capacity, authorized to perform delegated
                                                    tasks, including but not limited to booking flights, managing
                                                    Customers, handling travel-related Customer support, managing
                                                    Dashboards and profiles, processing transactions, etc.
                                                </li>
                                                <li><strong className="fw-bold text-dark">2.
                                                </strong>
                                                    You hold valid and appropriate licenses, permits, and/or
                                                    certifications required by the relevant governmental and regulatory
                                                    authorities in your jurisdiction to onboard on the Platform. These
                                                    may include but are not limited to IATA accreditation, Seller of
                                                    Travel licenses, ATOL certification, TIDS Registration, IRCTC
                                                    authorization, or any other regulatory approvals required under
                                                    local, national, or international law.
                                                </li>
                                                <li><strong className="fw-bold text-dark">3.
                                                </strong>
                                                    You have the authority to enter into legally binding agreements and
                                                    comply with the terms outlined in these Terms.
                                                </li>
                                                <li><strong className="fw-bold text-dark">4.
                                                </strong>
                                                    You are at least 18 years old or have the legal age of majority in
                                                    your jurisdiction if accessed by individuals acting on behalf of the
                                                    Partners.
                                                </li>
                                                <li><strong className="fw-bold text-dark">5.
                                                </strong> Your use of our Platform and/or Services will comply with all
                                                    applicable laws and regulations (including, without limitation, all
                                                    applicable laws concerning consumer protection laws, financial
                                                    regulations, intellectual property rights (IPR), data protection,
                                                    online conduct, acceptable content, licensing, privacy, consumer
                                                    protection, unfair competition, and false advertising).

                                                </li>
                                                <li><strong className="fw-bold text-dark">6.
                                                </strong> If the platform is accessed by any Customer then they are at
                                                    least eighteen (18) years of age and capable of forming a legally
                                                    binding agreement and demonstrate the intention and capacity to
                                                    utilise services provided by the Partners effectively. If the
                                                    Customers are under 18, they may only use our Platform with the
                                                    consent and supervision of a parent or legal guardian. Minors under
                                                    the age of 13 are not eligible to use our Services. If the Customers
                                                    are between 13 and 18 years old, they must obtain parental or
                                                    guardian consent before accessing our Platform.
                                                </li>

                                            </ul>
                                        </ul>
                                        <ul className="ps-3 text-secondary listing some-space mt-2">
                                            <h6 className="fw-bold text-dark">2. Prohibited Users:

                                            </h6>
                                            <ul className="ps-3 text-secondary listing some-space mt-2">
                                                <li><strong className="fw-bold text-dark">1. Individuals under 13 years
                                                    old: </strong>
                                                    Minors under the age of 13 are not eligible to use our Services. If
                                                    you are between 13 and 18 years old, please refrain from using our
                                                    Platform without appropriate parental or guardian consent.

                                                </li>

                                                <li><strong className="fw-bold text-dark">2. Users previously barred
                                                    from using the Services </strong>
                                                    Users who have been previously suspended or prohibited from
                                                    accessing our Services by us or any competent authority are not
                                                    allowed to use our Platform.

                                                </li>

                                                <li><strong className="fw-bold text-dark">3. Users Engaging in Illegal
                                                    Activities: </strong>
                                                    Individuals or entities engaged in or intending to engage in any
                                                    activity that is illegal under applicable law, including but not
                                                    limited to fraud, money laundering, and terrorism financing.

                                                </li>
                                                <li><strong className="fw-bold text-dark">4. Individuals from Sanctioned
                                                    Territories: </strong>
                                                    You are prohibited from using our Platform if you or the business
                                                    entity you represent is domiciled, registered, or conducts business
                                                    in any country or territory subject to financial and economic
                                                    sanctions, trade embargoes, or similar restrictions imposed by
                                                    Government of India, including but not limited to Pakistan, North
                                                    Korea, and Syria. This also applies if you are listed as a
                                                    prohibited, sanctioned, debarred, or denied party by the Directorate
                                                    General of Foreign Trade (DGFT) of India, the Reserve Bank of India
                                                    (RBI), the Ministry of External Affairs (MEA), or any other relevant
                                                    Indian authority. This Clause shall also apply if you or the
                                                    business entity you represent is domiciled, registered, or conducts
                                                    business in any country or territory subject to financial and
                                                    economic sanctions, trade embargoes United Nations, UN Security
                                                    Council or any other relevant UN agency. You must obtain any
                                                    required licence or government authorisation before accessing our
                                                    services if you fall under any of these categories.

                                                </li>
                                            </ul>
                                        </ul>
                                        <ul className="ps-3 text-secondary listing some-space mt-2">
                                            <h6 className="fw-bold text-dark">3. Compliance with Laws:


                                            </h6>
                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1. </strong>
                                                    All Users must comply with applicable laws, regulations, and
                                                    policies when using our Platform and/or Services. It is your
                                                    responsibility to ensure that your use of the Platform does not
                                                    violate any laws or regulations in your jurisdiction.

                                                </li>

                                            </ul>
                                            <h6 className="fw-bold text-dark">4. International Users:
                                            </h6>

                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1. </strong>
                                                    Our Platform and/or Services are intended for global use, but Users
                                                    must understand and comply with the laws and regulations of their
                                                    respective countries or regions. We do not guarantee that our
                                                    Platform complies with local laws outside India, and Users are
                                                    solely responsible for ensuring their compliance with applicable
                                                    laws.
                                                </li>

                                            </ul>
                                        </ul>
                                        <li><strong className="fw-bold text-dark">2. HOW CAN YOU ACCESS OUR PLATFORM AND
                                            SERVICES?
                                        </strong><br/>
                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1. Account Registration and
                                                    Verification:
                                                </strong>
                                                </li>
                                                <ul className="ps-3 text-secondary listing some-space">
                                                    <li><strong className="fw-bold text-dark">1. For Partners:

                                                    </strong><br/>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li><strong className="fw-bold text-dark">1.
                                                            </strong> To access and use the Services, you must register
                                                                by creating an account (the “Account”). During
                                                                registration, you are required to provide valid and
                                                                accurate details as prompted by the registration form.
                                                                This may include, without limitation, your
                                                                organisation's name, full address, website URL, name of
                                                                the authorised user, business email address and contact
                                                                number. Upon submission of the registration form, you
                                                                will receive an email with a secure link to upload the
                                                                required certifications and licenses. Your registration
                                                                will remain incomplete until the required documents are
                                                                uploaded.

                                                            </li>
                                                            <li><strong className="fw-bold text-dark">2.
                                                            </strong> You acknowledge and agree that once the
                                                                document(s) are uploaded, our team will review the
                                                                submitted document(s) for compliance with our
                                                                eligibility requirements. If additional documentation is
                                                                required, you will receive an email requesting further
                                                                details. Upon successful verification, you will receive
                                                                a confirmation email stating that your account has been
                                                                approved and activated. The email will also contain
                                                                instructions for logging in for the first time. Please
                                                                note, until the verification is completed, you will not
                                                                be able to utilize the Platform or Services.

                                                            </li>
                                                            <li><strong className="fw-bold text-dark">3.
                                                            </strong> For all subsequent logins, you must use your
                                                                registered business email address and password to access
                                                                your Account. An One-Time Password (OTP) will be sent to
                                                                your registered email for verification. Once the OTP is
                                                                successfully entered, you will gain access to your
                                                                Account.

                                                            </li>
                                                            <li><strong className="fw-bold text-dark">4.
                                                            </strong> You are solely responsible for maintaining the
                                                                confidentiality of your Account credentials and are
                                                                liable for all activities conducted under the Account.
                                                                If you have previously registered, you should log
                                                                in/sign in to your Account using the same business email
                                                                address provided during the initial registration
                                                                process. Please note that there is no fee required to
                                                                register on the Platform.

                                                            </li>
                                                        </ul>

                                                    </li>
                                                    <li><strong className="fw-bold text-dark">2. For Customers:
                                                    </strong> Customers do not need to register on the Platform
                                                        directly. All interactions, including bookings, payments, and
                                                        service requests, are handled through registered Partners.
                                                        Customers must provide valid and accurate information directly
                                                        to the Partner for travel arrangements. Additionally, in case of
                                                        difficulties or for any communication the Customers must contact
                                                        the Partners directly.

                                                    </li>
                                                </ul>
                                                <li><strong className="fw-bold text-dark">2. Account Access; Roles and
                                                    Permissions:

                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li><strong className="fw-bold text-dark">1.
                                                        </strong> When you create an Account, you add additional team
                                                            members (“Subuser”) and authorize such Subusers to manage
                                                            the Dashboards or configure the Services to meet their
                                                            organization's needs. If a Subuser accesses the Platform and
                                                            Services, they may perform actions based on the roles and
                                                            permissions assigned to them. All activities conducted by
                                                            Subusers will be considered to have occurred on behalf of
                                                            the primary account holder, including bookings, payments,
                                                            and cancellations, unless specifically stated otherwise.

                                                        </li>
                                                        <li><strong className="fw-bold text-dark">2.
                                                        </strong> If a third party creates and configures your Account
                                                            and Dashboards on your behalf, that third party likely
                                                            assumes the role of administrator. It is important to
                                                            establish a suitable agreement with such administrators,
                                                            outlining their roles, permissions and limitations as an
                                                            administrator of your Account. Further, it is important for
                                                            the primary account holder to manage the roles and
                                                            permissions of Subusers and administrators carefully. The
                                                            primary Account holder retains full responsibility for all
                                                            activities conducted on the Platform, including those
                                                            performed by Subusers and administrators.

                                                        </li>
                                                        <li><strong className="fw-bold text-dark">3.
                                                        </strong> The Users are responsible for (i) maintaining the
                                                            confidentiality of your organization Account credentials,
                                                            (ii) inviting competent individuals as Subusers and/or
                                                            administrators to manage your Account, and (iii) ensuring
                                                            that all activities associated with your Account comply with
                                                            these Terms. Please note that the Platform is not
                                                            responsible for the administration and internal management
                                                            of the Services on your behalf.
                                                        </li>
                                                        <li><strong className="fw-bold text-dark">4.
                                                        </strong> Please note that these Terms also apply to Subusers
                                                            and administrators, and they must adhere to the terms and
                                                            conditions outlined herein. To maintain the security and
                                                            integrity of the Platform, it is essential to keep your
                                                            login credentials confidential and only grant access to
                                                            individuals you trust. We recommend that you carefully
                                                            consider the roles and permissions assigned to Subusers and
                                                            administrators, ensuring that they align with their
                                                            respective responsibilities and duties within your
                                                            organization.
                                                        </li>
                                                    </ul>

                                                </li>
                                                <li><strong className="fw-bold text-dark">3. Account Security and
                                                    Update:


                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li><strong className="fw-bold text-dark">1.
                                                        </strong> You are responsible for safeguarding your Account
                                                            credentials and preventing unauthorized access to your
                                                            Account. Any unauthorized use of an Account must be reported
                                                            to the Company immediately for investigation.

                                                        </li>
                                                        <li><strong className="fw-bold text-dark">2.
                                                        </strong> If you suspect that unauthorized access has been made
                                                            to your Account, you must notify us immediately by email at
                                                            ________________. We will investigate any alleged
                                                            unauthorized Account activity. Notwithstanding any other
                                                            terms pertaining to our right to disable or block access to
                                                            your Account, we reserve the right to disable or block your
                                                            Account at any time when it is suspected that unauthorized
                                                            access has been made to your Account.
                                                        </li>
                                                        <li><strong className="fw-bold text-dark">3.
                                                        </strong> If you share or allow others to have access to your
                                                            Account, you assume exclusive liability and responsibility
                                                            for all activities conducted on your Account, as well as any
                                                            resulting consequences.
                                                        </li>
                                                        <li><strong className="fw-bold text-dark">4.
                                                        </strong> You acknowledge and agree that we shall not bear any
                                                            liability or responsibility for the activities or
                                                            consequences arising from the use or misuse of any
                                                            information under your Account.
                                                        </li>
                                                    </ul>

                                                </li>
                                                <li><strong className="fw-bold text-dark">4. Account Suspension and/or
                                                    Deletion:


                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li><strong className="fw-bold text-dark">1.
                                                        </strong> You agree to promptly inform us of any unauthorized
                                                            use of your Account. In the event of a suspected security
                                                            breach or misuse of your Account, we reserve the right to
                                                            suspend your Account, without incurring any liability to the
                                                            Company, for a duration deemed appropriate in the
                                                            circumstances. We disclaim any responsibility for any loss
                                                            or damage resulting from your non-compliance with this
                                                            provision.

                                                        </li>
                                                        <li><strong className="fw-bold text-dark">2.
                                                        </strong> You shall be solely responsible for the accuracy and
                                                            correctness of all such details/information given by you
                                                            during Account setup or during the utilization of our
                                                            Services. If we have reason to doubt the correctness of any
                                                            details/information furnished by you or in case any
                                                            information furnished by you is found incorrect, false, or
                                                            misleading, we reserve the right to cancel or suspend the
                                                            registration of the User permanently or for such period as
                                                            we deem fit.
                                                        </li>

                                                    </ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li><strong className="fw-bold text-dark">3. HOW CAN YOU ACCESS OUR PLATFORM AND
                                            SERVICES?
                                        </strong><br/>
                                            <p>Once your Account is successfully created, you will gain access to your
                                                Dashboard (“<strong className="fw-bold text-dark">Dashboard(s)</strong>”).
                                                Through the Dashboard, you can access various booking services, tools,
                                                and avail discounts and offers (if applicable). You may also track
                                                reservations, initiate cancellations, manage payments, and handle
                                                customer interactions, subject to the features available on the
                                                Platform.
                                            </p>
                                            <ul className="ps-3 text-secondary listing some-space">
                                                <li><strong className="fw-bold text-dark">1. Booking, Cancellation, and
                                                    Communication:
                                                </strong><br/>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li><strong className="fw-bold text-dark">1.
                                                        </strong>
                                                            Once you access your Dashboard, you can explore various
                                                            travel listings, including but not limited to standard
                                                            bookings, special fares such as student and senior citizen
                                                            discounts, promotional fares, last-minute deals, and
                                                            bulk/group booking offers provided by third-party travel
                                                            service providers. Where applicable, you may also avail of
                                                            incidental discounts or promotional price reductions offered
                                                            by the Platform, subject to availability and applicable
                                                            terms.
                                                        </li>
                                                        <li><strong className="fw-bold text-dark">2.
                                                        </strong>
                                                            You acknowledge that upon selecting a desired listing, you
                                                            may proceed with the booking and payment process in
                                                            accordance with applicable terms and conditions of the
                                                            relevant third-party service provider, along with these
                                                            Terms. After the successful payment, the system will
                                                            generate invoices and travel tickets, which will be sent to
                                                            your registered email address. Additionally, you may access
                                                            these documents under the “Previous Orders” section of your
                                                            Dashboard for future reference.
                                                        </li>
                                                        <li><strong className="fw-bold text-dark">3.
                                                        </strong>
                                                            You understand that the ability to track the status of
                                                            reservations and travel itineraries depends on the
                                                            availability of tracking features for that particular
                                                            booking. Such features, if provided, are subject to the
                                                            policies of the respective travel service providers and may
                                                            vary from one provider to another. The Platform does not
                                                            guarantee real-time tracking for all bookings.
                                                        </li>
                                                        <li><strong className="fw-bold text-dark">4.
                                                        </strong>
                                                            You acknowledge that the Platform does not currently provide
                                                            or facilitate any form of travel insurance, including but
                                                            not limited to trip cancellations, medical coverage, or
                                                            baggage protection. However, third-party service providers
                                                            may offer insurance-related options, which Partners may
                                                            choose to present to Customers as an additional service. Any
                                                            such insurance offerings will be provided solely by
                                                            third-party service providers, and any related payments,
                                                            terms, or claims will be governed by their respective
                                                            policies. The Platform does not assume any liability or
                                                            responsibility for such offerings. You further acknowledge
                                                            that the Platform reserves the right to introduce
                                                            insurance-related services in the future at its sole
                                                            discretion. Any such addition will be communicated to Users,
                                                            and the applicable terms and conditions will be updated
                                                            accordingly.
                                                        </li>
                                                        <li><strong className="fw-bold text-dark">5.
                                                        </strong>
                                                            If you wish to cancel a booking or request a refund, you can
                                                            initiate the process directly through your Dashboard.
                                                            However, all cancellations and refund requests will be
                                                            governed strictly by the Cancellation and Refund Policy,
                                                            which sets forth the applicable terms, eligibility criteria,
                                                            and timelines. The approval or rejection of such requests
                                                            will be at the sole discretion of the relevant service
                                                            provider, and the Platform does not assume any
                                                            responsibility for the outcome of such requests. The refund
                                                            amount, if applicable, will be processed based on the
                                                            provider’s terms and may be subject to deductions such as
                                                            cancellation fees or service charges.
                                                        </li>
                                                        <li><strong className="fw-bold text-dark">6.
                                                        </strong>
                                                            Additionally, where enabled, the Platform may provide
                                                            communication tools that allow you to interact directly with
                                                            third-party service providers regarding your bookings,
                                                            modifications, cancellations, or any other service-related
                                                            queries. The nature and extent of such communication options
                                                            may differ based on the service provider’s integration with
                                                            the Platform. You may also contact the Platform’s support
                                                            team via designated communication channels for technical
                                                            support or general inquiries related to the Platform’s
                                                            functionalities. However, you acknowledge that the Platform
                                                            does not mediate disputes between you and third-party
                                                            service providers and is not responsible for their
                                                            responses, service quality, or resolutions provided.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><strong className="fw-bold text-dark">2. Partner Commissions and
                                                    Invoicing:</strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1. </strong>
                                                            You acknowledge that you have the flexibility to determine
                                                            and charge convenience fees or commissions (“<strong
                                                            className="fw-bold text-dark">Commission</strong>”) from
                                                            Customers in connection with bookings made through the
                                                            Platform.

                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2. </strong>
                                                            The Platform offers e-wallet facility (“<strong
                                                            className="fw-bold text-dark">E-wallet</strong>”) to the
                                                            registered Partners, enabling you to receive and manage
                                                            Commission earnings from bookings facilitated through the
                                                            Platform. For instance, if the base ticket fare set by a
                                                            third-party service provider is INR 10,000 and you apply a
                                                            Commission of INR 2,000, the total amount payable will be
                                                            INR 12,000. Upon successful payment processing, INR 10,000
                                                            will be remitted to the respective third-party service
                                                            provider in accordance with their payment policies, and INR
                                                            2,000 will be credited to your E-wallet on the Platform.

                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3. </strong>
                                                            You may use your E-wallet balance for future transactions on
                                                            the Platform or withdraw funds by providing valid bank
                                                            account details through the designated withdrawal process.
                                                            Withdrawal requests will be processed within _____ business
                                                            days from the date of request, subject to banking
                                                            regulations, Payment Processor policies, and applicable
                                                            verification procedures.

                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">4. </strong>
                                                            You acknowledge and agree that withdrawals are subject to
                                                            transaction limits imposed by banks, Payment Processor, and
                                                            regulatory authorities. Any fees or charges imposed by
                                                            financial institutions for processing withdrawals shall be
                                                            borne by you. The Platform is not responsible for any
                                                            delays, failed transactions, or errors caused by banks,
                                                            third-party payment service providers, or incorrect bank
                                                            details provided by you. In case of any discrepancies or
                                                            delays beyond the stipulated processing timeframe, you shall
                                                            directly coordinate with your respective banks or Payment
                                                            Processor. You further acknowledge that the Platform
                                                            reserves the right to suspend, delay, or decline withdrawal
                                                            requests in cases of suspected fraudulent activity,
                                                            non-compliance with these Terms, or regulatory requirements.

                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">5. </strong>
                                                            The maximum balance that can be maintained in the E-wallet
                                                            at any given time shall be INR _________ (“ <strong
                                                            className="fw-bold text-dark">Upper Limit</strong>”). The
                                                            Platform reserves the right to modify this limit at its sole
                                                            discretion. Partners are responsible for ensuring that their
                                                            E-wallet balance does not exceed this Upper Limit, and any
                                                            excess funds may be automatically transferred to the
                                                            Partner’s registered bank account or withheld until a
                                                            withdrawal request is made. There is no minimum balance
                                                            requirement for the E-wallet.

                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">6. </strong>
                                                            You acknowledge and agree that the Platform does not
                                                            generate or issue invoices on your behalf to the Customers.
                                                            You are solely responsible for issuing invoices, receipts,
                                                            or any other necessary documentation to Customers, where
                                                            legally required. Any disputes regarding invoices must be
                                                            resolved directly between you and the Customer. The Platform
                                                            bears no liability for any errors, omissions, or
                                                            non-compliance in such invoicing.

                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">7. </strong>
                                                            Please note that all payments, transactions, and financial
                                                            processes mentioned in this subclause shall be subject to
                                                            and governed by Clause 4 and 5 of these Terms.

                                                        </li>
                                                    </ul>
                                                    <br/>
                                                </li>
                                                <li><strong className="fw-bold text-dark">3. Notifications and Profile
                                                    Management:
                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1. </strong>
                                                            Once registered on the Platform, you will receive real-time
                                                            notifications related to your bookings, payments,
                                                            cancellations, refunds, promotional offers, and any updates
                                                            regarding Platform policies or services. Notifications may
                                                            be delivered via email, SMS, push notifications, or through
                                                            alerts within your Dashboard. You acknowledge and agree that
                                                            the nature, frequency, and delivery method of these
                                                            notifications may vary based on your Account settings, the
                                                            type of transaction, and the Platform’s operational
                                                            requirements.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2. </strong>
                                                            You are responsible for ensuring that your registered
                                                            contact details, including email address and phone number,
                                                            are accurate and up to date. The Platform shall not be
                                                            liable for any failure to receive notifications due to
                                                            outdated or incorrect contact information provided by you.
                                                            Additionally, while we endeavor to ensure timely delivery of
                                                            notifications, we do not guarantee uninterrupted or
                                                            error-free transmission and shall not be held liable for any
                                                            loss, inconvenience, or damages arising from delayed,
                                                            misplaced, or undelivered notifications.

                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3. </strong>
                                                            Through your Dashboard, you may access and manage your
                                                            “Profile Settings”, “Team Management” (if applicable), and
                                                            “Platform Preferences”. You may update your business or
                                                            organization details, including contact information,
                                                            business registration details, certifications and any other
                                                            required credentials, subject to the Platform’s verification
                                                            policies and applicable restrictions. Certain modifications,
                                                            such as changes to your legal entity name or certification,
                                                            may require additional documentation and approval from the
                                                            Platform.

                                                        </li>

                                                    </ul>
                                                    <br/>
                                                </li>
                                                <li><strong className="fw-bold text-dark">4. WHAT ARE THE
                                                    RESPONSIBILITIES AND OBLIGATIONS OF THE PARTNERS?

                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1. Customer Data
                                                                Handling and Confidentiality:
                                                            </strong><br/>
                                                        </li>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li>
                                                                <strong className="fw-bold text-dark">1. </strong>
                                                                You are responsible for ensuring that all Customer data
                                                                you collect, process, store, or share complies with
                                                                applicable data protection, privacy, and cybersecurity
                                                                laws. You must implement appropriate technical and
                                                                organizational measures to safeguard Customer data from
                                                                unauthorized access, loss, or misuse. You acknowledge
                                                                that the Company is not responsible for any data
                                                                breaches resulting from your failure to implement
                                                                adequate security measures.

                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">1. </strong>
                                                                Customer data may only be used strictly for fulfilling
                                                                bookings, processing payments, managing travel
                                                                itineraries, and communicating essential travel-related
                                                                information. Any use beyond these purposes such as
                                                                marketing, profiling, or sharing with third parties,
                                                                requires explicit prior consent from the Customer and
                                                                must be in compliance with applicable laws and our
                                                                Privacy Policy.

                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">2. </strong>
                                                                You must not export, transfer, share, sell, lease, or
                                                                otherwise commercialize any Customer data collected
                                                                through the Platform. This includes, but is not limited
                                                                to, selling Customer details to third parties for
                                                                marketing or lead generation, using travel data for
                                                                targeted advertisements beyond your direct service
                                                                offering, sharing Customer booking or payment
                                                                information with any third party without legal basis or
                                                                Customer consent, etc. You understand and agree that
                                                                unauthorized data use or breaches may result in
                                                                immediate suspension or termination of your Account and
                                                                potential legal action.

                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">3. </strong>
                                                                You must not retain Customer data longer than necessary
                                                                for fulfilling bookings and legal compliance. Upon
                                                                completion of travel services or contract termination,
                                                                you must securely delete Customer personal information
                                                                unless legally required to retain it and ensure that any
                                                                stored data is anonymized if used for internal
                                                                analytics.

                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">4. </strong>
                                                                You agree to maintain strict confidentiality of any
                                                                non-public business, technical, or proprietary
                                                                information obtained through the Platform, including but
                                                                not limited to Customer travel history and preferences,
                                                                any details related to the Customers, pricing models,
                                                                commission structures, and Partner-exclusive deals, any
                                                                other proprietary information of the Company. You must
                                                                not disclose such information to any third party unless
                                                                required by law or with prior written approval from the
                                                                Company.

                                                            </li>
                                                        </ul>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2. Service Integrity
                                                                and Partner Conduct:

                                                            </strong><br/>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong className="fw-bold text-dark">1. </strong>
                                                                    You shall provide Customers with accurate, clear,
                                                                    and complete information regarding travel services,
                                                                    pricing, refund policies, availability, terms, and
                                                                    conditions. Any misleading, deceptive, or false
                                                                    representations about travel itineraries, refund
                                                                    policies, or service capabilities are strictly
                                                                    prohibited.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">1. </strong>
                                                                    You must ensure that all necessary licenses,
                                                                    accreditations, and regulatory approvals (including,
                                                                    but not limited to, IATA accreditation, Seller of
                                                                    Travel licenses, ATOL certification, or any
                                                                    equivalent) are valid and up to date while using the
                                                                    Platform. If a license is revoked, expired, or
                                                                    otherwise invalidated, you must notify the Company
                                                                    immediately and cease providing affected services.

                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">2. </strong>
                                                                    You shall not misrepresent your relationship with
                                                                    the Company or falsely imply any endorsement,
                                                                    sponsorship, or approval by the Company without
                                                                    prior written authorization. You may not use the
                                                                    Company’s logo, trademarks, or brand assets without
                                                                    explicit permission.

                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">3. </strong>
                                                                    You are solely responsible for managing Customer
                                                                    inquiries, complaints, cancellations, refunds, and
                                                                    disputes in a professional and timely manner. The
                                                                    Company does not mediate Customer disputes on your
                                                                    behalf, and you agree to indemnify the Company
                                                                    against any claims arising from your interactions
                                                                    with Customers.
                                                                </li>

                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3. Tax
                                                                Compliance:</strong><br/>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">1.</strong> You
                                                                    acknowledge and agree that you are solely
                                                                    responsible for determining, reporting, collecting,
                                                                    withholding, and remitting any and all applicable
                                                                    taxes, duties, levies, or similar governmental
                                                                    charges (including but not limited to GST, VAT,
                                                                    service tax, or income tax) (“Taxes”) that may arise
                                                                    in connection with your use of the Platform, the
                                                                    Commissions charged to Customers, or any other
                                                                    earnings received through the Platform.
                                                                </li>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">2.</strong> The
                                                                    Platform does not assume any responsibility for
                                                                    calculating, withholding, or remitting Taxes on your
                                                                    behalf, nor does it provide any tax-related advice
                                                                    or compliance assistance. It is your sole
                                                                    responsibility to ensure compliance with all tax
                                                                    obligations imposed by relevant governmental
                                                                    authorities in your jurisdiction.
                                                                </li>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">3.</strong> You
                                                                    shall indemnify and hold the Platform harmless from
                                                                    any claims, penalties, losses, or liabilities
                                                                    arising from your failure to comply with applicable
                                                                    tax laws, including but not limited to non-payment,
                                                                    incorrect calculation, or late payment of Taxes.
                                                                </li>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">4.</strong> The
                                                                    Platform may, at its sole discretion and to the
                                                                    extent required by law, report payments made to you
                                                                    to relevant tax authorities or withhold certain
                                                                    amounts from your earnings as required under
                                                                    applicable laws. You may be required to provide
                                                                    valid tax registration details, tax residency
                                                                    certificates, or any other documentation necessary
                                                                    for compliance with tax regulations. Failure to
                                                                    provide the required documentation may result in
                                                                    delays, withholding of payments, or suspension of
                                                                    your access to the Platform’s financial features.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">5.</strong> In
                                                                    case of international transactions, you are
                                                                    responsible for handling import duties, foreign tax
                                                                    obligations, or withholding taxes applicable in
                                                                    cross-border transactions.
                                                                </li>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">6.</strong> You
                                                                    must maintain accurate tax records, invoices, and
                                                                    filings for transactions conducted through the
                                                                    Platform. The Platform does not assume any
                                                                    responsibility for collecting, withholding,
                                                                    reporting, or remitting taxes on your behalf. Any
                                                                    disputes or penalties related to Taxes are solely
                                                                    your responsibility.
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">4. Fair Usage
                                                                Policy:</strong><br/>
                                                            <p>The following terms constitute “<strong
                                                                className="fw-bold text-dark"> Usage Policy</strong>”:
                                                            </p>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">1.</strong> You
                                                                    must not abuse or exploit the Platform’s features to
                                                                    gain unfair advantages, manipulate services, or
                                                                    circumvent intended use. Prohibited actions include,
                                                                    but are not limited to creating multiple accounts to
                                                                    access promotional benefits or circumvent
                                                                    restrictions, artificially inflating demand by
                                                                    making fraudulent bookings or submitting false
                                                                    leads, scraping, copying, or harvesting data from
                                                                    the Platform without authorization, etc.
                                                                </li>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">2.</strong> All
                                                                    bookings, payments, and service transactions must be
                                                                    conducted in good faith and in accordance with the
                                                                    Company’s policies. Partners must:
                                                                </li>
                                                                <ul className="ps-3 text-secondary listing some-space">
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">1. </strong> determine
                                                                        and charge Commissions from Customers in a fair,
                                                                        transparent, and lawful manner, ensuring that
                                                                        any additional fees are clearly disclosed before
                                                                        payment is processed;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">2. </strong> adhere
                                                                        to the Platform’s Cancellation and Refund Policy
                                                                        without imposing additional undisclosed charges
                                                                        on Customers;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">3. </strong> ensure
                                                                        secure and accurate payment processing, without
                                                                        unauthorized surcharges; and
                                                                    </li>

                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">4. </strong> not
                                                                        engage in fraudulent chargebacks or deceptive
                                                                        pricing practices.
                                                                    </li>

                                                                </ul>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">3. </strong> You
                                                                    shall not access or use the Platform through
                                                                    automated means, including but not limited to bots,
                                                                    emulators, scrapers, virtual environments, or
                                                                    Artificial Intelligence tools, to manipulate,
                                                                    extract, or interfere with any feature of the
                                                                    Platform. Any attempt to automate bookings, scrape
                                                                    pricing, Customer information, or content, create or
                                                                    modify user profiles artificially, or engage in
                                                                    unauthorized data collection or modification of the
                                                                    Platform’s functionality is strictly prohibited.
                                                                </li>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">4. </strong> You
                                                                    are responsible for ensuring that your hardware,
                                                                    software, and internet connection meet the technical
                                                                    requirements necessary to access and use the
                                                                    Platform and Services. The Company is not
                                                                    responsible for any issues that arise from your
                                                                    failure to meet these requirements, including
                                                                    compatibility issues or system failures.
                                                                </li>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">5. </strong> You
                                                                    must take all reasonable precautions to protect your
                                                                    system and any data you process through the
                                                                    Platform. This includes ensuring that your computer
                                                                    systems do not expose you to viruses, malware, or
                                                                    any other malicious software that could harm your
                                                                    devices or compromise your data security. The
                                                                    Company is not responsible for any loss or damage to
                                                                    your computer systems or data arising from the use
                                                                    of the Platform.
                                                                </li>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">6. </strong> You
                                                                    agree that the country of residence and/or your
                                                                    company’s country of incorporation aligns with the
                                                                    country specified in the contact and/or billing
                                                                    address you provide to us.
                                                                </li>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">5. </strong> You
                                                                    agree to fully comply with all applicable laws,
                                                                    regulations, and contractual terms governing your
                                                                    use of the Platform and Services. This includes any
                                                                    laws specific to your geographical location or the
                                                                    location of your Customers. Compliance extends to
                                                                    ensuring that surveys, and any User Content do not
                                                                    violate any relevant laws, including those related
                                                                    to data protection, intellectual property, and
                                                                    consumer protection.
                                                                </li>
                                                                <li>
                                                                    <strong
                                                                        className="fw-bold text-dark">5. </strong> You
                                                                    acknowledge and agree that violations of this Fair
                                                                    Usage Policy may result in temporary or permanent
                                                                    suspension from the Platform, termination of your
                                                                    Account, and/or legal action as necessary.
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">5. Account Management
                                                                and Restrictions:
                                                            </strong><br/>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong className="fw-bold text-dark">1. </strong>
                                                                    You may not assign, transfer, sublicense, lease,
                                                                    resell, or share access to your Account with
                                                                    unauthorized third parties, aggregators, or
                                                                    intermediaries. Your Account is strictly for your
                                                                    business operations, and any detected misuse may
                                                                    lead to Account termination.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">2. </strong>
                                                                    You agree not to create an account or otherwise
                                                                    access the Platform with the intent to impersonate
                                                                    another person or use a Username that is subject to
                                                                    someone else’s rights without proper authorization.
                                                                    Impersonation, unauthorized use, or misleading
                                                                    representation is strictly prohibited.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">3. </strong>
                                                                    You agree that we shall not bear any liability or
                                                                    responsibility for the activities or consequences
                                                                    arising from the use or misuse of any information
                                                                    under your Account, including without limitation,
                                                                    situations where you have neglected to update your
                                                                    mobile phone number and/or email address on the
                                                                    Platform.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">4. </strong>
                                                                    Linking to the Platform is permitted as long as it
                                                                    is done in a manner that is fair, legal, and does
                                                                    not tarnish our reputation. You must not link in a
                                                                    way that implies an association, approval, or
                                                                    endorsement by the Company, unless explicitly
                                                                    authorized. You may not frame or embed the Platform
                                                                    on another website without express permission.
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <br/>
                                                </li>
                                                <li><strong className="fw-bold text-dark">5. WHAT ARE THE PAYMENT TERMS?
                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1. </strong>
                                                            You understand that the registration on the Platform is
                                                            currently free of charge. Partners may access Platform
                                                            features, manage bookings, and utilize available tools
                                                            without incurring any service or subscription fees.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2. </strong>
                                                            However, the Platform reserves the right to introduce a paid
                                                            model at its sole discretion. If implemented, this may
                                                            include service fees, subscription plans, freemium models,
                                                            premium features, or additional fees for access to advanced
                                                            functionalities or value-added services.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3. </strong>
                                                            In the event that the Platform introduces a paid model or
                                                            modifies its fee structure, you will be notified in advance.
                                                            Such changes will be accompanied by updated Terms, detailed
                                                            pricing information, and applicable payment methods. You
                                                            must review and accept these updated terms to continue
                                                            accessing the Platform and/or Services under the new pricing
                                                            model.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">4. </strong>
                                                            All payments made by you on the Platform shall be in <u>_____________[<strong
                                                            className="fw-bold text-dark">Insert Currency(ies)</strong>]</u>.
                                                            We will not facilitate transactions concerning any other
                                                            form of currency with respect to the payment. If you choose
                                                            to pay using a different currency, the applicable conversion
                                                            rate will apply, and any conversion costs will be borne by
                                                            you alone.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">5. </strong>
                                                            We use CCAvenue as a provider of payment gateway services (“<strong
                                                            className="fw-bold text-dark">Payment Processor</strong>”).
                                                            You acknowledge and agree that any payment made on the
                                                            Platform shall also be subject to the terms and conditions
                                                            of our Payment Processor. You agree and acknowledge that in
                                                            the future, we may utilise the services of other payment
                                                            services providers and you shall be subject to their terms
                                                            and conditions as and when applicable.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">6. </strong>
                                                            You are authorised to use valid credit/debit cards, or any
                                                            other accepted payment methods, including online banking
                                                            facilities and Unified Payment Interface (UPI), for
                                                            completing the payment. You are required to provide accurate
                                                            and complete information regarding your credit/debit card
                                                            details, UPI information (if applicable), or online banking
                                                            account details when using the payment link. It is your
                                                            responsibility to ensure the correctness of the information
                                                            submitted, and any costs, expenses, losses, or damages
                                                            incurred as a result of providing incorrect details shall be
                                                            solely your responsibility.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">7. </strong>
                                                            We disclaim any responsibility and liability for any loss or
                                                            damage incurred by you during the utilisation of available
                                                            payment methods on the Platform. This includes but is not
                                                            limited to, instances such as:
                                                        </li>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li>Lack of authorization for a transaction;</li>
                                                            <li>Exceeding the mutually agreed preset limit between you
                                                                and the respective bank;
                                                            </li>
                                                            <li>Payment issues arising from the transaction; and/or
                                                            </li>
                                                            <li>Transaction being declined due to any other reasons.
                                                            </li>
                                                        </ul>
                                                        <li>
                                                            <strong className="fw-bold text-dark">8. </strong>
                                                            You are solely responsible for providing accurate and
                                                            complete payment information; any costs, expenses, losses,
                                                            or damages resulting from incorrect details submitted will
                                                            be your responsibility.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">9. </strong>
                                                            By accepting these Terms, you expressly authorize us and our
                                                            third-party Payment Processor to electronically collect,
                                                            process, facilitate, and remit payments, including the
                                                            transaction amount, through electronic means between Users
                                                            on the Platform. You agree and accept that all nuances and
                                                            modalities relating to payments shall be separately governed
                                                            by the Payment Processor. We would not be responsible, in
                                                            any manner whatsoever, for any liability that may arise in
                                                            relation to any aspect of/relating to the payment modes
                                                            (including any fraudulent transaction).
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">10. </strong>
                                                            You acknowledge, understand, and agree that the payment
                                                            facility provided by us constitutes neither a banking nor
                                                            financial service. Instead, we function as a facilitator,
                                                            offering an electronic, automated online payment, and
                                                            remittance facility for transactions on the Platform through
                                                            the existing authorized banking infrastructure and credit
                                                            card payment gateway networks. It is imperative to clarify
                                                            that, in providing the payment facility, we assume neither
                                                            the role of a trustee nor engage in a fiduciary capacity
                                                            concerning the transaction, thereby absolving itself from
                                                            such responsibilities.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">11. </strong>
                                                            We affirm that the information provided by you in this
                                                            regard will be treated as confidential and will not be
                                                            divulged to any third party, except as necessitated by the
                                                            applicable laws and/or processes of any government
                                                            authority, and/or in connection with any judicial
                                                            proceedings pertaining to legal actions, suits, and/or
                                                            proceedings arising from or related to these Terms.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">12. </strong>
                                                            We diligently report and pursue both confirmed and suspected
                                                            instances of UPI or credit/debit card fraud concerning our
                                                            Services. We may request additional authorization from you
                                                            and the decision to seek further authorization rests solely
                                                            with us. We retain the right to annul, postpone, decline
                                                            delivery, or retract any transaction in case of suspected
                                                            fraud. We shall not be held liable to you for any losses
                                                            arising from such cancellation.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">13. </strong>
                                                            During the transaction process, we or third-party payment
                                                            processors may collect specific information such as user ID,
                                                            time, date, IP address, and other relevant details aimed at
                                                            identifying individuals involved in fraudulent activities.
                                                            In cases where a transaction is suspected to be fraudulent,
                                                            all records will be provided, with or without a summons, to
                                                            law enforcement agencies and the credit/debit card company
                                                            for a thorough fraud investigation. We are committed to
                                                            collaborating with authorities to ensure the prosecution of
                                                            offenders to the maximum extent permitted by law.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">14. </strong>
                                                            You agree not to hold us liable for payments that do not
                                                            reach us because they have quoted incorrect payment
                                                            information or the Payment Processor refused the payment for
                                                            any other reason.
                                                        </li>
                                                    </ul>

                                                </li>
                                                <li><strong className="fw-bold text-dark">6. WHAT ARE THE USER CONTENT
                                                    GUIDELINES?

                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li><strong className="fw-bold text-dark">1. </strong>
                                                            In utilizing our Platform and Services, you may provide,
                                                            display, submit, or otherwise share your and/or Customers’
                                                            Personal Information through the Platform, provide Feedback
                                                            for your experience with the Platform (in written and/or
                                                            audio-visual format) on the Platform, social media accounts
                                                            or otherwise, or other information as may be required to
                                                            provide Services to you (collectively referred to as "User
                                                            Content"). By doing so, you provide us with a worldwide,
                                                            irrevocable, non-exclusive, royalty-free license to employ,
                                                            reproduce, store, and distribute User Content within the
                                                            operational scope of the Platform's functionalities and our
                                                            Services. This includes but is not limited to, processing
                                                            your User Content to deliver you services and promotional
                                                            and advertising purposes across any media now known or
                                                            hereafter devised. You acknowledge that we may utilize any
                                                            User Content you publish on the Platform, consistent with
                                                            our Privacy Policy and you shall not be entitled to any
                                                            payment or other compensation for such use. It’s your
                                                            responsibility to ensure that the User Content abides by
                                                            applicable jurisdictional laws and any agreement entered
                                                            into. We aren’t responsible for any harm resulting from
                                                            anyone’s access, use, or downloading of User Content, or for
                                                            any harm resulting from third-party websites. You’re
                                                            responsible for taking the necessary precautions to protect
                                                            yourself and your devices from viruses, worms, Trojan
                                                            horses, and other harmful or destructive content.
                                                        </li>
                                                        <li><strong className="fw-bold text-dark">2. </strong> User
                                                            Content must comply with the Content Standards set out in
                                                            this Clause. User Content must not-
                                                            <br/></li>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li>
                                                                <strong className="fw-bold text-dark">1. </strong>
                                                                You understand that the registration on the Platform is
                                                                currently free of charge. Partners may access Platform
                                                                features, manage bookings, and utilize available tools
                                                                without incurring any service or subscription fees.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">2. </strong>
                                                                User Content must comply with the Content Standards set
                                                                out in this Clause. User Content must not-
                                                                <ul className="ps-3 text-secondary listing some-space">
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">1. </strong>
                                                                        contain any material that is defamatory,
                                                                        obscene, indecent, abusive, offensive,
                                                                        harassing, violent, hateful, inflammatory, or
                                                                        otherwise objectionable;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">2. </strong>
                                                                        promote sexually explicit or pornographic
                                                                        material, violence, or discrimination based on
                                                                        race, sex, religion, nationality, disability,
                                                                        sexual orientation, or age;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">3. </strong>
                                                                        infringe any patent, trademark, trade secret,
                                                                        copyright, or other intellectual property or
                                                                        other rights of any other person;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">4. </strong>
                                                                        violate the legal rights (including the rights
                                                                        of publicity and privacy) of others or contain
                                                                        any material that could give rise to any civil
                                                                        or criminal liability under applicable laws or
                                                                        regulations or that otherwise may be in conflict
                                                                        with these Terms of Service or our Privacy
                                                                        Policy;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">5. </strong>
                                                                        be likely to deceive any person;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">6. </strong>
                                                                        promote any illegal activity, or advocate,
                                                                        promote, or assist any unlawful act;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">7. </strong>
                                                                        cause annoyance, inconvenience, or needless
                                                                        anxiety or be likely to upset, embarrass, alarm,
                                                                        or annoy any other person;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">8. </strong>
                                                                        impersonate any person, or misrepresent your
                                                                        identity or affiliation with any person or
                                                                        organisation;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">9. </strong>
                                                                        involve commercial activities or sales, such as
                                                                        contests, sweepstakes, and other sales
                                                                        promotions, barter, or advertising;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">10. </strong>
                                                                        give the impression that they emanate from or
                                                                        are endorsed by us or any other person or entity
                                                                        if this is not the case.
                                                                    </li>
                                                                </ul>
                                                            </li>

                                                        </ul>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3. </strong>
                                                            You confirm that you possess and will continue to maintain
                                                            the complete authority, ownership, licenses, consents, and
                                                            permissions necessary to authorize the Platform to access
                                                            User Content or any part thereof. This authorization allows
                                                            for the importing, exporting, copying, displaying,
                                                            uploading, publishing, transmitting, and/or any other
                                                            utilization of your User Content.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">4. </strong>
                                                            Your Content must not be misleading or unlawful, and must
                                                            not violate any of these Terms, applicable laws, and
                                                            regulations, or infringe or misappropriate any rights of any
                                                            person or entity.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">5. </strong>
                                                            The User acknowledges that we may or may not pre-screen User
                                                            Content. However, we retain the right (but are not
                                                            obligated) to pre-screen, refuse, or remove any User Content
                                                            and/or products from the Platform at any time, for any
                                                            reason. This includes, but is not limited to, instances
                                                            where we receive claims, allegations, or complaints from
                                                            third parties and/or authorities related to such User
                                                            Content, or for no reason at all. However, we do not endorse
                                                            or guarantee the accuracy, completeness, or reliability of
                                                            any User Content.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">6. </strong>
                                                            You affirm that the User Content is (and will continue to
                                                            be) true, current, accurate, non-harmful, non-infringing
                                                            upon any third-party rights, and in no way unlawful for you
                                                            to upload, import, export, copy, possess, post, publish,
                                                            transmit, display or otherwise use, in the country in which
                                                            you reside.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">7. </strong>
                                                            You agree and allow us to disclose your identity or other
                                                            information about you to any third party who claims that
                                                            material posted by you violates their rights, including
                                                            their Intellectual Property Rights or their right to
                                                            privacy.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">8. </strong>
                                                            Without limiting the foregoing, we have the right to
                                                            cooperate fully with any law enforcement authorities or
                                                            court order requesting or directing us to disclose the
                                                            identity or other information of anyone posting any
                                                            materials on or through the Platform.
                                                            <strong className="text-uppercase"> YOU WAIVE AND HOLD
                                                                HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND
                                                                SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY
                                                                ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR
                                                                TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH
                                                                PARTIES OR LAW ENFORCEMENT AUTHORITIES.</strong>
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">9. </strong>
                                                            However, we do not undertake to review all material before
                                                            it is posted on the Platform, and cannot ensure prompt
                                                            removal of objectionable material after it has been posted.
                                                            Accordingly, we assume no liability for any action or
                                                            inaction regarding transmissions, communications, or content
                                                            provided by any User or third party. We have no liability or
                                                            responsibility to anyone for the performance or
                                                            nonperformance of the activities described in this Clause.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">10. </strong>
                                                            <strong className="fw-bold text-dark">Feedback and
                                                                Reviews:</strong> The following terms constitute
                                                            “ <strong className="fw-bold text-dark">Feedback
                                                            Policy</strong>
                                                            ”:
                                                        </li>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li>
                                                                <strong className="fw-bold text-dark">1. </strong>
                                                                You may submit your ratings, reviews, or other feedback
                                                                (collectively, “Feedback”) to us in written or
                                                                audio-visual format, and/or share such Feedback with
                                                                other Users, or the public through our Platform, social
                                                                media platforms, public forums, or other communication
                                                                channels. If you submit Feedback, it shall be
                                                                exclusively owned by our Company.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">2. </strong>
                                                                By providing such Feedback to us, you acknowledge and
                                                                agree that it may be used by us in order to:
                                                                <ul className="ps-3 text-secondary listing some-space">
                                                                    <li>(i) further develop, customise, and improve our
                                                                        Platform and Services,
                                                                    </li>
                                                                    <li>(ii) provide ongoing assistance and technical
                                                                        support,
                                                                    </li>
                                                                    <li>(iii) contact you with general or personalised
                                                                        platform-related notices and/or interview
                                                                        requests based on your ratings and Feedback or
                                                                        otherwise,
                                                                    </li>
                                                                    <li>(iv) facilitate, sponsor, and offer certain
                                                                        promotions, and monitor performance,
                                                                    </li>
                                                                    <li>(v) create aggregated statistical data and other
                                                                        aggregated and/or inferred information, which we
                                                                        may use to provide and improve our Platform and
                                                                        Services,
                                                                    </li>
                                                                    <li>(vi) enhance our data security and fraud
                                                                        prevention capabilities, and
                                                                    </li>
                                                                    <li>(vii) comply with any applicable laws and
                                                                        regulations, or for any other purpose.
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">3. </strong>
                                                                We may use such Feedback for any purpose without any
                                                                compensation or obligation to you. We reserve the right
                                                                to post a Feedback or remove any Feedback posted in our
                                                                public forums for any reason at our sole discretion.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">4. </strong>
                                                                In addition, you:
                                                                <ul className="ps-3 text-secondary listing some-space">
                                                                    <li>(1) represent and warrant that such Feedback is
                                                                        accurate, complete, and does not infringe on any
                                                                        third-party rights;
                                                                    </li>
                                                                    <li>(2) irrevocably assign to us any right, title,
                                                                        and interest you may have in such Feedback; and
                                                                    </li>
                                                                    <li>(3) explicitly and irrevocably waive any and all
                                                                        claims relating to any past, present, or future
                                                                        moral rights, artists’ rights, or any other
                                                                        similar rights worldwide in or to such Feedback.
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li><strong className="fw-bold text-dark">7. OWNERSHIP AND INTELLECTUAL
                                                    PROPERTY RIGHTS

                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li><strong className="fw-bold text-dark">1. Company’s
                                                            Intellectual Property:
                                                        </strong></li>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li>
                                                                <strong className="fw-bold text-dark">1. </strong>
                                                                All rights, title, and interest in and to the Platform
                                                                and Services, including any and all copyrightable
                                                                materials or any other content thereof which is or may
                                                                be subject to any intellectual property rights under any
                                                                applicable law (including any artwork, graphics, images,
                                                                website templates, and widgets, literary work, source,
                                                                and object code, tools, software, computer code,
                                                                including HTML), applications, audio, music, video and
                                                                other media, designs, animations, interfaces,
                                                                documentation, derivatives and versions thereof, the
                                                                “look and feel” of the Platform or Services, methods,
                                                                products, algorithms, data, interactive features and
                                                                objects, advertising and acquisition tools and methods,
                                                                inventions, trade secrets, logos, domains, customized
                                                                URLs, trademarks, service marks, trade names and other
                                                                proprietary identifiers, whether or not registered
                                                                and/or capable of being registered (collectively,
                                                                “ <strong className="fw-bold text-dark">Company’s
                                                                Intellectual Property</strong>”), and any derivations
                                                                thereof, are owned by and/or licensed to the Company.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">2. </strong>
                                                                You acknowledge that no ownership of the Platform is
                                                                being transferred. All rights, Intellectual Property,
                                                                and interests in the Platform remain the exclusive
                                                                property of the Company, its affiliates, or its
                                                                licensors.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">3. </strong>
                                                                Subject to the provisions of these Terms, we grant you a
                                                                limited, non-exclusive, non-transferable licence to
                                                                access and use our Platform and/or Services strictly for
                                                                its intended purposes. This licence explicitly does not
                                                                confer any ownership rights to you except as otherwise
                                                                stated in this Clause, and any unauthorised use
                                                                constitutes a material breach of these Terms.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">4. </strong>
                                                                All copyright and other intellectual property rights in
                                                                the material on our Platform are reserved.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">5. </strong>
                                                                All intellectual property rights discovered, developed,
                                                                or otherwise coming into existence as a result of, for
                                                                the purposes of, or in connection with, the Platform or
                                                                the provision of any Services will automatically vest in
                                                                and are assigned to us, including any enhancements,
                                                                improvements and modifications to the Intellectual
                                                                Property. You must not represent to anyone or in any
                                                                manner whatsoever that they are the proprietor of the
                                                                Platform and/or Intellectual Property.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">6. </strong>
                                                                We respect the intellectual property rights of others,
                                                                and you are expected to do the same. Uploading, posting,
                                                                or otherwise transmitting any content that infringes on
                                                                the Company's or any third party’s intellectual property
                                                                rights is strictly prohibited. We reserve the right to
                                                                promptly remove any infringing content from our Platform
                                                                and take legal action if necessary.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">7. </strong>
                                                                You are not permitted to reproduce, modify, create
                                                                derivative works, distribute, licence, lease, sell,
                                                                resell, transfer, publicly display, publicly perform,
                                                                transmit, stream, broadcast, or otherwise exploit the
                                                                Platform, and/or Services except as expressly permitted
                                                                by the Company, except as follows:
                                                            </li>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong className="fw-bold text-dark">1. </strong>
                                                                    Your computer/mobile device may store copies of such
                                                                    materials in RAM incidental to your accessing and
                                                                    viewing those materials.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">2. </strong>
                                                                    You may store files that are automatically cached by
                                                                    your web browser/mobile operating systems for
                                                                    display enhancement purposes.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">3. </strong>
                                                                    You may print, take a screenshot of or download a
                                                                    reasonable number of pages of our Platform for your
                                                                    own personal use and not for further reproduction,
                                                                    publication, or distribution.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">4. </strong>
                                                                    If we provide desktop, mobile, or other applications
                                                                    for download, you may download a single copy to your
                                                                    computer or mobile device solely for your own
                                                                    personal, non-commercial use, provided you agree to
                                                                    be bound by our end user licence agreement, if any,
                                                                    for such applications.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">5. </strong>
                                                                    If we provide social media features with certain
                                                                    content, you may take such actions as are enabled by
                                                                    such features.
                                                                </li>
                                                            </ul>
                                                            <li>
                                                                <strong className="fw-bold text-dark">8. </strong>
                                                                You must not:
                                                            </li>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong className="fw-bold text-dark">1. </strong>
                                                                    Modify copies of any materials from this Platform.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">2. </strong>
                                                                    Use any illustrations, photographs, video or audio
                                                                    sequences, or any graphics separately from the
                                                                    accompanying text.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">3. </strong>
                                                                    Delete or alter any copyright, trademark, or other
                                                                    proprietary rights notices from copies of materials
                                                                    from this Platform.
                                                                </li>
                                                            </ul>
                                                            <li>
                                                                <strong className="fw-bold text-dark">9. </strong>
                                                                These Terms do not convey any right or interest in or to
                                                                the Company’s Intellectual Property (or any part
                                                                thereof), except only for the limited license expressly
                                                                granted above. Nothing in these Terms constitutes an
                                                                assignment or waiver of the Company’s Intellectual
                                                                Property rights under any law.

                                                            </li>
                                                        </ul>
                                                        <li><strong className="fw-bold text-dark">2. Use of your
                                                            Intellectual Property:</strong>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li><strong className="fw-bold text-dark">1. </strong>
                                                                    You retain ownership rights to the User Content. By
                                                                    providing us with User Content, you grant us a
                                                                    worldwide, royalty-free, perpetual, irrevocable, and
                                                                    sublicensable right to use such materials for
                                                                    purposes detailed in our Privacy Policy.
                                                                </li>
                                                                <li><strong
                                                                    className="fw-bold text-dark">2. </strong> You
                                                                    acknowledge and agree that if you elect to upload
                                                                    any User Content to our Platform, you:
                                                                </li>
                                                                <ul className="ps-3 text-secondary listing some-space">
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">1. </strong>
                                                                        represent and warrant that you either have the
                                                                        rights in such User Content or have the
                                                                        necessary permission to upload, post, transmit
                                                                        or otherwise make available that User Content
                                                                        via the Platform;
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">2. </strong>
                                                                        are solely responsible for that User Content and
                                                                        that we will not be liable for any loss,
                                                                        expenses, liabilities, costs, or damages that
                                                                        are caused by the information you provide on the
                                                                        Platform; and

                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">3. </strong>
                                                                        you have procured all necessary rights from
                                                                        third parties, which are from time to time
                                                                        required in order for us to be able to provide
                                                                        the Platform or the Services to you.

                                                                    </li>
                                                                </ul>
                                                                <li><strong
                                                                    className="fw-bold text-dark">3. </strong> You
                                                                    further agree that we may refer to you, your name
                                                                    and make reference to you as a customer of ours in
                                                                    any communications or publications for the purposes
                                                                    of marketing or promoting our business provided that
                                                                    any proposed communication or reference is approved
                                                                    in writing in advance by you.
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><strong className="fw-bold text-dark">8. HOW TO REPORT INTELLECTUAL
                                                    PROPERTY INFRINGEMENT? </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1. Copyright
                                                                Infringement Claims:</strong>

                                                        </li>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li>
                                                                <strong className="fw-bold text-dark">1. </strong>
                                                                We respect the intellectual property rights of others
                                                                and are committed to investigating and addressing
                                                                allegations of copyright infringement promptly. We
                                                                provide tools and clear procedures for Users to report
                                                                any content they believe infringes on their copyrights
                                                                or other intellectual property rights.

                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">2. </strong>
                                                                Users agree not to upload or transmit any communications
                                                                or User Content that infringes or violates the rights of
                                                                any party while using the Platform. We do not permit
                                                                infringing materials to remain on the Platform and
                                                                encourage Users to promptly notify us if they believe
                                                                any materials on the Platform, including advertisements
                                                                or linked User Content, infringe third-party copyrights.

                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">3. </strong>
                                                                If you believe that your copyright-protected material is
                                                                being infringed, you may submit a notice (“<strong
                                                                className="fw-bold text-dark">Copyright Infringement
                                                                Notice</strong>”) in compliance with the Copyright Act,
                                                                1957 (“<strong
                                                                className="fw-bold text-dark">Act</strong>”) in India,
                                                                containing the following information-

                                                            </li>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong className="fw-bold text-dark">1. </strong>
                                                                    Identification of your copyrighted work and a
                                                                    description of what is protected under the
                                                                    copyright(s) you are referring to.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">2. </strong>
                                                                    Your copyright certificate(s)/designation(s) and the
                                                                    type, e.g., registered or unregistered.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">3. </strong>
                                                                    Proof of your copyright ownership, such as the
                                                                    registration number or a copy of the registration
                                                                    certificate.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">4. </strong>
                                                                    A short description of how our User(s) allegedly
                                                                    infringe(s) your copyright(s).
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">5. </strong>
                                                                    Clear reference to the materials you allege are
                                                                    infringing and which you are requesting to be
                                                                    removed, for example, the specific URL or link to
                                                                    the content in question.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">6. </strong>
                                                                    Your complete name, address, email address, and
                                                                    telephone number.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">7. </strong>
                                                                    A statement that you have a good faith belief that
                                                                    the use of the material in the manner complained of
                                                                    is not authorized by the copyright owner, its agent,
                                                                    or the law.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">8. </strong>
                                                                    A statement made that the information provided in
                                                                    the notice is accurate and that you are the
                                                                    copyright owner or the owner of an exclusive right
                                                                    that is being infringed, or are authorized to make
                                                                    the complaint on behalf of the copyright owner or
                                                                    the owner of an exclusive right that is being
                                                                    infringed.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">9. </strong>
                                                                    Your electronic or physical signature.
                                                                </li>
                                                            </ul>
                                                            <li>
                                                                <strong className="fw-bold text-dark">4. </strong>
                                                                Upon receipt of a proper Copyright Infringement Notice
                                                                of claimed infringement under the Act, we will respond
                                                                promptly to remove or disable access to the allegedly
                                                                infringing material. We will follow the procedures
                                                                specified in the Act to resolve the claim between the
                                                                notifying party and the alleged infringer responsible
                                                                for the content.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">5. </strong>
                                                                To report copyright infringement issues and send a
                                                                Copyright Infringement Notice, please reach out to us
                                                                at <u>_________________</u>.
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">6. </strong>
                                                                Please note that you may be liable for damages
                                                                (including costs and attorneys’ fees) if you materially
                                                                misrepresent that material is infringing. If we remove
                                                                or disable access, we will attempt to contact the poster
                                                                of the allegedly infringing material so that the poster
                                                                may provide a counter notification (the “Counter
                                                                Notice”) as described in Clause 8.2.
                                                            </li>
                                                        </ul>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2. Counter Notice
                                                                Procedure:
                                                            </strong>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong className="fw-bold text-dark">1. </strong>
                                                                    If you believe that your content was removed or
                                                                    disabled due to a mistake or misidentification, you
                                                                    may file a Counter Notice. A valid Counter Notice
                                                                    must include the following information:

                                                                </li>
                                                                <ul className="ps-3 text-secondary listing some-space">
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">1. </strong>
                                                                        The specific content that was removed or to
                                                                        which access was disabled, including the
                                                                        location where the material appeared before it
                                                                        was removed or disabled.
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">2. </strong>
                                                                        Include a statement under penalty of perjury
                                                                        that you have a good faith belief that the
                                                                        content was removed or disabled as a result of a
                                                                        mistake or misidentification of the material to
                                                                        be removed or disabled.
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">3. </strong>
                                                                        Provide your name, address, and telephone
                                                                        number.
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">4. </strong>
                                                                        State that you consent to the jurisdiction of
                                                                        the courts of India and that you will accept
                                                                        service of process from the person who provided
                                                                        the original notification of infringement.
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">5. </strong>
                                                                        Your physical or electronic signature.
                                                                    </li>

                                                                </ul>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">2. </strong>
                                                                    Submit your Counter-Notice to us at
                                                                    _________________. Once we receive a valid Counter
                                                                    Notice, we will forward it to the party who
                                                                    submitted the original infringement notice. If the
                                                                    original complainant does not file an action seeking
                                                                    a court order against you within 10-14 business
                                                                    days, we may restore the removed content.

                                                                </li>
                                                            </ul>

                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3. Trademark and Other
                                                                Intellectual Property Infringement Claims
                                                            </strong><br/>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong className="fw-bold text-dark">1. </strong>
                                                                    For claims of trademark infringement or other
                                                                    intellectual property infringement on the Platform,
                                                                    please send us an infringement notice (“ <strong
                                                                    className="fw-bold text-dark">Infringement
                                                                    Notice</strong>”) and provide us with the following
                                                                    detailed information to ensure a thorough review and
                                                                    response:
                                                                </li>

                                                                <ul className="ps-3 text-secondary listing some-space">
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">1. </strong>
                                                                        Clearly identify the location of the allegedly
                                                                        infringing content, including the specific URL
                                                                        or link where the work is located.
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">2. </strong>
                                                                        Provide comprehensive information about your
                                                                        trademark or other intellectual property rights,
                                                                        including:
                                                                    </li>
                                                                    <ul className="ps-3 text-secondary listing some-space">
                                                                        <li>
                                                                            <strong
                                                                                className="fw-bold text-dark">1. </strong>
                                                                            The trademarked word(s) or symbol(s).
                                                                        </li>
                                                                        <li>
                                                                            <strong
                                                                                className="fw-bold text-dark">2. </strong>
                                                                            The trademark registration number(s).
                                                                        </li>
                                                                        <li>
                                                                            <strong
                                                                                className="fw-bold text-dark">3. </strong>
                                                                            A direct link to the trademark record(s), if
                                                                            available.

                                                                        </li>

                                                                    </ul>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">3. </strong>
                                                                        Include documentation or evidence that
                                                                        substantiates your ownership of the intellectual
                                                                        property in question. This can be registration
                                                                        certificates, documentation of first use, or
                                                                        other relevant proof.
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">4. </strong>
                                                                        Provide your full contact information, including
                                                                        your name, physical address, email address, and
                                                                        telephone number.
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">5. </strong>
                                                                        Specify your relationship to the trademark
                                                                        holder. Indicate whether you are the trademark
                                                                        holder or an authorized representative of the
                                                                        holder.
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">6. </strong>
                                                                        A statement asserting that you have a good faith
                                                                        belief that the use of the material in the
                                                                        manner complained of is not authorized by the
                                                                        intellectual property owner, its agent, or the
                                                                        law.
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">7. </strong>
                                                                        A declaration made that the information provided
                                                                        in the notice is accurate and that you are the
                                                                        intellectual property owner or authorized to act
                                                                        on behalf of the owner.
                                                                    </li>
                                                                    <li>
                                                                        <strong
                                                                            className="fw-bold text-dark">8. </strong>
                                                                        Your physical or electronic signature.
                                                                    </li>
                                                                </ul>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">2. </strong>
                                                                    To report intellectual property infringement claims,
                                                                    please send an Infringement Notice to
                                                                    _________________.
                                                                </li>
                                                                <ul className="ps-3 text-secondary listing some-space">
                                                                    <li><strong
                                                                        className="fw-bold text-dark">3. </strong>
                                                                        To report intellectual property infringement
                                                                        claims, please send an Infringement Notice to
                                                                        _________________.

                                                                    </li>
                                                                </ul>
                                                            </ul>
                                                        </li>

                                                        <li><strong className="fw-bold text-dark">4. Repeated
                                                            Infringers:
                                                        </strong>

                                                        </li>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li>
                                                                <strong className="fw-bold text-dark">1. </strong>
                                                                In accordance with our Privacy Policy, we reserve the
                                                                right to disable or terminate the accounts of Users who
                                                                are found to be repeat infringers under appropriate
                                                                circumstances.

                                                            </li>

                                                        </ul>


                                                    </ul>

                                                </li>
                                                <li><strong className="fw-bold text-dark">9. WHAT CAN YOU DO AND WHAT IS
                                                    A NO-GO WHEN USING OUR PLATFORM?

                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1. </strong>
                                                            Subject to compliance with these Terms, including
                                                            the <strong>'Prohibited Uses'</strong> defined in Clause 9.6
                                                            below, we grant you a non-exclusive, non-transferable,
                                                            revocable license to:
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li><strong
                                                                    className="fw-bold text-dark">1. </strong> access
                                                                    our Platform and/or Services; and
                                                                </li>
                                                                <li><strong
                                                                    className="fw-bold text-dark">2. </strong> download
                                                                    the User Content or any part of it, provided by them
                                                                    for utilizing our Platform and/or Services.
                                                                </li>
                                                            </ul>
                                                        </li>

                                                        <li>
                                                            <strong className="fw-bold text-dark">2. </strong>
                                                            Subject to compliance with these Terms, we may offer to
                                                            provide the Services as more comprehensively described on
                                                            the Platform. These Services, selected by you, are intended
                                                            solely for your own use and are not to be used or exploited
                                                            for the benefit of any third party.
                                                        </li>

                                                        <li>
                                                            <strong className="fw-bold text-dark">3. </strong>
                                                            The provision of Services will be carried out on a
                                                            best-efforts basis. We do not provide any warranties for the
                                                            continuous availability or the accuracy of any content or
                                                            User Content.
                                                        </li>

                                                        <li>
                                                            <strong className="fw-bold text-dark">4. </strong>
                                                            You agree to be polite and respectful when you communicate
                                                            or interact with others on the Platform or otherwise.
                                                            Communication must remain professional and purpose-driven.
                                                            Users are prohibited from using the Platform for spamming,
                                                            harassment, or unauthorized solicitation.
                                                        </li>

                                                        <li>
                                                            <strong className="fw-bold text-dark">5. </strong>
                                                            You agree to not compel others to create an Account, leave a
                                                            review, or otherwise interact with a third-party website,
                                                            application or service unless authorized by us.
                                                        </li>

                                                        <li>
                                                            <strong className="fw-bold text-dark">6. Prohibited
                                                                Uses:</strong>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li><strong
                                                                    className="fw-bold text-dark">1. </strong> While
                                                                    using our Platform and/or Services, you consent to
                                                                    abstain from activities that contravene these Terms,
                                                                    encompassing fraudulent acts, spamming, hacking, and
                                                                    any actions that could disrupt the operations of our
                                                                    Platform.
                                                                </li>
                                                                <li><strong
                                                                    className="fw-bold text-dark">2. </strong> While
                                                                    using our Platform, you are strictly prohibited from
                                                                    assuming the identity of others indulging in any
                                                                    conduct that may falsely represent their identity.
                                                                </li>
                                                                <li><strong
                                                                    className="fw-bold text-dark">3. </strong> While
                                                                    using our Platform, you are strictly prohibited from
                                                                    purchasing search engines or other pay per click
                                                                    keywords (such as Google AdWords), or domain names
                                                                    that use the name Skill Arbitrage or Skill
                                                                    Arbitrage's trademarks and/or variations and
                                                                    misspellings thereof.
                                                                </li>
                                                                <li><strong
                                                                    className="fw-bold text-dark">4. </strong> You shall
                                                                    not edit or otherwise modify any material on our
                                                                    Platform unless you own or control the relevant
                                                                    information.
                                                                </li>
                                                                <li><strong
                                                                    className="fw-bold text-dark">5. </strong> You are
                                                                    prohibited from probing, scanning, or testing the
                                                                    vulnerability of our Platform without our
                                                                    permission.
                                                                </li>
                                                                <li><strong
                                                                    className="fw-bold text-dark">6. </strong> You agree
                                                                    not to submit, transmit, or display any User
                                                                    Content, or use in a context, that may be deemed as
                                                                    defamatory, libelous, obscene, harassing,
                                                                    threatening, incendiary, abusive, racist, offensive,
                                                                    deceptive, or fraudulent, encouraging criminal or
                                                                    harmful conduct, or which otherwise violates the
                                                                    rights of our Platform or any third party (including
                                                                    any intellectual property rights, privacy rights,
                                                                    contractual or fiduciary rights), or otherwise shows
                                                                    any person, entity or brand in a bad or disparaging
                                                                    light, without their prior explicit approval.
                                                                </li>
                                                                <li><strong
                                                                    className="fw-bold text-dark">7. </strong> You agree
                                                                    not to publish and/or make any use of our Platform
                                                                    and/or Services on any website, media, network, or
                                                                    system other than those provided by the Company
                                                                    and/or frame, “deep link”, “page-scrape”, mirror,
                                                                    and/or create a browser or border environment around
                                                                    any of the Services, our Platform (or any part
                                                                    thereof), except as expressly permitted by the
                                                                    Company, in advance and in writing.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">8.</strong>
                                                                    You are prohibited from using our Platform to copy,
                                                                    store, host, transmit, send, use, publish, or
                                                                    distribute
                                                                    any material that consists of or is linked to any
                                                                    spyware, computer virus, Trojan horse, worm,
                                                                    keystroke
                                                                    logger, rootkit, or other malicious computer
                                                                    software.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">9.</strong>
                                                                    You are prohibited from decrypting or deciphering
                                                                    any
                                                                    communications sent by or to our Platform without
                                                                    our
                                                                    permission.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">10.</strong>
                                                                    You shall not conduct any systematic or automated
                                                                    data
                                                                    collection activities, which include without
                                                                    limitation
                                                                    scraping, data mining, data extraction, and data
                                                                    harvesting on or in relation to our Platform without
                                                                    our
                                                                    express written consent.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">11.</strong>
                                                                    You shall not use our Platform, Services or User
                                                                    Content
                                                                    or any part thereof except by means of the public
                                                                    interfaces.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">12.</strong>
                                                                    You shall not use data collected from our Platform
                                                                    for
                                                                    any direct marketing activity, including without
                                                                    limitation email marketing, SMS marketing,
                                                                    telemarketing, and direct mailing.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">13.</strong>
                                                                    You agree not to remove or alter any copyright
                                                                    notices,
                                                                    watermarks, restrictions, and signs indicating
                                                                    proprietary rights of any of our licensors,
                                                                    including
                                                                    copyright mark [©], creative commons [(cc)]
                                                                    indicators,
                                                                    or trademarks [® or ™] contained in or accompanying
                                                                    the
                                                                    Services and/or Platform.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">14.</strong>
                                                                    You must not do anything that interferes with the
                                                                    normal
                                                                    use of our Platform and/or Services.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">15.</strong>
                                                                    You must not systematically retrieve Personal
                                                                    Information, data or other content from our Platform
                                                                    to
                                                                    create or compile, directly or indirectly, a
                                                                    collection,
                                                                    compilation, database, or directory without written
                                                                    permission from us.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">16.</strong>
                                                                    You must not engage in any automated use of the
                                                                    system,
                                                                    such as using scripts to send comments or messages
                                                                    or
                                                                    using any data mining, robots, or similar data
                                                                    gathering
                                                                    and extraction tools.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">17.</strong>
                                                                    You are prohibited from copying, modifying, creating
                                                                    derivative works of, downloading, adapting, reverse
                                                                    engineering, emulating, migrating to another
                                                                    service,
                                                                    translating, compiling, decompiling, or
                                                                    disassembling
                                                                    our Platform and the Services (or any part thereof),
                                                                    any
                                                                    content offered by our Platform or third party
                                                                    services
                                                                    for use and/or any part thereof in any way, or
                                                                    publicly
                                                                    display, perform, transmit or distribute any of the
                                                                    foregoing without our prior written and specific
                                                                    consent
                                                                    and/or as expressly permitted under these Terms.
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">7. Additional
                                                                activities that are prohibited:</strong>
                                                            <p>You may not access or use our Platform and/or our
                                                                Services for any purpose other than that for which we
                                                                make the Services available. The Services may not be
                                                                used in connection with any commercial endeavours except
                                                                those that are specifically endorsed or approved by us.
                                                            </p>
                                                            <p>The Users of our Platform agree not to:
                                                            </p>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong className="fw-bold text-dark">1.</strong>
                                                                    Trick, defraud, or mislead us and other Users,
                                                                    especially in any attempt to learn sensitive
                                                                    information;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">2.</strong>
                                                                    Circumvent, disable, or otherwise interfere with
                                                                    security-related features of our Platform, including
                                                                    features that prevent or restrict the use or copying
                                                                    of
                                                                    any User Content or enforce limitations on the use
                                                                    of
                                                                    our Platform, Services and/or the User Content
                                                                    contained
                                                                    therein;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">3.</strong>
                                                                    Disparage, tarnish, or otherwise harm, in our
                                                                    opinion,
                                                                    us and/or the Services.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">4.</strong>
                                                                    Use any information obtained from our Platform in
                                                                    order
                                                                    to harass, abuse, or harm another person;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">5.</strong>
                                                                    Make improper use of our Platform and/or Services or
                                                                    submit false reports of abuse or misconduct;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">6.</strong>
                                                                    Use the Services in a manner inconsistent with any
                                                                    Applicable Laws or regulations;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">7.</strong>
                                                                    Engage in unauthorized framing of or linking to the
                                                                    Services;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">8.</strong>
                                                                    Upload or transmit (or attempt to upload or to
                                                                    transmit)
                                                                    viruses, Trojan horses, or other material, including
                                                                    excessive use of capital letters and spamming
                                                                    (continuous posting of repetitive text), that
                                                                    interferes
                                                                    with any person’s uninterrupted use and enjoyment of
                                                                    our
                                                                    Platform and/or Services or modifies, impairs,
                                                                    disrupts,
                                                                    alters, or interferes with the use, features,
                                                                    functions,
                                                                    operation, or maintenance of our Platform and/or
                                                                    Services;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">9.</strong>
                                                                    Attempt to impersonate another User or person or use
                                                                    the
                                                                    username of another User;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">10.</strong>
                                                                    Upload or transmit (or attempt to upload or to
                                                                    transmit)
                                                                    any material that acts as a passive or active
                                                                    information collection or transmission mechanism,
                                                                    including, without limitation, clear graphics
                                                                    interchange formats ('gifs'), 1×1 pixels, web bugs,
                                                                    cookies, or other similar devices (sometimes
                                                                    referred to
                                                                    as 'spyware' or 'passive collection mechanisms' or
                                                                    'pcms');
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">11.</strong>
                                                                    Interfere with, disrupt, or create an undue burden
                                                                    on
                                                                    our Platform or the networks or services connected
                                                                    to
                                                                    them;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">12.</strong>
                                                                    Harass, annoy, intimidate, or threaten any of our
                                                                    employees or agents engaged in providing any portion
                                                                    of
                                                                    the Services to you;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">13.</strong>
                                                                    Attempt to bypass any measures of our Platform
                                                                    and/or
                                                                    Services designed to prevent or restrict access to
                                                                    our
                                                                    Platform and/or Services, or any portion thereof;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">14.</strong>
                                                                    Copy or adapt our Platform’s software, including but
                                                                    not
                                                                    limited to flash, PHP, HTML, JavaScript, or other
                                                                    code;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">15.</strong>
                                                                    Except as may be the result of standard search
                                                                    engine or
                                                                    Internet browser usage, use, launch, develop, or
                                                                    distribute any automated system, including, without
                                                                    limitation, any spider, robot, cheat utility,
                                                                    scraper,
                                                                    or offline reader that accesses our Platform, or use
                                                                    or
                                                                    launch any unauthorized script or other software;
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">16.</strong>
                                                                    Make any unauthorized use of our Platform and/or
                                                                    Services, including collecting email addresses of
                                                                    Users
                                                                    by electronic or other means for the purpose of
                                                                    sending
                                                                    unsolicited emails by automated means or under false
                                                                    pretenses.
                                                                </li>
                                                            </ul>
                                                            <p>You acknowledge and agree that your failure to abide by
                                                                any of the foregoing or any misrepresentation made by
                                                                you herein may result in the immediate termination of
                                                                your User Account or your access to our Platform and/or
                                                                any Services provided to you – with or without further
                                                                notice to you, and without any refund of amounts paid on
                                                                account of any such Services.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><strong className="fw-bold text-dark">10. USER REPRESENTATIONS


                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li><strong className="fw-bold text-dark">1. </strong> By using
                                                            our Platform and/or Services, you represent and warrant
                                                            that:
                                                        </li>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li>
                                                                <strong className="fw-bold text-dark">1. </strong>
                                                                All the information submitted on our Platform will be
                                                                true,
                                                                accurate, current, and complete;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">2. </strong>
                                                                You will maintain the accuracy of such information and
                                                                promptly update such information as necessary;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">3. </strong>
                                                                You have the legal capacity and you agree to comply with
                                                                these Terms. You are not a minor in the jurisdiction in
                                                                which you reside or have apt parental consent to utilize
                                                                the
                                                                Platform and Services, and are responsible for adhering
                                                                to
                                                                India’s laws when accessing our Platform and utilizing
                                                                our
                                                                Services, given that the Company is located in India;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">4. </strong>
                                                                The use of our Platform and/or Services shall be solely
                                                                for
                                                                the purposes of professional use;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">5. </strong>
                                                                You will not misrepresent or embellish the relationship
                                                                between the Company and Users (including by expressing
                                                                or
                                                                implying that we support, sponsor, endorse, or
                                                                contribute to
                                                                them or their business endeavours). You will not imply
                                                                any
                                                                relationship or affiliation between the Company and
                                                                Users;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">6. </strong>
                                                                You will not use our Platform and/or Services for any
                                                                illegal or unauthorized purpose.
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li><strong className="fw-bold text-dark">11. IS THE COMPANY RESPONSIBLE
                                                    FOR THIRD-PARTY WEBSITE INTEGRATIONS INTO THE PLATFORM?
                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1.</strong>
                                                            You understand and acknowledge that we provide various
                                                            tools,
                                                            software and integration services to our Users through
                                                            third-party service providers on our Platform for your
                                                            convenience. You acknowledge that in integrating such tools
                                                            and
                                                            integration services, our Platform collaborates with the
                                                            related
                                                            third-party service providers, and the Company in itself is
                                                            not
                                                            the distributor or retailer.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2.</strong>
                                                            You understand that to make use of tools and integration
                                                            services, they may need to access the third-party website
                                                            links
                                                            provided by us on our Platform. You will then be redirected
                                                            to
                                                            the website of such third-party service providers. You
                                                            hereby
                                                            acknowledge that when they access third-party websites, they
                                                            do
                                                            so at their own risk.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3.</strong>
                                                            You may be notified when you are leaving our Platform and
                                                            redirected to access a third-party site or service.
                                                            Additionally, we are not responsible for any content,
                                                            advertisements, products, or other materials available on
                                                            external sites or through integrated services.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">4.</strong>
                                                            You understand that external sites are beyond our control,
                                                            and
                                                            we are not accountable for the content, functionality,
                                                            accuracy,
                                                            legality, appropriateness, or any other aspect of such
                                                            external
                                                            websites or resources. The inclusion of any link does not
                                                            imply
                                                            endorsement or sponsorship by us or any association with its
                                                            operators.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">5.</strong>
                                                            You acknowledge and agree that any issues or non-performance
                                                            of
                                                            such services will be addressed on a best-effort basis,
                                                            subject
                                                            to the pass-through obligations as agreed with the
                                                            external/third-party service providers. You further
                                                            understand
                                                            that any charges or obligations you incur in your dealings
                                                            with
                                                            these third-party sites are your responsibility.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">6.</strong>
                                                            You acknowledge that the linked third-party sites are not
                                                            under
                                                            our control and may collect data or solicit personal
                                                            information
                                                            from you, or they may automatically collect information from
                                                            you. You are advised to exercise caution when navigating
                                                            external websites and using the tools and integration
                                                            services
                                                            and carefully review the terms and conditions and privacy
                                                            policies of all off-website pages and other websites that
                                                            they
                                                            visit.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">7.</strong>
                                                            We reserve the right to modify or remove links to
                                                            third-party
                                                            websites and services at any time without notice. Continued
                                                            use
                                                            of our Platform constitutes acceptance of any modifications
                                                            to
                                                            the links provided.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">8.</strong>
                                                            You acknowledge that we collaborate with external or
                                                            third-party
                                                            service providers to enhance the user experience. Any
                                                            collaborative efforts aim to improve services but are
                                                            subject to
                                                            the terms and conditions agreed upon with the respective
                                                            service
                                                            providers.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><strong className="fw-bold text-dark ">12. IS USER INFORMATION
                                                    COLLECTED SECURED?

                                                </strong>
                                                    <p>Please take a moment to review our Privacy Policy, which governs
                                                        not only your visit to the Platform but also details the terms
                                                        related to the collection of information from you, security
                                                        measures, access to your data, and the transfer of your
                                                        information. Rest assured, any personal information or data you
                                                        share with us while using the Platform is treated with the
                                                        utmost confidentiality and is strictly handled in accordance
                                                        with our Privacy Policy, as well as applicable laws and
                                                        regulations. If you have any objections to the transfer or use
                                                        of your information, we kindly advise against using the
                                                        Platform. Your privacy and trust are of utmost importance to us,
                                                        and we strive to maintain the highest standards of data
                                                        protection and security.</p>
                                                    <p>You agree that by using our Platform and/or Service, you hereby
                                                        agree to comply with all applicable data protection laws and
                                                        other security and regulatory compliances in respect of any
                                                        information that you make available on the Platform or otherwise
                                                        collect from your Customers. You are responsible for protecting
                                                        all Personal Information that you receive from or are provided
                                                        with in connection with your use of the Platform, or any of the
                                                        Services.
                                                    </p>
                                                </li>
                                                <li><strong className="fw-bold text-dark ">13. IS THE PLATFORM AVAILABLE
                                                    24/7? </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1.</strong>
                                                            While we do our best to keep the Platform up and running all
                                                            the time, we can't promise it will always be perfect.
                                                            Sometimes, there might be interruptions, delays, or errors,
                                                            or the Platform might not be free of viruses. If there are
                                                            any problems, we'll try our best to fix them as soon as
                                                            possible.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2.</strong>
                                                            You need the internet to use the Platform, and you shall
                                                            have to cover the costs for that. We won't be responsible
                                                            for those costs.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3.</strong>
                                                            Our Platform might not work with every device, operating
                                                            system or software out there, and sometimes we will need to
                                                            update it, which might make some parts temporarily
                                                            unavailable.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">4.</strong>
                                                            We're not liable for any business losses or other indirect
                                                            losses you might experience while using our Platform.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">5.</strong>
                                                            You are responsible for having the proper internet
                                                            connection and devices to use our Platform. If you use
                                                            wireless devices, you might have to pay extra fees for the
                                                            mobile network or Wi-Fi. And while we try to make sure our
                                                            Platform works on most devices, we can't guarantee they'll
                                                            work perfectly on every single one.
                                                        </li>
                                                    </ul>


                                                </li>

                                                <li><strong className="fw-bold text-dark ">14. UNDER WHAT CIRCUMSTANCES
                                                    MAY MODIFICATIONS AND INTERRUPTIONS OCCUR ON THE PLATFORM?

                                                </strong>
                                                    <p>We reserve the right to change, modify, or remove the contents
                                                        from the Platform and/or Services at any time or for any reason
                                                        at our sole discretion without notice. However, we have no
                                                        obligation to update any information on our Platform. We cannot
                                                        guarantee that the Services will be available at all times. We
                                                        may experience hardware, software, or other problems or need to
                                                        perform maintenance related to our Platform, resulting in
                                                        interruptions, delays, or errors. We reserve the right to
                                                        change, revise, update, suspend, discontinue, or otherwise
                                                        modify the Services at any time or for any reason without any
                                                        notice to you. You agree that we have no liability whatsoever
                                                        for any loss, damage, or inconvenience caused by your inability
                                                        to access or use the Platform and/or Services during any
                                                        downtime or discontinuance of the Platform. Nothing in these
                                                        Terms will be construed to obligate us to maintain and support
                                                        the Platform or to supply any corrections, updates, or releases
                                                        in connection therewith.
                                                    </p>
                                                </li>

                                                <li><strong className="fw-bold text-dark ">15. UNDER WHAT CIRCUMSTANCES
                                                    MAY ACCESS TO THE PLATFORM AND/OR SERVICES BE TERMINATED?

                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1.</strong>
                                                            These Terms shall remain in full force and effect while you
                                                            use
                                                            the Platform and/or Services. WITHOUT LIMITING ANY OTHER
                                                            PROVISION OF THESE TERMS, WE RESERVE THE RIGHT TO, IN OUR
                                                            SOLE
                                                            DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO
                                                            AND
                                                            USE OF THE SERVICES AND/OR PLATFORM (INCLUDING BLOCKING
                                                            CERTAIN
                                                            IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
                                                            REASON,
                                                            INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                                                            REPRESENTATION,
                                                            WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OR OF ANY
                                                            APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
                                                            PARTICIPATION IN THE SERVICES AND ANY CONTENT OR INFORMATION
                                                            THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                                                            DISCRETION.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2.</strong>
                                                            We reserve the right to deny access to the Platform,
                                                            terminate
                                                            Accounts, remove content, or disable any features or
                                                            identifiers
                                                            (whether chosen by you or assigned by us) at any time, if,
                                                            in
                                                            our judgement, any information you provide or actions you
                                                            take
                                                            violate the Platform policies, applicable laws, or
                                                            governmental
                                                            policies.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3.</strong>
                                                            If we terminate or suspend your Account or your access to
                                                            the
                                                            Platform and/or Services for any reason, you are prohibited
                                                            from
                                                            registering or creating a new account under your name, a
                                                            fake or
                                                            borrowed name, or the name of any third party, regardless of
                                                            whether you act on their behalf. In addition to suspension
                                                            or
                                                            termination, we reserve the right to pursue appropriate
                                                            legal
                                                            action, including civil, criminal, and injunctive remedies.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">4.</strong>
                                                            Upon termination of your Account and/or access, you must
                                                            immediately discontinue use of the Platform and Services.
                                                            All
                                                            licenses granted to you under these Terms automatically
                                                            terminate and you shall automatically forfeit the right to
                                                            use
                                                            the Platform.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">5.</strong>
                                                            We may terminate these Terms by providing written notice to
                                                            you
                                                            if:
                                                        </li>
                                                    </ul>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1.</strong>
                                                            you fail to remedy a material breach of these Terms within
                                                            seven (7) days of being notified of the breach;
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2.</strong>
                                                            you become bankrupt, enter a voluntary arrangement, is in
                                                            liquidation or receivership, ceases business, threatens to
                                                            cease business, or are otherwise insolvent.

                                                        </li>

                                                    </ul>
                                                    <li>
                                                        <strong className="fw-bold text-dark">6. </strong>
                                                        You have the liberty to terminate your Account and/or stop
                                                        utilizing the Platform and the Services whenever you wish by
                                                        adhering to the instructions that are clearly outlined on the
                                                        Platform.

                                                    </li>
                                                    <li>
                                                        <strong className="fw-bold text-dark">7. </strong>
                                                        Clauses including Indemnity, Disclaimer, and Limitation of
                                                        Liability, shall survive termination of these Terms.

                                                    </li>
                                                </li>

                                                <li><strong className="fw-bold text-dark ">16. WARRANTIES AND DISCLAIMER


                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1.</strong>
                                                            THE PLATFORM AND SERVICES ARE PROVIDED ON AN AS-IS AND
                                                            AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE PLATFORM
                                                            AND
                                                            SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT
                                                            PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
                                                            IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
                                                            THEREOF,
                                                            INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                                                            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                                                            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
                                                            ABOUT
                                                            THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE
                                                            CONTENT OF ANY PLATFORMS OR MOBILE APPLICATIONS LINKED TO
                                                            THE
                                                            SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY
                                                            FOR
                                                            ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                                                            MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
                                                            NATURE
                                                            WHATSOEVER, RESULTING FROM USER ACCESS TO AND USE OF THE
                                                            SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR
                                                            SECURE
                                                            SERVERS AND/OR ANY AND ALL INFORMATION AND/OR FINANCIAL
                                                            INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
                                                            CESSATION OF
                                                            TRANSMISSION TO OR FROM THE SERVICES, AND (5) ANY BUGS,
                                                            VIRUSES,
                                                            TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                                                            THROUGH THE SERVICES BY ANY THIRD PARTY. WE WILL NOT BE A
                                                            PARTY
                                                            TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
                                                            TRANSACTION
                                                            BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
                                                            SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
                                                            THROUGH
                                                            ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                                                            JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2.</strong>
                                                            Users understand and agree that the Platform acts solely as
                                                            an
                                                            intermediary between Partners and service providers such as
                                                            hotels, airlines, transport companies. The Company does not
                                                            provide travel services directly and is not liable for the
                                                            performance, quality, availability, or accuracy of the
                                                            services
                                                            offered by such third parties...
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3.</strong>
                                                            Users acknowledge and agree that the Platform displays
                                                            travel-related services based on data provided by
                                                            third-party
                                                            suppliers. Prices, availability, and descriptions may change
                                                            without prior notice...
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">4.</strong>
                                                            Partners are responsible for complying with all applicable
                                                            travel laws, licensing requirements, and industry
                                                            regulations
                                                            (e.g., IATA accreditation, ATOL certification)...
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">5.</strong>
                                                            We make no guarantees, representations, or warranties that
                                                            your
                                                            use of the Platform will result in increased bookings,
                                                            revenue
                                                            generation, business growth, or any specific financial or
                                                            commercial outcome...
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">6.</strong>
                                                            The Platform does not endorse, certify, or verify the
                                                            legitimacy
                                                            of any Partner, travel supplier, or third-party service
                                                            provider
                                                            listed on the Platform...
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">7.</strong>
                                                            You must exercise caution when interacting with individuals
                                                            attempting to contact you via social media, phone, email, or
                                                            any
                                                            other electronic medium outside of the official
                                                            communication
                                                            channels of the Platform...
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">8.</strong>
                                                            You understand and agree that utilizing Services and
                                                            engaging
                                                            with third-party services through the Platform involves
                                                            inherent
                                                            risks...
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">9.</strong>
                                                            Except as provided in these Terms, no further warranty,
                                                            condition, undertaking, or term, express or implied,
                                                            statutory
                                                            or otherwise as to the condition, quality, performance, or
                                                            fitness for the purpose of the Platform provided in
                                                            accordance
                                                            with these Terms is given by us, other than as required by
                                                            law...
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">10.</strong>
                                                            We do not recommend the use of the Platform for hosting
                                                            personal
                                                            content and shall not bear any security or integrity
                                                            obligations
                                                            or risks regarding breach or damage to any such content.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><strong className="fw-bold text-dark ">17. LIMITATION OF LIABILITY


                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1. </strong>
                                                            We bear no responsibility for any secondary, resultant, or
                                                            incidental harm that may occur due to the utilization or
                                                            inability to utilize the Platform and/or Services.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2. </strong>
                                                            In no event will we or our directors, employees, or agents
                                                            be liable to you or any third party for any direct,
                                                            indirect, consequential, exemplary, incidental, special, or
                                                            punitive damages, including lost profit, lost revenue, loss
                                                            of data, or other damages arising from your use of the
                                                            Platform and/or Services, even if we have been advised of
                                                            the possibility of such damages.

                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3. </strong>
                                                            We shall not be liable for:

                                                        </li>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li>
                                                                <strong className="fw-bold text-dark">1. </strong>
                                                                unsuccessful transactions, delays, or financial losses
                                                                due to events beyond our control or third-party
                                                                services;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">2. </strong>
                                                                the Platform, not meeting individual requirements,
                                                                containing defects, or causing damage due to cyber
                                                                attacks or other breaches beyond our control;

                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">3. </strong>
                                                                internet transmissions not being entirely private or
                                                                secure; messages may be read by others; and/or

                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">4. </strong>
                                                                under various legal theories, we shall not be liable for
                                                                loss of profits, revenue, goodwill, or any indirect or
                                                                consequential damages arising from the use of the
                                                                Platform and/or Services. You should be aware of
                                                                potential risks in internet transmissions.

                                                            </li>
                                                        </ul>
                                                        <li>
                                                            <strong className="fw-bold text-dark">4. </strong>
                                                            You acknowledge and agree that these limitations of
                                                            liability are agreed allocations of risk constituting in
                                                            part the consideration for our Services to you, and such
                                                            limitations will apply even if we have been advised of the
                                                            possibility of such liabilities.


                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">5. </strong>
                                                            You further agree that neither party shall be liable to the
                                                            other party for lost profit, lost revenue, incidental,
                                                            indirect, consequential, special, or punitive damages,
                                                            except for either party’s (i) indemnification obligations,
                                                            (ii) confidentiality obligations, (iii) breach of applicable
                                                            laws, (iv) fraud, gross negligence and/or willful
                                                            misconduct.


                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">6. Limitation of
                                                                Liability for Data Loss:
                                                            </strong><br/>
                                                            <ul className="ps-3 text-secondary listing some-space">
                                                                <li>
                                                                    <strong className="fw-bold text-dark">1.</strong>
                                                                    You acknowledge and agree that, to the extent
                                                                    permitted
                                                                    by applicable law, the Company shall not be held
                                                                    liable
                                                                    for any loss, corruption, or unauthorized access to
                                                                    User
                                                                    data, including but not limited to any User Content
                                                                    uploaded, submitted, or transmitted through our
                                                                    Platform
                                                                    and/or Services. We implement robust security
                                                                    measures
                                                                    to protect data; however, we cannot guarantee
                                                                    absolute
                                                                    security.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">2.</strong>
                                                                    In the event of data loss, corruption, or breach,
                                                                    the
                                                                    Company will take reasonable steps to restore data
                                                                    from
                                                                    the latest backup. Users understand and agree that
                                                                    it is
                                                                    their responsibility to maintain adequate backups of
                                                                    their data. The Company disclaims any liability for
                                                                    any
                                                                    direct, indirect, incidental, special,
                                                                    consequential, or
                                                                    punitive damages arising out of or related to data
                                                                    loss,
                                                                    regardless of whether such damages were foreseeable
                                                                    and
                                                                    whether or not the Company has been advised of the
                                                                    possibility of such damages.
                                                                </li>
                                                                <li>
                                                                    <strong className="fw-bold text-dark">3.</strong>
                                                                    By using our Platform and/or Services, you expressly
                                                                    release the Company from any liability for data loss
                                                                    and
                                                                    agree to indemnify and hold us harmless from any
                                                                    claims,
                                                                    damages, or losses resulting from data loss.
                                                                </li>
                                                            </ul>
                                                            <li><strong className="fw-bold text-dark">7. </strong> Some
                                                                jurisdictions do not allow the exclusion of certain
                                                                warranties or the limitation or exclusion of liability
                                                                for incidental or consequential damages. Accordingly,
                                                                some of the above limitations may not apply to you. In
                                                                these jurisdictions, Company’s liability will be limited
                                                                to the greatest extent permitted by law.
                                                            </li>

                                                        </li>
                                                    </ul>

                                                </li>

                                                <li><strong className="fw-bold text-dark ">18. INDEMNIFICATION


                                                </strong>
                                                    <p>You are solely and exclusively responsible for the utilization of
                                                        our Platform and/or Services:
                                                    </p>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li><strong className="fw-bold text-dark ">1. </strong> You
                                                            agree to defend, indemnify, and hold us harmless, including
                                                            our subsidiaries, affiliates, and all of our respective
                                                            officers, agents, partners, and employees, from and against
                                                            any loss, damage, liability, claim, or demand received or
                                                            suffered by us, including reasonable attorneys' fees and
                                                            expenses, made by any third party due to or arising out of:
                                                        </li>
                                                        <ul className="ps-3 text-secondary listing some-space">
                                                            <li>
                                                                <strong className="fw-bold text-dark">1.</strong>
                                                                Any User Content, or other material submitted, shared or
                                                                displayed by you;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">2.</strong>
                                                                Your use of the Platform, Services, including but not
                                                                limited to booking, listing, or engaging in transactions
                                                                facilitated through the Platform, and/or User Content;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">3.</strong>
                                                                Breach of these Terms, including but not limited to any
                                                                violation of applicable policies, misuse of Platform
                                                                features, code of conduct, or payment obligations, our
                                                                Privacy Policy, Cancellation and Returns Policy or any
                                                                terms outlined in other policies;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">4.</strong>
                                                                Any disputes, inaccuracies, misrepresentations, or data
                                                                discrepancies arising from bookings, cancellations,
                                                                payments, or interactions with travel service providers
                                                                and Customers;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">5.</strong>
                                                                Any claims arising from travel services booked through
                                                                the Platform, including service failures, delays,
                                                                cancellations, or any dissatisfaction with third-party
                                                                services;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">6.</strong>
                                                                Any disputes, errors, data discrepancies, or technical
                                                                failures related to third-party platforms;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">7.</strong>
                                                                Any breach of User representations and warranties set
                                                                forth in these Terms;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">8.</strong>
                                                                Your violation of the rights of a third party, including
                                                                but not limited to intellectual property rights,
                                                                privacy, or contractual rights;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">9.</strong>
                                                                Any unauthorized access, fraudulent activities, data
                                                                scraping, or other deceptive or harmful conduct
                                                                involving the Platform;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">10.</strong>
                                                                Any overt harmful act toward any other User of our
                                                                Platform with whom User connected via our Platform;
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">11.</strong>
                                                                Any chargebacks, fraudulent transactions, or payment
                                                                disputes initiated by you; or
                                                            </li>
                                                            <li>
                                                                <strong className="fw-bold text-dark">12.</strong>
                                                                Any inaccuracies or errors in the results provided by
                                                                the Platform.
                                                            </li>
                                                        </ul>
                                                        <p>Notwithstanding the foregoing, we reserve the right, at your
                                                            expense, to assume the exclusive defense and control of any
                                                            matter for which you are required to indemnify us, and you
                                                            agree to cooperate, at your expense, with our defense of
                                                            such claims. We will use reasonable efforts to notify you of
                                                            any such claim, action, or proceeding that is subject to
                                                            this indemnification upon becoming aware of it.</p>
                                                    </ul>
                                                </li>
                                                <li><strong className="fw-bold text-dark ">19. DISPUTE RESOLUTION AND
                                                    GOVERNING LAW
                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1.</strong>
                                                            You expressly acknowledge and agree that we shall have the
                                                            right
                                                            to enforce these Terms against them.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2.</strong>
                                                            These Terms shall be construed in accordance with the
                                                            applicable
                                                            laws of India.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3.</strong>
                                                            In the event, a dispute or difference arises in connection
                                                            with
                                                            (i) these Terms, our Privacy Policy and/or Cancellation and
                                                            Refund Policy, and (ii) your access to or use of our
                                                            Platform
                                                            and/or Services, the parties to the dispute shall attempt in
                                                            the
                                                            first instance to amicably resolve such dispute through
                                                            mutual
                                                            consultations.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">4.</strong>
                                                            If the Parties fail to resolve the Dispute by mutual
                                                            negotiation
                                                            and consultation within a period of 30 days, you agree to
                                                            submit
                                                            the dispute to the Arbitration for determination
                                                            administered by
                                                            the<strong className="fw-bold text-dark"> Centre for Online
                                                            Resolution of Disputes (CORD)</strong> (<a
                                                            href="https://www.resolveoncord.com"
                                                            target="_blank">www.resolveoncord.com</a>) in accordance
                                                            with
                                                            the Rules of Arbitration of CORD, prevailing as on the date
                                                            of
                                                            initiating arbitration. The arbitral tribunal shall consist
                                                            of a
                                                            sole arbitrator who shall be appointed by CORD in accordance
                                                            with its Rules. The decision of the arbitrator shall be
                                                            final.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">5.</strong>
                                                            The seat of arbitration shall be New Delhi, India. The
                                                            language
                                                            of the arbitration shall be English.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">6.</strong>
                                                            In the event that CORD is unable to assign an Arbitrator or
                                                            such
                                                            Arbitrator is unable to initiate the proceedings within a
                                                            period
                                                            of 60 days from the date of notice of the arbitration or in
                                                            the
                                                            event that there is no hearing conducted by the Arbitrator
                                                            within a period of 90 days of the commencement of the
                                                            arbitration proceedings, the Parties agree to submit to the
                                                            jurisdiction of the competent courts at New Delhi, India for
                                                            the
                                                            resolution of the disputes.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">7.</strong>
                                                            Notwithstanding the foregoing provision, you agree that we
                                                            have
                                                            the right to bring any proceedings before any court/forum of
                                                            competent jurisdiction and you irrevocably submit to the
                                                            jurisdiction of such courts or forum. Any proceeding brought
                                                            by
                                                            you shall be exclusively before the courts in New Delhi,
                                                            India.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><strong className="fw-bold text-dark mt-2">20. NO CLASS ACTIONS
                                                </strong>
                                                    <p>UNLESS OTHERWISE PROHIBITED BY APPLICABLE LAW IN YOUR
                                                        JURISDICTION, YOU AGREE THAT YOU MAY BRING CLAIMS ONLY IN YOUR
                                                        INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                                                        ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further,
                                                        neither you nor we will seek to have any dispute heard as a
                                                        class action or in any other proceeding in which either party
                                                        acts or proposes to act in a representative capacity. No
                                                        arbitration or proceeding will be combined with another without
                                                        the prior written consent of all parties to all affected
                                                        arbitrations or proceedings.
                                                    </p>
                                                </li>
                                                <li><strong className="fw-bold text-dark ">21. MISCELLANEOUS

                                                </strong>
                                                    <ul className="ps-3 text-secondary listing some-space">
                                                        <li>
                                                            <strong className="fw-bold text-dark">1.</strong>
                                                            You understand that we will not be liable to you if we are
                                                            prevented from, or delayed in, providing the Services due to
                                                            acts, events, omissions, or accidents beyond our reasonable
                                                            control (“<strong className="fw-bold text-dark">Unavoidable
                                                            Events</strong>”). Where an Unavoidable Event
                                                            occurs, we will attempt to recommence the provision of the
                                                            Services as soon as reasonably practicable.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">2.</strong>
                                                            We may assign any or all of our rights and obligations to
                                                            any
                                                            person or affiliate entity at any time. If any provision or
                                                            part
                                                            of a provision of these Terms is determined to be unlawful,
                                                            void, or unenforceable, that provision or part of the
                                                            provision
                                                            is deemed severable from the Terms and does not affect the
                                                            validity and enforceability of any remaining provisions.
                                                        </li>
                                                        <li>
                                                            <strong className="fw-bold text-dark">3.</strong>
                                                            You acknowledge that there is no joint venture, partnership,
                                                            employment, or agency relationship created between you and
                                                            us as
                                                            a result of these Terms or use of the Services.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><strong className="fw-bold text-dark ">22. CONTACT


                                                </strong>
                                                    <p className='mb-0'>If you require further information or have any
                                                        inquiries or concerns regarding the Terms of Service, please do
                                                        not hesitate to contact us in writing at:</p>
                                                    <p>Email: _______________________
                                                    </p>
                                                </li>
                                            </ul>

                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>


            </div>


            <DownloadApplications/>
            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default TermsOfService;
