import FlightImg from "../../../components/flightImg";
import {
    displayDate,
    getAirlineLogo,
    getTotalDurationSegments, minToHours
} from "../../../components/utils/appUtils";
import React from "react";
import AllStopsDetailsPopover from "../components/AllStopsDetailsPopover";
import LccStatus from "../components/LccStatus";

const FlightAddress = ({data}) => {
    return (
        <>
            <small className="d-block">
                {/*{data && data.Airport && data.Airport.AirportName ? data.Airport.AirportName : ""}*/}

            </small>
            <small className="d-block">
                {/*{data && data.Airport && data.Airport.Terminal ? `${data.Airport.Terminal}, ` : ""}*/}
                {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
                {data && data.Airport && data.Airport.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}

                {/*{data && data.Airport && data.Airport.CountryName ? data.Airport.CountryName : ""}*/}
            </small>
        </>
    )
}

const WithStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments?.[0];
    let segmentStarting = allSegmentData?.[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    let originObj = fareRules?.[0];
    let destinationObj = fareRules[fareRules.length - 1];
    let stopCount = allSegmentData.length - 1;


    let stopCities = allSegmentData
        .slice(0, stopCount)
        .map(segment => segment.Destination.Airport.CityName)
        .join(", ");

    const getStopLine = (numStops) => {
        return (
            <div className="stop-line">
                <div className="red-line">
                    {Array.from({length: numStops}).map((_, index) => (
                        <span key={index} className="stop-dot"></span>
                    ))}
                </div>
            </div>
        );
    };
    let stopsComponent = (
        <>
            <span><FlightImg/></span>
            <h5>
                <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                    <small className="d-block">
                        {getTotalDurationSegments(allSegmentData)}
                    </small>
                    {getStopLine(stopCount)}
                    <small className={'d-block'}>{allSegmentData.length - 1} stop via</small>
                </AllStopsDetailsPopover>
                <small className="d-block">
                    {stopCities}
                    {/*&nbsp;,&nbsp;*/}
                    {/*{destinationObj && destinationObj.Destination ? destinationObj.Destination : ""}*/}
                </small>
            </h5>
            <span><FlightImg/></span>
        </>
    )

    return (
        <>

            <ul>
                <li className="p-0">
                       <span className="logo">
                            <img src={getAirlineLogo(data.AirlineCode)} alt=""/>
                        </span>
                    <h5>{segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                        <small className="d-block">
                            {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                            {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                            {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                        </small>
                        <LccStatus data={data}/>

                    </h5>

                    <div
                        className="d-flex align-items-center"
                        style={{gap: '30px'}}>
                        <h5>
                            {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                {displayDate(segmentStarting.Origin.DepTime)}
                            </> : null}
                            {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                        </h5>

                        {stopsComponent}

                        <h5>
                            {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                {displayDate(segmentEnding.Destination.ArrTime)}
                            </> : null}

                            {segmentEnding.Destination ?
                                <FlightAddress data={segmentEnding.Destination}/> : null}
                        </h5>


                        {allSegmentData.length == 1 ? <h5>
                            {getTotalDurationSegments(allSegmentData)}
                            <small
                                className="d-block">Economy
                                Class</small>
                            <small className="d-block">
                                {data.IsRefundable ? "Refundable" : "Non Refundable"}

                            </small>
                        </h5> : null}
                    </div>
                </li>

            </ul>


        </>
    )
}
export default WithStopComponent;
