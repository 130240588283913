import React, {useState, useEffect} from "react"
import {swapIcon} from "../../../components/imgComp";
import "./style.css"

const ChangeLocationImage = (props) => {
    let {onClick, state} = props;
    const [rotate, setRotate] = useState(false);
    const handleClick = () => {
        setRotate(true);
        // Reset rotation after animation ends (optional)
        setTimeout(() => setRotate(false), 500); // duration should match CSS animation time
        onClick(events.changeLocation())
    };
    const events = {
        changeLocation: () => {
            let {destination, destinationAirportName, destinationCity, origin, originAirportName, originCity} = state;
            return {
                origin: destination,
                originAirportName: destinationAirportName,
                originCity: destinationCity,
                destination: origin,
                destinationAirportName: originAirportName,
                destinationCity: originCity,
            }
        }
    }

    return (
        <div className="divide-box position-relative">
            <img
                src={swapIcon} // Replace with your image
                alt="Rotating"
                onClick={handleClick}
                className={`cursor-pointer transition-transform duration-1000 ${
                    rotate ? "rotate" : ""
                }`}
            />
        </div>
    )
}
export default ChangeLocationImage;
