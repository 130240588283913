import React from "react";
import Slider from "react-slick";
import RegisterBanner from "../components/pageComponents/registerBanner";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";

const TermsAndConditions = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <div className={'term-section'}>
                <section className="about-banner">

                    <img src="../images/about-banner.jpg" className="w-100"/>
                </section>

                <section className="note-banner pt-lg-4 pt-3">
                    <div className="container">
                        <h3 className="m-0">Welcome to X Travel World! By using our platform and services, you agree to
                            the
                            following terms and conditions. Please read them carefully.</h3>
                    </div>
                </section>
                <section className="our-values">
                    <div className="container">


                        <div className="row row-gap-3 mt-5">
                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">1. General Terms</h5>
                                    <ul className="ps-3 text-secondary listing">
                                        <li>These terms apply to all users accessing the <strong>X Travel
                                            World</strong> platform, including travel agents, tour operators, and other
                                            B2B clients.
                                        </li>
                                        <li>You must be at least 18 years old and have the legal capacity to enter into
                                            binding contracts to use our services.
                                        </li>
                                        <li>All information provided during the registration process must be accurate
                                            and up to date.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">2. Account Registration and Security</h5>
                                    <ul className="ps-3 text-secondary listing">
                                        <li>To access certain services, you must create an account by providing accurate
                                            business details and contact information.
                                        </li>
                                        <li>You are responsible for maintaining the confidentiality of your account
                                            credentials and ensuring the security of your account.
                                        </li>
                                        <li>If you suspect any unauthorized use of your account, you must notify us
                                            immediately.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">3. Booking and Payment</h5>
                                    <ul className="ps-3 text-secondary listing">
                                        <li>All bookings made through our platform are subject to availability and
                                            confirmation by the airline or hotel partner.
                                        </li>
                                        <li>Payments for bookings must be made as per the payment terms outlined during
                                            the booking process.
                                        </li>
                                        <li><strong>X Travel World</strong> is not liable for any issues or
                                            cancellations that occur once a booking has been made.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">4. Cancellations and Refunds</h5>
                                    <ul className="ps-3 text-secondary listing">
                                        <li>Cancellation policies vary by airline, hotel, or service provider and are
                                            subject to their respective terms and conditions.
                                        </li>
                                        <li>Refunds, if applicable, will be processed according to the service
                                            provider’s refund policy. <strong>X Travel World</strong> facilitates the
                                            refund process but is not responsible for delays or issues.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">5. Data Privacy and Protection</h5>
                                    <ul className="ps-3 text-secondary listing">
                                        <li>We are committed to protecting your personal and business information in
                                            accordance with our privacy policy.
                                        </li>
                                        <li>By using our services, you agree to the collection and use of your data as
                                            described in our privacy policy.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">6. Intellectual Property</h5>
                                    <ul className="ps-3 text-secondary listing">
                                        <li>All content, trademarks, and logos on the X Travel World platform are the
                                            property of <strong>X Travel World</strong> and its partners.
                                        </li>
                                        <li>You may not copy, distribute, or modify any of the content without prior
                                            written permission.

                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">7. Limitation of Liability</h5>
                                    <ul className="ps-3 text-secondary listing">
                                        <li><strong>X Travel World</strong> is not liable for any indirect, special,
                                            incidental, or consequential damages resulting from the use of our platform
                                            or services.
                                        </li>
                                        <li>Our liability is limited to the amount paid for the booking in question.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">8. Governing Law</h5>
                                    <ul className="ps-3 text-secondary listing">
                                        <li>These terms and conditions are governed by and construed in accordance with
                                            the laws of the jurisdiction where <strong>X Travel World</strong> operates.
                                        </li>
                                        <li>Any disputes arising from these terms will be resolved through binding
                                            arbitration.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">9. Changes to Terms and Conditions</h5>
                                    <ul className="ps-3 text-secondary">
                                        <li><strong>X Travel World</strong> reserves the right to update these terms and
                                            conditions at any time. All changes will be communicated through the
                                            platform, and continued use of our services constitutes acceptance of those
                                            changes.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="card border-0 shadow-sm rounded-4 p-4">
                                    <h5 className="fw-bold text-dark">10. Contact Us</h5>

                                    <ul className="ps-3 text-secondary listing">
                                        <li>If you have any questions regarding our terms and conditions, please contact
                                            us at:
                                        </li>
                                        <li><strong>Email:</strong> enquiry@xtravelworld.com</li>
                                        <li><strong>Phone:</strong> 1800-102-0340</li>
                                        <li><strong>Address:</strong> SCO 178, 2nd floor, Sector 38C, Chandigarh, 160036
                                            140306 (INDIA)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>

            <DownloadApplications/>
            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default TermsAndConditions;
